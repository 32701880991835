import React, { useEffect, useState, useRef } from "react";
import Icon from "@material-ui/core/Icon";
import JSZip from "jszip";
import {
  Table,
  // TextField,
  // Button,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  // FormControl,
  MenuItem,
} from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import PrintDocList from "../../CommonComponents/PrintDoc/PrintDocList";
import moment from "moment";
import {
  action1,
  pharmacyCall,
  route_report,
  pilldropLogo,
  landline_white,
  text,
  pharmacyAddress,
} from "../../../assests";
import { useReactToPrint } from "react-to-print";

import { useNavigate } from "react-router";

import { toast } from "react-toastify";

import "./PharmacyReportObject.css";

import {
  PHARMACY_REPORT_COUNT,
  ORDER_DETAIL_LIST,
} from "../../../utils/apiUrls";

import { getApiWithAuth, postApiWithAuth } from "../../../utils/api";
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const columns2 = [
  { id: "date", label: "Date", minWidth: 170 },
  { id: "sameDay", label: "Same Day Orders", minWidth: 170 },
  { id: "nextDay", label: "Next Day Orders", minWidth: 170 },
  { id: "timeWindow", label: "Next Day (Time Window)", minWidth: 170 },
  { id: "urgent", label: "Static Delivery Orders", minWidth: 170 },
  { id: "fattempt", label: "1st Attempts Deliveries", minWidth: 170 },
  { id: "sattempt", label: "2nd Attempts Deliveries", minWidth: 170 },
  { id: "tattempt", label: "3rd Attempts Deliveries", minWidth: 170 },
  { id: "cancelled", label: "Cancelled Orders", minWidth: 170 },
  { id: "sum", label: "Daily Amount Sum", minWidth: 170 },
];

const useStyles = makeStyles(() => ({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    padding: 10,
    fontWeight: "700",
    flexGrow: 1,
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    marginTop: "0px",
    fontWeight: "700",
    textAlign: "center",
    border: "1px solid black",
    minWidth: "30%",
    padding: "0 10px",
  },
  header2: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: "700",
    textAlign: "center",
  },
  header3: {
    fontSize: 18,
    marginBottom: 0,
    marginTop: "0px",
    fontWeight: "400",
    textAlign: "center",
  },
  header4: {
    fontSize: 18,
    marginBottom: 8,
    marginTop: "0px",
    fontWeight: "400",
    textAlign: "center",
  },
  Total: {
    fontSize: 24,
    float: "right",
    marginBottom: 8,
    marginRight: 0,
    marginTop: "0px",
    fontWeight: "700",
    textAlign: "center",
  },
  Sums: {
    fontSize: 18,
    marginBottom: "1px",
    marginTop: "0px",
    fontWeight: "700",
  },
  table: {
    width: "100%",
    border: "1px solid black",
    marginBottom: 0,
    fontWeight: "700",
  },
  tableHeader: {
    // backgroundColor: '#f0f0f0',
    fontWeight: "700",
    borderBottomWidth: "2px",
  },
  tableRow: {},
  tableCell: {
    padding: 5,
    fontSize: 12,
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    fontWeight: "700",
  },
  firstColumn: {
    fontWeight: "700",
    // borderBottomWidth: 2,
  },
  firstRow: {
    fontWeight: "700",
    borderBottomWidth: "2px",
  },
}));

function PharmacyReportObject({ pharmacyOrder }) {
  console.log("PharmacyReportObjectPharmacyReportObject", pharmacyOrder);
  const [invoice, setInvoice] = useState([]);
  const [signedOrders, setSignedOrders] = useState([]);
  const [loading, setLoading] = useState(null);
  const [dayAndDateArray, setDayAndDateArray] = useState([]);
  const createDate = new Date();
  const navigate = useNavigate();
  const classes = useStyles();
  const componentRef = useRef();
  const componentRef2 = useRef();
  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });
  const userType = localStorage.getItem("userType");

  const totalSum = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + parseFloat(currentOrder.sum),
    0
  );
  const totalCopay = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.copay,
    0
  );
  const totalDelivered = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.deliveredOrder,
    0
  );
  const totalOrder = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.totalOrder,
    0
  );
  const totalSign = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.sign,
    0
  );
  const totalHippa = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.amountHippa,
    0
  );
  const totalNF = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.amountNF,
    0
  );
  const totalLien = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.amountLien,
    0
  );
  const amountCollectedCopay = invoice?.reduce(
    (accumulator, currentOrder) =>
      accumulator + currentOrder.amountCollectedCopay,
    0
  );
  const pharmacyCopay = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.pharmacyCopay,
    0
  );
  const totalCopaySlip = invoice?.reduce(
    (accumulator, currentOrder) => accumulator + currentOrder.totalCopay,
    0
  );

  function formatDateToMMDDYYYY(inputDate) {
    if (!inputDate) {
      return ""; // Return an empty string if the date is undefined or empty
    }
    const parts = inputDate.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];
    return `${month}/${day}/${year}`;
  }

  const fetchInvoice = async (dateRange) => {
    // setByDate(true);
    const [startDate, endDate] = dateRange.split(" - ");
    console.log(startDate, endDate);
    const deliveryTime = new Date(startDate);
    const deliveryTimeFrom = new Date(endDate);
    const formData = {
      from_date: startDate,
      to_date: endDate,
      pharmacy: pharmacyOrder.pharmacyDetail.id,
    };
    // const formData = { data: duration }
    const response = await postApiWithAuth(PHARMACY_REPORT_COUNT, formData);
    console.log(response);
    if (response.data !== undefined) {
      setInvoice(response.data.data);
    } else {
      console.log(response);
    }
  };

  useEffect(() => {
    if (invoice.length > 6) {
      handleDownloadPDF();
      setInvoice([]);
    }
  }, [invoice]);

  const handleDownloadPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Order_File.pdf",
  });
  const handleDownloadSignedSlip = async (ids) => {
    setLoading(true);
    const chunkSize = 100;
    let allSignedOrders = [];

    // Function to split the IDs into chunks
    const splitIntoChunks = (array, chunkSize) => {
      let result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        let chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
      }
      return result;
    };

    const idChunks = splitIntoChunks(ids, chunkSize);

    for (const chunk of idChunks) {
      try {
        const response = await getApiWithAuth(
          ORDER_DETAIL_LIST + `?order_master_ids=${chunk.join(",")}`
        );
        if (response.data !== undefined) {
          allSignedOrders = allSignedOrders.concat(response.data.data);
        } else {
          console.log("Error in chunk: ", chunk);
          // Handle the error appropriately
        }
      } catch (error) {
        console.log("API call error: ", error);
        // Handle the API call error appropriately
      }
    }

    // Update the state with all aggregated signed orders
    setSignedOrders(allSignedOrders);

    console.log(
      "all signed orders",
      allSignedOrders,
      allSignedOrders.orderDetail,
      typeof allSignedOrders.orderDetail
    );

    setLoading(false);
  };

  useEffect(() => {
    if (signedOrders.length > 0) {
      setLoading(true);
      const timer = setTimeout(() => {
        handlePrintSlip();
        setLoading(false);
      }, 30000); // 20000 milliseconds = 20 seconds

      // Cleanup function to clear the timeout if the component unmounts or the dependencies change
      return () => clearTimeout(timer);
    }
  }, [signedOrders]);

  function getWeekNumber(date) {
    const d = new Date(date);
    d.setDate(d.getDate() - ((d.getDay() + 6) % 7));
    const year = d.getFullYear();
    const weekNumber = Math.ceil(
      (d - new Date(year, 0, 1)) / (7 * 24 * 60 * 60 * 1000)
    );
    return { year, weekNumber };
  }

  const handleDownloadImages = async (pharmacy, dateRange) => {
    console.log("Start conversion process...");
  
    // Single date input like '9/23/2024', no range needed
    const [dateStr] = dateRange.split(" - ");
    
    console.log("Date string:", dateStr);
  
    // Format the date from MM/DD/YYYY to YYYY-MM-DD
    const formatDate = (dateStr) => {
      const [month, day, year] = dateStr.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    };
  
    const formattedDate = formatDate(dateStr);
  
    console.log("Formatted date:", formattedDate);
  
    // Construct the URL for the ZIP file
    const fileUrl = `https://pilldrop-s3-bucket.s3.amazonaws.com/images/merge_sign_files/all/${pharmacy}_${formattedDate}_${formattedDate}_images.zip`;
    console.log("File URL:", fileUrl);
  
    // Trigger the download
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `${pharmacy}_${formattedDate}_${formattedDate}_images.zip`);  // File name for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);  // Clean up the DOM
  };
   

  const handleOpenNewTab = (pharmacy, dateRange) => {
    const [startDateStr, endDateStr] = dateRange.split(" - ");
    // Parse the date strings in the format MM/DD/YYYY to YYYY-MM-DD
    const startDateParts = startDateStr.split("/");
    const endDateParts = endDateStr.split("/");
    const startDate = `${startDateParts[2]}-${startDateParts[0].padStart(
      2,
      "0"
    )}-${startDateParts[1].padStart(2, "0")}`;
    const endDate = `${endDateParts[2]}-${endDateParts[0].padStart(
      2,
      "0"
    )}-${endDateParts[1].padStart(2, "0")}`;

    // Construct the real file URL with the correct date format
    let realFileUrl = `https://pilldrop-s3-bucket.s3.amazonaws.com/images/merge_sign_files/rx/${pharmacy}_${startDate}_${endDate}_rx.pdf`;

    // Encode the entire URL
    realFileUrl = encodeURI(realFileUrl);

    // Replace encoded spaces with `+` characters
    realFileUrl = realFileUrl.replace(/%20/g, "+");

    console.log("real url", realFileUrl);
    window.open(realFileUrl, "_blank");

    // Fetch the file using the constructed URL
    // fetch(realFileUrl)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // Create a URL for the blob object
    //     const objectUrl = URL.createObjectURL(blob);
    //     // Open the URL in a new tab
    //     window.open(objectUrl, "_blank");
    //   })
    //   .catch((error) => console.error("Error:", error));
  };

  const handleOpenNewTabManual = (pharmacy, dateRange) => {
    const [startDateStr, endDateStr] = dateRange.split(" - ");
    // Parse the date strings in the format MM/DD/YYYY to YYYY-MM-DD
    const startDateParts = startDateStr.split("/");
    const endDateParts = endDateStr.split("/");
    const startDate = `${startDateParts[2]}-${startDateParts[0].padStart(
      2,
      "0"
    )}-${startDateParts[1].padStart(2, "0")}`;
    const endDate = `${endDateParts[2]}-${endDateParts[0].padStart(
      2,
      "0"
    )}-${endDateParts[1].padStart(2, "0")}`;
    // Construct the real file URL with the correct date format
    const realFileUrl = `https://pilldrop-s3-bucket.s3.amazonaws.com/images/merge_sign_files/manual/${pharmacy}_${startDate}_${endDate}_manual.pdf`;
    console.log("real url", realFileUrl);
    // Open the URL in a new tab
    window.open(realFileUrl, "_blank");
  };

  const handleDownloadSlip = async (fileUrls, dateRange, type) => {
    console.log("handle dwnload slips is called");
    setLoading(true);
    const [startDate, endDate] = dateRange.split(" - ");
    const weekStart = getWeekNumber(startDate);
    const dateList = [];
    if (fileUrls.length > 0) {
      // Convert start and end dates to JavaScript Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Loop through the range of dates and add each date to the list
      let current = start;
      while (current <= end) {
        const formattedDate = current.toLocaleDateString("en-GB"); // Format the date as 'DD-MM-YYYY'
        dateList.push(formattedDate);
        current.setDate(current.getDate() + 1); // Move to the next date
      }

      const pharmacyId = pharmacyOrder.pharmacyDetail.id; // Assuming id is defined somewhere in the code

      const requestBody = {
        type: type,
        pharmacy_id: pharmacyId,
        year: weekStart.year,
        week: weekStart.weekNumber,
      };

      const response = await fetch(
        `${process.env.REACT_APP_LINK_BASE_URL}ship/download`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${startDate} - ${endDate}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else if (response.ok) {
        console.log("processing");
      } else {
        // Handle the error response
        console.error("Error:", response.statusText);
      }
    } else {
      toast.error("No files available.", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    setDayAndDateArray(getDayAndDate(pharmacyOrder.dateRange));
  }, [pharmacyOrder.dateRange]);
  function getDayAndDate(dateRange) {
    const [startDate, endDate] = dateRange.split(" - ");
    const start = new Date(startDate);
    const end = new Date(endDate);
    const result = [];

    let currentDate = new Date(start);
    while (currentDate <= end) {
      result.push({
        day: currentDate.toLocaleDateString("en-US", { weekday: "long" }),
        date: currentDate.toLocaleDateString(),
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return result;
  }
  const isCurrentWeek = (dateRange) => {
    const [startDate, endDate] = dateRange.split(' - ');
    const currentDate = new Date();
    console.log("dateRange",dateRange)
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
   console.log("currentDate >= startDateObj && currentDate <= endDateObj;",currentDate >= startDateObj && currentDate <= endDateObj)
    return currentDate >= startDateObj && currentDate <= endDateObj;
  };
  const isBeforeToday = (date) => {
    const today = new Date();
    const inputDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    return inputDate < today; // Returns true for all dates before today
  };
  
  
  return (
    <>
      {loading && <CustomLoader />}
      <div className="pharmacyOrder-div-phar">
        <div className="pharmacyOrder-first-phar">
          <h3 className="pharmacyOrder-date-phar">
            {pharmacyOrder.pharmacyDetail.name}
          </h3>
          <div
            className="detail-div-pharmacy-phar"
            style={{ marginBottom: "5px" }}
          >
            <h3 className="detail-in-text-pharmacy-phar">
              {pharmacyOrder.pharmacyDetail.location}
            </h3>
          </div>
        </div>
        <div className="row-container-phar">
          <div className="pharmacyOrder-order-list-phar">
  {!isCurrentWeek(pharmacyOrder.dateRange) && (
    <>
            <div className="pharmacyReport-obj-phar">
             <h3 className="pharmacyReport-head-phar" style={{ fontSize: "16px" }}>PDF Slips</h3>
            </div>
            <div className="pharmacyReport-obj-phar">
              <h3 className="pharmacyReport-head-phar">Uploaded</h3>
              <h3 className="pharmacyReport-head-phar">Delivery Slips</h3>
            </div>
            <div
              className="pharmacyReport-obj3-phar"
              style={{ minWidth: "150px" }}
            >
              <h3
                className="pharmacyReport-text-phar"
                style={{ marginRight: "10px" }}
              >
                {pharmacyOrder.deliverySlipUnsign.length} RX Slips
              </h3>
              {pharmacyOrder.deliverySlipUnsign.length > 0 ? (
                <img
                  className="detail-in-icon-ph"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleDownloadSlip(
                      pharmacyOrder.deliverySlipUnsign,
                      pharmacyOrder.dateRange,
                      "unsign"
                    )
                  }
                  src={route_report}
                  alt="Logo"
                />
              ) : (
                <img
                  className="detail-in-icon-ph"
                  src={route_report}
                  alt="Disabled Icon"
                />
              )}
            </div>
            <div className="pharmacyReport-obj-phar">
              <h3 className="pharmacyReport-head-phar">Signed</h3>
              <h3 className="pharmacyReport-head-phar">Delivery Slips</h3>
            </div>
            <div className="pharmacyReport-obj2-phar">
              <div className="pharmacyReport-obj3-phar">
                <h3
                  className="pharmacyReport-text-phar"
                  style={{ marginRight: "30px" }}
                >
                  {pharmacyOrder.deliverySlip.length} RX Slips
                </h3>
                {pharmacyOrder.deliverySlip.length > 0 ? (
                  <img
                    className="detail-in-icon-ph"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleOpenNewTab(
                        pharmacyOrder.pharmacyDetail.name,
                        pharmacyOrder.dateRange
                      )
                    }
                    src={route_report}
                    alt="Logo"
                  />
                ) : (
                  <img
                    className="detail-in-icon-ph"
                    src={route_report}
                    alt="Disabled Icon"
                  />
                )}
                {/* {pharmacyOrder.deliverySlip.length > 0 ? (
  <Icon 
    className="fas fa-download" 
    style={{ cursor: "pointer",fontSize: "14px" }} 
    onClick={() =>
      handleDownloadImages(
        pharmacyOrder.pharmacyDetail.name,
        pharmacyOrder.dateRange
      )
    } 
  />
) : (
  <Icon className="fas fa-download"  style={{ fontSize: "14px" }} />
)} */}
              </div>

              <div
                className="pharmacyReport-obj3-phar"
                style={{ minWidth: "180px" }}
              >
                <h3
                  className="pharmacyReport-text-phar"
                  style={{ marginRight: "10px" }}
                >
                  {pharmacyOrder.manual_orders_url.length} Manual Slips
                </h3>
                {pharmacyOrder.manual_orders_url.length > 0 ? (
                  <img
                    className="detail-in-icon-ph"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleOpenNewTabManual(
                        pharmacyOrder.pharmacyDetail.name,
                        pharmacyOrder.dateRange
                      )
                    }
                    src={route_report}
                    alt="Logo"
                  />
                ) : (
                  <img
                    className="detail-in-icon-ph"
                    src={route_report}
                    alt="Disabled Icon"
                  />
                )}
              </div>
            </div>
            <div className="pharmacyReport-obj-phar">
              <h3 className="pharmacyReport-head-phar">Biling & Copay</h3>
              <h3 className="pharmacyReport-head-phar">Invoice</h3>
            </div>
            <div className="pharmacyReport-obj2-phar">
              <h3 className="pharmacyReport-text-phar">
                Copay Collected{" "}
                <span style={{ fontWeight: 800 }}>
                  {pharmacyOrder.totalCopay.toFixed(2)}
                </span>
              </h3>
              <h3 className="pharmacyReport-text-phar">
                Deliveries Charges <span style={{ fontWeight: 800 }}></span>
              </h3>
            </div>
            <div className="pharmacyReport-obj3-phar">
              <img
                className="detail-in-icon-ph"
                style={{ marginRight: "10px", cursor: "pointer" }}
                src={action1}
                alt="Logo"
              />
              <img
                className="detail-in-icon-ph"
                style={{ cursor: "pointer" }}
                onClick={() => fetchInvoice(pharmacyOrder.dateRange)}
                src={route_report}
                alt="Logo"
              />
            </div>
            </>
  )}
          </div>
          {userType === "Admin"  ? (
        
        <div className="pharmacyOrder-order-list-phar">
          <div className="pharmacyReport-obj-phar">
            <h3 className="pharmacyReport-head-phar" style={{ fontSize: "16px" }}>
              JPEG Slips
            </h3>
          </div>
                  {pharmacyOrder.dailyData.map((data, index) => {
            const currentDate = data.date; // Get the date directly        
            if (isBeforeToday(currentDate)) {
              return (
                <div key={index} className="pharmacyReport-obj3-phar" style={{marginLeft:'20px'}}>
                  <h3 className="pharmacyReport-text">{dayAndDateArray[index]?.date}</h3>
                  <h3 className="pharmacyReport-text" style={{marginLeft:'10px'}}>{dayAndDateArray[index]?.day}</h3>
                  
                  
                  <div className="pharmacyReport-obj2-phar">
                    <div className="pharmacyReport-obj3-phar">
                      <h3 className="pharmacyReport-text" style={{ marginRight: "10px",marginLeft:'10px' }}>
                    {data.deliverySlip.length}
                  </h3>
                      <Icon
                        className="fas fa-download"
                        style={{ cursor: "pointer", fontSize: "14px" }}
                        onClick={() => handleDownloadImages(pharmacyOrder.pharmacyDetail.name, dayAndDateArray[index].date)}
                      />
                    </div>
                  </div>
                </div>
              );
            } else {
              console.log(`Adding empty div for index ${index} with date: ${currentDate}`);
              return <div key={`empty-${index}`} className="pharmacyReport-obj empty-div"></div>; // Add empty div for today or future dates
            }
          })}
        </div>
        
       
       
       
        
        
          ) : null}
        </div>

        <div style={{ display: "none" }}>
          <div ref={componentRef} style={{ margin: 20 }}>
            <div className={classes.page}>
              <div className={classes.section}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "400" }}>
                    Date Issued: {moment(createDate).format("MM/DD/YYYY")}
                  </p>
                  <p style={{ fontWeight: "400" }}>Page 1/1</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    height: "80px",
                  }}
                >
                  <div style={{ minWidth: "550px" }}>
                    <img
                      src={pilldropLogo}
                      alt="Mantis"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <h4 className={classes.header}>
                    Invoice{" "}
                    <span style={{ marginLeft: "10px" }}>
                      ({formatDateToMMDDYYYY(invoice[1]?.date)} To{" "}
                      {formatDateToMMDDYYYY(invoice[invoice?.length - 1]?.date)}
                      )
                    </span>
                  </h4>
                </div>
                <h4 className={classes.header2}>
                  {invoice[0]?.pharmacy?.name}
                </h4>
                <h4 className={classes.header3}>
                  {invoice[0]?.pharmacy?.address}
                </h4>
                <h4 className={classes.header4}>
                  {invoice[0]?.pharmacy?.contact}
                </h4>
                <Table className={classes.table}>
                  <TableBody>
                    {columns2.map((column, i) => (
                      <TableRow key={column.id}>
                        <TableCell
                          className={`${classes.tableCell} ${classes.firstColumn}`}
                          align="center"
                        >
                          {column.label}
                        </TableCell>
                        {invoice.map((dateResult, k) => {
                          const value = dateResult[column.id];
                          return (
                            <TableCell
                              className={`${classes.tableCell} ${
                                column.id === "date" ? classes.firstRow : ""
                              }`}
                              key={dateResult.date}
                              align="center"
                              style={{ border: "1px solid black" }}
                            >
                              {k === 0 && column.id === "date"
                                ? value
                                : column.id === "date"
                                ? formatDateToMMDDYYYY(value)
                                : column.id === "sum" && value === " "
                                ? " "
                                : column.id === "sum" && value !== " "
                                ? `$ ${parseFloat(value).toFixed(2)}`
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 15px 5px 30px",
                    border: "1px solid black",
                  }}
                >
                  <p>Total Orders = {totalOrder}</p>
                  <p>Total Amount ${parseFloat(totalSum).toFixed(2)}</p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "600" }}>
                    Get Forms Signed = {totalSign}
                  </p>
                  <p style={{ fontWeight: "600" }}>
                    Copay Orders = {totalCopay}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid black",
                  }}
                >
                  <p style={{ fontWeight: "600" }}>
                    HIPAA FORM = {totalHippa}, Nf AOB FORM = {totalNF}, LIEN
                    FORM = {totalLien}
                  </p>
                  <div style={{ textAlign: "right" }}>
                    <p style={{ fontWeight: "600" }}>
                      Copay's Collected = ${" "}
                      {parseFloat(amountCollectedCopay).toFixed(2)}
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Copay's Pharmacy Wavied / Received = ${" "}
                      {parseFloat(pharmacyCopay).toFixed(2)}
                    </p>
                    <p style={{ fontWeight: "600" }}>
                      Total Copay = $ {parseFloat(totalCopaySlip).toFixed(2)}
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "600" }}>
                    PD Receiver's Name: ______________
                  </p>
                  <p style={{ fontWeight: "600" }}>
                    Copay's Receiver's Name: ______________
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "600" }}>
                    PD Receiver's Signatures: ______________
                  </p>
                  <p style={{ fontWeight: "600" }}>
                    Copay's Receiver's Signatures: ______________
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "600" }}>
                    Form Of Payment: ______________
                  </p>
                  <p style={{ fontWeight: "600" }}>
                    Receiving Date: ______________
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div ref={componentRef} style={{ width: 400, height: 100, marginTop: 100, marginLeft: 100 }}>
            {csvData.map((data, i) => (
              <div key={i}>
                <h4>Name: </h4>
                <h5> ${data.name}</h5>
                <h4>Address: </h4>
                <h5> ${data.address}</h5>
                <h4>Contact: </h4>
                <h5> ${data.contact}</h5>
              </div>
            ))}
          </div> */}
        </div>

        <div style={{ display: "none" }}>
          {signedOrders && signedOrders.length > 0 && (
            <div ref={componentRef2}>
              {signedOrders?.map((orderDetail, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    paddingTop: "10px",
                    pageBreakBefore: "always",
                    pageBreakAfter: "always",
                    pageBreakInside: "avoid",
                  }}
                >
                  <PrintDocList
                    orderDetail={orderDetail}
                    pharmacy={signedOrders[0]?.pharmacy}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PharmacyReportObject;
