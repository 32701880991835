import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import {
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Badge,
  IconButton,
  Typography,
  Tooltip,
  Divider,
  FormControl,
  Avatar,
  List,
  ListItem,
  ListItemText,
  InputLabel,
  TextField,
  Box,
  capitalize,
} from "@mui/material";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";
import Barcode from "react-barcode";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useReactToPrint } from "react-to-print";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import QRCode from "qrcode.react";
import OrderDetailsModal from "../DriverScanObject/OrderDetailsModal";
import { toast } from "react-toastify";
import { FiRefreshCw } from "react-icons/fi";

import {
  CREATE_TICKET,
  CREATE_COMMENT,
  GET_HISTORY,
  CREATE_ADMIN_NOTE,
  CREATE_PHARMACY_NOTE,
  MAKE_CALL,
  DRIVER_NOTE,
  ORDER_DETAIL_LIST,
  CREATE_ORDER,
  PERSON_ORDERS,
  SEND_TWILIO_SMS,
  ORDER_CHAT_LIST,
} from "../../../utils/apiUrls";
import {
  postApiWithAuth,
  getApiWithAuth,
  putApiWithAuth,
  patchApiWithAuth,
  postApiWithoutAuth,
} from "../../../utils/api";

import "./orderObject.css";
import moment from "moment";

import {
  landline,
  mobile,
  phone,
  action2,
  qrImage,
  action3,
  action4,
  action5,
  action6,
  action7,
  action8,
  userBlack,
  pdfLogo,
  photoId,
  registered,
  buildings,
  cellphone,
  iphone,
  delivery,
  route_report,
  readyPickOrder,
  pickedOrder,
  recievedFacilityOrder,
  transitOrder,
  rescheduleOrder,
  cancelOrder,
  chatTop,
} from "../../../assests";
import { TextareaAutosize } from "@mui/base";

import { DetailOrder } from "../../FullComponents";
import PrintDocDownList from "../../CommonComponents/PrintDoc/PrintDocDownList";
import PrintDocList from "../../CommonComponents/PrintDoc/PrintDocList";
import { TextFields } from "@mui/icons-material";

function getStatusMessage(statusCode) {
  switch (statusCode) {
    case "1":
      return "Ready to Pick Up";
    case "2":
      return "Picked Up";
    case "3":
      return "Received At Facility";
    case "4":
      return "In Transit";
    case "5":
      return "Delivered";
    case "6":
      return "Cancelled";
    case "7":
      return "Return Created";
    case "8":
      return "Return Route Created";
    case "9":
      return "Returned to Pharmacy";
    default:
      return "Unknown Status";
  }
}

function OrderObject({ order, getOrders, type, statusId, setNumber, setCallingOrder, setCallModel, unreadCounts }) {
  const statusMessage = getStatusMessage(order.status);
  const [loading, setLoading] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalViewOpen, setModalViewOpen] = useState(false);
  const [modalHistoryOpen, setModalHistoryOpen] = useState(false);
  const [modalFacilityNoteOpen, setModalFacilityNoteOpen] = useState(false);
  const [modalPharmacyNoteOpen, setModalPharmacyNoteOpen] = useState(false);
  const [twilioModel, setTwilioModel] = useState(false);
  const [modalPilldropNoteList, setModalPilldropNoteList] = useState(false);
  const [modalDriverNoteList, setModalDriverNoteList] = useState(false);
  const [driverNotes, setDriverNotes] = useState("");
  const [orderId, setOrderId] = useState("");
  const [history, setHistory] = useState({});
  const [adminNote, setAdminNote] = useState("");
  const [pharmacy, setPharmacy] = useState("");
  const [orderDetail, setOrderDetail] = useState(null);
  const [pharmacyNote, setPharmacyNote] = useState("");
  const [toNumber, setToNumber] = useState("");
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [selectedNumber, setSelectedNumber] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [numberSeleteModal, setNumberSeleteModal] = useState(false);
  const [chatModel, setChatModel] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [modalnameOpen, setModalnameOpen] = useState(false);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [formState, setFormState] = useState({
    subject: "",
    comment: "",
  });

  const { pid } = useParams();
  const navigate = useNavigate();

  const endOfMessagesRef = useRef(null);

  const componentRef = useRef();
  const componentRef2 = useRef();
  const reportTemplateRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });

  const getOrderDetail = async () => {
    const response = await getApiWithAuth(CREATE_ORDER + `/${order.id}`);
    if (response.data !== undefined) {
      setOrderDetail(response.data.data);
      setPharmacy(response.data.data.pharmacy);
    } else {
      console.log("Error");
    }
  };

  console.log(orderDetail);

  useEffect(() => {
    handlePrintSlip();
  }, [orderDetail]);
  const handleOpenPdf = () => {
    handlePrint({
      content: () => (
        <iframe
          src={`https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${order.slug}_signed.pdf?embedded=true#toolbar=1&navpanes=0&scrollbar=0`}
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      ),
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const makeCall = async () => {
    const response = await postApiWithoutAuth(MAKE_CALL, {});
    console.log(response);
    if (response.data !== undefined) {
      console.log(response.data);
    } else {
      console.log("error");
    }
  };

  const fetchDriverNots = async () => {
    const response = await getApiWithAuth(
      DRIVER_NOTE + `?order_id=${order.id}&is_pharmacy=${true}`
    );
    console.log(response);
    if (response.data !== undefined) {
      setDriverNotes(response.data.data);
    } else {
      console.log("error");
    }
  };

  const fetchOrderChat = async () => {
    const response = await getApiWithAuth(
      ORDER_CHAT_LIST + `?order_id=${order.id}`
    );
    console.log(response);
    if (response.data !== undefined) {
      scrollToBottom();
      setChatList(response.data.data);
    } else {
      console.log("error");
    }
  };

  const markReadOrderChat = async () => {
    await patchApiWithAuth(
      ORDER_CHAT_LIST + `update_by_order/?order_id=${order.id}`, { is_read: true }
    );
    getOrders(statusId);
  };

  useEffect(() => {
    if (chatModel) {
      scrollToBottom();
    }
  }, [chatList, chatModel]);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendTwilioSmsList = async () => {
    if (newMessage !== "") {
      const firstMessage = chatList[0];
      const numberToSend = firstMessage?.clientNumber;
      console.log(numberToSend)
      const response = await postApiWithAuth(SEND_TWILIO_SMS, {
        to_number: numberToSend,
        text: newMessage,
        order_id: order.id
      });
      console.log(response.data);
      if (response.data.error === undefined) {
        toast.success(response.data.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      fetchOrderChat();
      setNewMessage("")
    } else {
      toast.error("Please write message for sent", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleCreateFacilityNote = () => {
    setModalFacilityNoteOpen(true);
    setAdminNote(
      userType === "PharmacyOwner" || userType === "	PharmacyEmployee"
        ? order.pharmacy_staff_note
        : order.admin_staff_note
    );
  };


  const HandleSmsList = () => {
    fetchOrderChat();
    markReadOrderChat();
    setChatModel(true);
  };

  const handleCloseChatModel = () => {
    setChatList([]);
    setChatModel(false);
  };

  const handleNumberSeleteOpen = (number) => {
    setNumberSeleteModal(true);
    setSelectedNumber(number);
  };

  const handleNumberSeleteClose = () => {
    setNumberSeleteModal(false);
    setSelectedNumber("");
  };

  const handleMakeCall = (number) => {
    setNumberSeleteModal(false);
    setSelectedNumber("");
    setNumber(number);
    setCallingOrder({ id: order.id, name: order.name, number: selectedNumber });
    setCallModel(true);
  };

  const handleReschedule = () => {
    navigate(`/reschedule/order/${order.id}`);
  };

  const handleModalFacilityNoteClose = () => {
    setModalFacilityNoteOpen(false);
    setAdminNote("");
  };

  const handleCreatePharmacyNote = () => {
    setModalPharmacyNoteOpen(true);
    setPharmacyNote(order.admin_note);
  };

  const handleTwilioModel = () => {
    setTwilioModel(true);
  };

  const handleTwilioModelClose = () => {
    setTwilioModel(false);
  };

  const handleListPilldropNote = () => {
    setModalPilldropNoteList(true);
    fetchDriverNots();
  };

  const handleListPilldropNoteClose = () => {
    setModalPilldropNoteList(false);
  };

  const handleListDriverNote = () => {
    setModalDriverNoteList(true);
    fetchDriverNots();
  };

  const handleListDriverNoteClose = () => {
    setModalDriverNoteList(false);
  };

  const handleModalPharmacyNoteClose = () => {
    setModalPharmacyNoteOpen(false);
    setPharmacyNote("");
  };

  const handleCreateHistory = async () => {
    const response = await getApiWithAuth(GET_HISTORY + `${order.id}/`);
    console.log("history response", response.data.data);
    if (response.data !== undefined) {
      setHistory(response.data.data);
      setModalHistoryOpen(true);
    } else {
      console.log("error");
    }
  };

  const handleModalHistoryClose = () => {
    setModalHistoryOpen(false);
  };

  const handleViewModalOpen = (id) => {
    setModalViewOpen(true);
    setOrderId(id);
  };

  const handleViewModalClose = () => {
    setModalViewOpen(false);
    setOrderId("");
  };

  const handleCreateTicket = (id) => {
    setModalOpen(true);
    setOrderId(id);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setOrderId("");
  };

  const userType = localStorage.getItem("userType");

  const handleSaveTicket = async (e) => {
    console.log("workingggggg", formState.comment);
    setIsButtonDisabled(true);
    console.log("Saving ticket...", formState);
    e.preventDefault();
    console.log("Order Details:", formState);
    if (formState.comment !== "" && formState.comment !== null) {
      const formData = new FormData();
      formData.append("name", order.slug + " Ticket");
      formData.append("ticket_status", "Pending");
      formData.append("order", orderId);

      const responseTicket = await postApiWithAuth(CREATE_TICKET, formData);

      if (responseTicket.data !== undefined) {
        console.log(responseTicket.data);
        const formDataComment = new FormData();
        formDataComment.append("comment", formState.comment);
        formDataComment.append("ticket", responseTicket.data.data.id);
        const responseComment = await postApiWithAuth(
          CREATE_COMMENT,
          formDataComment
        );
        if (responseComment.data !== undefined) {
          console.log(responseComment.data);
          setModalOpen(false);
          toast.success("Ticket Created Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOrderId("");
          getOrders(statusId);
        } else {
          console.log("Error");
          setModalOpen(false);
          toast.error("Some Backend Error!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setOrderId("");
        }
      } else {
        console.log("Error");
        setModalOpen(false);
        setOrderId("");
        toast.error("Some Backend Error!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      toast.error("Please add comment it's mandatory!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleUpdateStatus = async (ticketId) => {
    // Send a PUT request to update the ticket status to 'Completed'
    const response = await patchApiWithAuth(CREATE_TICKET + `${ticketId}/`, {
      ticket_status: "Completed",
    });
    if (response.data !== undefined) {
      console.log(response.data);
      getOrders(statusId);
      toast.success("Ticket Closed Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      console.log("Error");
      toast.error("Some Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleSaveFacilityNote = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (adminNote !== "") {
      formData.append("note", adminNote);
      const response = await putApiWithAuth(
        CREATE_ADMIN_NOTE + `${orderId}`,
        formData
      );
      if (response.data !== undefined) {
        console.log(response.data);
        setModalFacilityNoteOpen(false);
        setOrderId("");
        setAdminNote("");
        getOrders(statusId);
        setError("");
      } else {
        setModalFacilityNoteOpen(false);
        setOrderId("");
        setAdminNote("");
        setError("");
      }
    } else {
      setError("Add note for save");
    }
  };

  const handleSavePharmacyNote = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (pharmacyNote !== "") {
      formData.append("note", pharmacyNote);
      const response = await putApiWithAuth(
        CREATE_PHARMACY_NOTE + `${orderId}`,
        formData
      );
      if (response.data !== undefined) {
        console.log(response.data);
        getOrders(statusId);
        setModalPharmacyNoteOpen(false);
        setOrderId("");
        setPharmacyNote("");
        setError("");
      } else {
        setModalPharmacyNoteOpen(false);
        setOrderId("");
        setPharmacyNote("");
        setError("");
      }
    } else {
      setError("Add note for save");
    }
  };

  const handleSendTwilioSms = async (e) => {
    e.preventDefault();
    if (selectedNumber !== "") {
      const response = await postApiWithAuth(SEND_TWILIO_SMS, {
        to_number: selectedNumber,
        text: text,
        order_id: order.id
      });
      console.log(response.data);
      if (response.data.error === undefined) {
        toast.success(response.data.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setTwilioModel(false);
      setSelectedNumber("");
      setNumberSeleteModal(false);
    } else {
      setError("Add number to send sms");
    }
  };

  const downloadUploadSlip = (e) => {
    e.stopPropagation();
    window.open(
      `${`${process.env.REACT_APP_LINK_BASE_URL}` + order.order_file}`,
      "_blank"
    );
  };

  const downloadDeliveryProof = () => {
    window.open(`${order.location_image}`, "_blank");
  };

  const actionItems = [
    // {
    //   name: "Upload Slip",
    //   img: action2,
    //   onClick: downloadUploadSlip,
    //   path: "homepage",
    //   group: false,
    //   admin: true,
    // },
    // {
    //   name: "Call Facility",
    //   img: phone,
    //   onClick: makeCall,
    //   path: "homepage",
    //   group: false,
    //   admin: true,
    // },
    // {
    //   name: "Download QR Slip",
    //   img: qrImage,
    //   onClick: handlePrint,
    //   path: "list/order",
    //   group: true,
    //   admin: true,
    //   show: 'all',
    //   color: "default"
    // },
    {
      name: "Staff Notes",
      img: action3,
      onClick: handleCreateFacilityNote,
      path: "dashboard",
      group: false,
      admin: true,
      show: "all",
      color: order.admin_staff_note ? "#30abf8" : "default",
    },
    {
      name: "Reschedule",
      img: action4,
      onClick: handleReschedule,
      path: "list/order",
      group: false,
      admin: true,
      show: "all",
      color: "default",
    },
    // {
    //   name: "Signed Slip",
    //   img: action5,
    //   onClick: {},
    //   path: "list/order",
    //   group: true,
    //   admin: true,
    //   show: 'deliver',
    //   color: "default"
    // },
    // {
    //   name: "Proof Images",
    //   img: action6,
    //   onClick: downloadDeliveryProof,
    //   path: "list/pharmacy",
    //   group: true,
    //   admin: true,
    // },
    {
      name: "Note to Pharmacy",
      img: action7,
      onClick: handleCreatePharmacyNote,
      path: "list/users",
      group: true,
      admin: true,
      show: "all",
      color: order.admin_note ? "#30abf8" : "default",
    },
    // {
    //   name: "Send Message",
    //   img: action7,
    //   onClick: handleTwilioModel,
    //   path: "list/order",
    //   group: true,
    //   admin: true,
    //   show: "all",
    //   color: "default",
    // },
    // {
    //   name: "Driver Notes",
    //   img: action7,
    //   onClick: handleListDriverNote,
    //   path: "list/users",
    //   group: true,
    //   admin: true,
    //   show: 'all',
    // },
    {
      name: "History",
      img: action8,
      onClick: handleCreateHistory,
      path: "list/route",
      group: true,
      admin: true,
      show: "all",
      color: "default",
    },
  ];

  const pharmacyActionItems = [
    {
      name: "Note from Pilldrop",
      img: action7,
      onClick: handleListPilldropNote,
      path: "list/users",
      group: true,
      admin: true,
      show: "all",
      color: order.admin_note ? "#30abf8" : "default",
    },
    {
      name: "Staff Note",
      img: action3,
      onClick: handleCreateFacilityNote,
      path: "dashboard",
      group: true,
      admin: true,
      show: "all",
      color: order.pharmacy_staff_note ? "#30abf8" : "default",
    },
    {
      name: "History",
      img: action8,
      onClick: handleCreateHistory,
      path: "list/route",
      group: true,
      admin: true,
      show: "all",
      color: "default",
    },
  ];
  // Function to handle name click
  const handleNameClick = async (orderId, orderCell) => {
    console.log("Name clicked");
    setLoading(true);
    const response = await getApiWithAuth(
      PERSON_ORDERS + `?orderId=${orderId}&phone=${orderCell}`
    );
    if (response.data !== undefined) {
      // Update state with selected order details
      setSelectedOrderDetails(response.data.data);
      setLoading(false);
      // Open the modal
      setModalnameOpen(true);
    } else {
      console.log("Error");
    }
  };
  return (
    <>
      {loading && <CustomLoader />}
      <div className="overflow-x-auto grid-flow-col auto-cols-max grid lg:grid-cols-9 gap-4 border border-[#D9D9D9] rounded-lg px-md py-sm mb-xs hover:bg-slate-300">
        {order.last_orderticket !== null ? (
          <div className="flex flex-col justify-start gap-[2px] md:col-span-2 lg:col-span-3">
            <div className="flex items-center justify-between">
              <p className="text-[16px] font-[700] underline">
                Order ID - {order.slug}
                {/* {order?.twilioChat &&
                  <IconButton color="inherit">
                    <Badge badgeContent={unreadCounts} color="error">
                      <QuestionAnswerIcon onClick={HandleSmsList} />
                    </Badge>
                  </IconButton>} */}
              </p>

              <div
                className={
                  order.refrigrated && order.photoid
                    ? "urgent-tag refriPhoto"
                    : order.photoid
                      ? "urgent-tag photoId"
                      : order.refrigrated && " urgent-tag refri"
                }
              >
                {order.refrigrated && order.photoid
                  ? "Refrigerated / Photo ID"
                  : order.photoid
                    ? "Photo ID"
                    : order.refrigrated && "Refrigerated"}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex items-center gap-sm">
                <img width="16px" height="auto" src={userBlack} alt="user" />
                <p
                  className="text-[16px] font-[700]"
                  onClick={() => handleNameClick(order.id, order.contact)}
                >
                  {order.name}
                </p>
              </div>
              {/* Pass modalOpen state and selectedOrderDetails to OrderDetailsModal */}
              <OrderDetailsModal
                open={modalnameOpen}
                onClose={() => setModalnameOpen(false)}
                orderDetails={selectedOrderDetails}
              />
            </div>

            {userType === "PharmacyOwner" || userType === "	PharmacyEmployee" ?
              <div className="flex justify-between gap-[2px] xl:flex-row">
                <div className="flex items-center min-w-[100px]">
                  <img width="16px" height="auto" src={cellphone} alt="phone" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis">
                    {order.contact}
                  </p>
                </div>
                <div className="flex items-center min-w-[100px]">
                  <img width="16px" height="auto" src={iphone} alt="mobile" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis">
                    {order.cell}
                  </p>
                </div>
                <div className="flex items-center min-w-[100px]">
                  <img width="16px" height="auto" src={landline} alt="landline" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis">
                    {order.workNumber}
                  </p>
                </div>
              </div>
              :
              <div className="flex justify-between gap-[2px] xl:flex-row">
                <div className="flex items-center min-w-[100px]">
                  <img width="16px" height="auto" src={cellphone} alt="phone" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis">
                    {order.contact}
                  </p>
                </div>
                <div className="flex items-center min-w-[100px]">
                  <img width="16px" height="auto" src={iphone} alt="mobile" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis">
                    {order.cell}
                  </p>
                </div>
                <div className="flex items-center min-w-[100px]">
                  <img width="16px" height="auto" src={landline} alt="landline" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis">
                    {order.workNumber}
                  </p>
                </div>
              </div>
            }

            <div className="flex items-center gap-sm">
              <img width="16px" height="auto" src={buildings} alt="work" />
              <p className="pfontupdate">{order.address}</p>
            </div>

            <div className="flex justify-between">
              <p className="font-[700]">
                Apt #: <span className="apt-in">{order.apart}</span>
              </p>
              <div className="flex justify-end gap-sm">
                {order.totalcopay !== 0 && (
                  <p className="font-[700]">
                    Copay:
                    <span className="apt-in text-green">
                      ${order.totalcopay.toFixed(2)}
                    </span>
                    {order.status === "5" && order.iscollected && (
                      <p className="collect-text">Collected</p>
                    )}
                    {order.status === "5" && order.ispaidpharmacy && (
                      <p className="collect-text">Paid To Pharmacy</p>
                    )}
                    {order.status === "5" && order.iswaivedpharmacy && (
                      <p className="collect-text">Waived By Pharmacy</p>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-start gap-[2px] md:col-span-3 lg:col-span-3">
            <div className="flex items-center justify-between">
              <p className="text-[16px] font-[700] underline">
                Order ID - {order.slug}
                {/* {order?.twilioChat &&
                  <IconButton color="inherit">
                    <Badge badgeContent={unreadCounts} color="error">
                      <QuestionAnswerIcon onClick={HandleSmsList} />
                    </Badge>
                  </IconButton>} */}
              </p>

              <div
                className={
                  order.refrigrated && order.photoid
                    ? "urgent-tag refriPhoto"
                    : order.photoid
                      ? "urgent-tag photoId"
                      : order.refrigrated && " urgent-tag refri"
                }
              >
                {order.refrigrated && order.photoid
                  ? "Refrigerated / Photo ID"
                  : order.photoid
                    ? "Photo ID"
                    : order.refrigrated && "Refrigerated"}
              </div>
              {/* {order.deliverydate === "Urgent" && (
                <div className="urgent-tag urgent">Urgent</div>
              )} */}
            </div>

            <div className="flex justify-between">
              <div className="flex items-center gap-sm">
                <img width="16px" height="auto" src={userBlack} alt="user" />
                <p
                  className="text-[16px] font-[700]"
                  onClick={() => handleNameClick(order.id, order.contact)}
                >
                  {order.name}
                </p>
              </div>
              {/* Pass modalOpen state and selectedOrderDetails to OrderDetailsModal */}
              <OrderDetailsModal
                open={modalnameOpen}
                onClose={() => setModalnameOpen(false)}
                orderDetails={selectedOrderDetails}
              />
            </div>
            {userType === "PharmacyOwner" || userType === "	PharmacyEmployee" ?
              <div className="flex justify-between gap-[2px] xl:flex-row">
                <div className="flex items-center min-w-[100px] gap-sm">
                  <img width="16px" height="auto" src={cellphone} alt="phone" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis pfontupdate">
                    {order.contact}
                  </p>
                </div>
                <div className="flex items-center min-w-[100px] gap-sm">
                  <img width="12px" height="auto" src={iphone} alt="mobile" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis pfontupdate">
                    {order.cell}
                  </p>
                </div>
                <div className="flex items-center min-w-[100px] gap-sm">
                  <img width="16px" height="auto" src={landline} alt="landline" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis pfontupdate">
                    {order.workNumber}
                  </p>
                </div>
                {/* {order.deliverydate === "Urgent" && (
                <div className="urgent-tag urgent">Urgent</div>
              )} */}
              </div>
              :
              <div className="flex justify-between gap-[2px] xl:flex-row">
                <div className="flex items-center min-w-[100px] gap-sm">
                  <img width="16px" height="auto" src={cellphone} alt="phone" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis pfontupdate">
                    {order.contact}
                  </p>
                </div>
                <div className="flex items-center min-w-[100px] gap-sm">
                  <img width="12px" height="auto" src={iphone} alt="mobile" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis pfontupdate">
                    {order.cell}
                  </p>
                </div>
                {/* handleNumberSeleteOpen(order.workNumber)  use this function for calling and sms */}
                <div className="flex items-center min-w-[100px] gap-sm">
                  <img width="16px" height="auto" src={landline} alt="landline" />
                  <p className="text-sm font-[400] max-w-[100px] overflow-hidden overflow-ellipsis pfontupdate">
                    {order.workNumber}
                  </p>
                </div>
                {/* {order.deliverydate === "Urgent" && (
                <div className="urgent-tag urgent">Urgent</div>
              )} */}
              </div>
            }

            <div className="flex items-center gap-sm">
              <img width="16px" height="auto" src={buildings} alt="work" />
              <p className="pfontupdate"> {order.address}</p>
            </div>

            <div className="flex justify-between">
              <p className="font-[700] pfontupdate">
                Apt #: <span className="apt-in">{order.apart}</span>
              </p>

              <div className="flex justify-end gap-sm pfontupdate">
                {order.totalcopay !== 0 && (
                  <p className="font-[700]">
                    Copay:
                    <span className="apt-in text-green">
                      ${order.totalcopay.toFixed(2)}
                    </span>
                    {order.status === "5" && order.iscollected && (
                      <p className="collect-text">Collected</p>
                    )}
                    {order.status === "5" && order.ispaidpharmacy && (
                      <p className="collect-text">Paid To Pharmacy</p>
                    )}
                    {order.status === "5" && order.iswaivedpharmacy && (
                      <p className="collect-text">Waived By Pharmacy</p>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="rounded-md p-sm flex flex-col gap-[2px] items-center justify-center md:col-span-1 lg:col-span-1">
          {order.deliverydate === "Urgent" && (
            <div className="urgent-tag urgent">Urgent</div>
          )}
        </div>

        <div className="flex flex-col gap-[2px] items-start justify-start mx-auto md:col-span-2 lg:col-span-1">
          <h2 className="status text-center">
            Status:
            <span
              className={
                statusMessage === "Ready to Pick Up"
                  ? "readyToPick"
                  : statusMessage === "Received At Facility"
                    ? "receivedAtFacility"
                    : statusMessage === "In transit"
                      ? "inTransit"
                      : statusMessage === "In transit"
                        ? "dispatch"
                        : statusMessage === "Delivered"
                          ? "delivered"
                          : "cancel"
              }
            >
              {statusMessage}
            </span>
          </h2>

          <h2 className="date  text-start">
            Created Date:
            <span className="date-in">
              {moment(order.createdAt).format("MM-DD-YYYY")}
            </span>
          </h2>

          <h2 className="date  text-start">
            Created Time:
            <span className="time-in">
              {moment(order.createdAt).format("hh:mm:ss A")}
            </span>
          </h2>

          <h2 className="date  text-start">
            Delivery Date:
            <span className="date-in">
              {moment(order.deliverAtSelected).format("MM-DD-YYYY")}
            </span>
          </h2>

          <h2 className="date text-start">
            Delivery Time:
            <span className="time-in">{order.deliverydate}</span>
          </h2>

          <div className="orderTicket">
            <h2 className="attempt text-start">
              Attempts: <span className="attempt-in">{order.attempt}</span>
            </h2>

            {type === "pharmacy" &&
              (order.last_orderticket === null ? (
                <button
                  className="ticketbutton"
                  onClick={() => handleCreateTicket(order.id)}
                >
                  Create Ticket
                </button>
              ) : (
                <button className="ticketbutton text-start">
                  Ticket Created
                </button>
              ))}
          </div>
        </div>

        <div className="rounded-md p-sm flex flex-col gap-[2px] items-center justify-start md:col-span-1 lg:col-span-1">
          {order.last_orderticket !== null && (
            <>
              <p className="ticket-time">
                {order.last_orderticket.ticket_status !== "Completed"
                  ? `Created At: ${moment(
                    order.last_orderticket.createdAt
                  ).format("MM-DD-YYYY")}`
                  : `Closed At: ${moment(
                    order.last_orderticket.ticketcomment_set[
                      order.last_orderticket.ticketcomment_set.length - 1
                    ].createdAt
                  ).format("MM-DD-YYYY")}`}
              </p>

              <div className="ticket-div">
                <p className="ticket-text text-black text-[13px]">
                  {order.last_orderticket.ticketcomment_set[0].comment}
                </p>
              </div>
              {type === "pharmacy" ? (
                order.last_orderticket !== null &&
                  order.last_orderticket.ticket_status === "Pending" ? (
                  <button
                    className="ticketclosebutton"
                    style={{ backgroundColor: "red" }}
                    onClick={() => navigate(`/pharmacy/${pid}/ticketlisting`)}
                  >
                    Created
                  </button>
                ) : order.last_orderticket.ticket_status === "Active" ? (
                  <button
                    className="ticketclosebutton"
                    style={{ backgroundColor: "red" }}
                    onClick={() => navigate(`/pharmacy/${pid}/ticketlisting`)}
                  >
                    Active
                  </button>
                ) : (
                  <button
                    className="ticketclosebutton"
                    style={{ backgroundColor: "green" }}
                    onClick={() => navigate(`/pharmacy/${pid}/ticketlisting`)}
                  >
                    {order.last_orderticket.ticket_status}
                  </button>
                )
              ) : order.last_orderticket !== null &&
                order.last_orderticket.ticket_status === "Completed" ? (
                <button
                  className="ticketclosebutton"
                  style={{ backgroundColor: "green" }}
                  onClick={() => navigate(`/ticketlisting`)}
                >
                  Completed
                </button>
              ) : (
                <button
                  className="ticketclosebutton"
                  style={{ backgroundColor: "red" }}
                  onClick={() => navigate(`/ticketlisting`)}
                >
                  Pending
                </button>
              )}
            </>
          )}
        </div>

        <div
          className="deliveryBox rounded-md bg-#30abf8 p-sm flex flex-col gap-[2px] items-center justify-start md:col-span-2 lg:col-span-1"
          style={{ width: "230px", height: "130px" }}
        >
          <p className="text-[20px] font-[700] text-white underline">
            Delivery Note
          </p>
          <TextareaAutosize
            maxRows={4}
            style={{ background: "transparent", textAlign: "center" }}
            className="bg-#30abf8 text-white w-full text-[13px] mt-sm"
            value={order.notes}
          />
        </div>

        <div
          className="min-w-max flex flex-col gap-[2px] mx-auto md:col-span-1 lg:col-start-9 lg:col-span-1 "
          style={{ justifyContent: "center" }}
        >
          <a
            href={
              (userType === "PharmacyOwner" ||
                userType === "PharmacyEmployee") &&
                order.status === "1"
                ? `/pharmacy/${pid}/update/order/${order.id}`
                : (userType === "PharmacyOwner" ||
                  userType === "PharmacyEmployee") &&
                  order.status !== "1"
                  ? `/pharmacy/${pid}/detail/order/${order.id}`
                  : order.status === "1" ||
                    order.status === "2" ||
                    order.status === "3" ||
                    order.status === "4"
                    ? `/update/order/${order.id}`
                    : `/detail/order/${order.id}`

              // handleViewModalOpen(order.id) :
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="action-div"
            // onClick={() =>
            //   pid !== undefined
            //     ? navigate(`/pharmacy/${pid}/update/order/${order.id}`)
            //     : navigate(`/detail/order/${order.id}`)
            // }
            >
              <i
                className="fa fa-eye action-icon"
                style={{ fontSize: "11px" }}
              />
              <p className="action-text">View / Edit Order</p>
            </div>
          </a>
          {order.status === "5" ? (
            // <PDFDownloadLink
            //   document={<PrintDocDownList orderDetail={order} pharmacy={order.pharmacy} />}
            //   fileName="client-details.pdf"
            // >
            //   {({ blob, url, loading, error }) => (
            //     loading ? 'Loading document...' : (error ? error :
            //       <div className="action-div" >
            //         <img
            //           className="action-icon"
            //           src={action5}
            //           alt="Logo"
            //           width="11px"
            //           height="auto"
            //         />
            //         <p className="action-text">Signed Slip</p>
            //       </div>
            //       )
            //   )}
            // </PDFDownloadLink>
            <div
              className="action-div"
              onClick={() => {
                if (order.pharmacy.pos_types === "PrimeRX") {
                  getOrderDetail();
                } else {
                  window.open(
                    `https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${order.slug}_signed.pdf?embedded=true`,
                    "_blank"
                  );
                }
              }}
            >
              <img
                className="action-icon"
                src={action5}
                alt="Logo"
                width="11px"
                height="auto"
              />
              <p className="action-text">Signed Slip</p>
            </div>
          ) : (
            <div className="action-div" onClick={() => handlePrint()}>
              <img
                className="action-icon"
                src={action5}
                alt="Logo"
                width="11px"
                height="auto"
              />
              <p className="action-text">QR Slip</p>
            </div>
          )}

          {userType === "PharmacyOwner" || userType === "	PharmacyEmployee"
            ? pharmacyActionItems
              .filter((actionItem) => {
                if (actionItem.show === "deliver") {
                  return order.status === "5" || order.status === "6";
                } else {
                  return true; // Show for all other statuses
                }
              })
              .map((actionItem, i) => (
                <div
                  key={i}
                  className={
                    actionItem.color === "#30abf8"
                      ? "action-div-color"
                      : "action-div"
                  }
                  onClick={() => {
                    if (typeof actionItem.onClick === "function") {
                      actionItem.onClick();
                    }
                    setOrderId(order.id);
                  }}
                >
                  <img
                    className="action-icon"
                    src={actionItem.img}
                    alt="Logo"
                    width="11px"
                    height="auto"
                  />
                  <p className="action-text">{actionItem.name}</p>
                </div>
              ))
            : actionItems
              .filter((actionItem) => {
                if (actionItem.show === "deliver") {
                  return order.status === "5" || order.status === "6";
                } else {
                  return true; // Show for all other statuses
                }
              })
              .map((actionItem, i) => (
                <div
                  key={i}
                  className={
                    actionItem.color === "#30abf8"
                      ? "action-div-color"
                      : "action-div"
                  }
                  onClick={() => {
                    if (typeof actionItem.onClick === "function") {
                      actionItem.onClick();
                    }
                    setOrderId(order.id);
                  }}
                >
                  <img
                    className="action-icon"
                    src={actionItem.img}
                    alt="Logo"
                    width="11px"
                    height="auto"
                  />
                  <p className="action-text">{actionItem.name}</p>
                </div>
              ))}
        </div>

        <Dialog
          open={modalViewOpen}
          onClose={handleViewModalClose}
          fullWidth={true}
          maxWidth="xl"
        >
          {/* <DialogContent>
            <label className="pharmacy-field-label">Subject</label>
            <input
              type="text"
              className="modal-field"
              name="subject"
              placeholder="Subject"
              value={formState.subject}
              onChange={handleInputChange}
            />
            <label className="pharmacy-field-label">Comment</label>
            <textarea
              type="text"
              className="modal-field"
              name="comment"
              placeholder="Comment"
              value={formState.comment}
              onChange={handleInputChange}
              rows={4}
            />
            <Button
              onClick={handleSaveTicket}
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogContent> */}
          <DetailOrder
            orderid={orderId}
            handleViewModalClose={handleViewModalClose}
          />
        </Dialog>
        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          PaperProps={{ style: { height: "200px !important" } }}
        >
          <DialogTitle>Create Ticket</DialogTitle>
          <DialogContent>
            <label className="pharmacy-field-label">Comment</label>
            <textarea
              type="text"
              className="modal-field"
              name="comment"
              placeholder="Comment"
              value={formState.comment}
              onChange={handleInputChange}
              rows={4}
              style={{ height: "150px" }}
            />
            <Button
              onClick={handleSaveTicket}
              style={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
              disabled={isButtonDisabled}
            >
              Save
            </Button>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogContent>
        </Dialog>

        <Dialog
          open={modalFacilityNoteOpen}
          onClose={handleModalFacilityNoteClose}
          maxWidth="xl"
        >
          <DialogTitle
            sx={{
              backgroundColor: "#D9D9D9",
              color: "#0455A6",
              fontWeight: "800",
              padding: 1,
            }}
          >
            Staff Notes
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1 }}>
            <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
            <textarea
              type="text"
              className="modal-field notes-field"
              name="note"
              autoFocus
              placeholder="Write your note here!!!"
              value={adminNote}
              onChange={(e) => setAdminNote(e.target.value)}
              rows={4}
            />
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              gap={"15px"}
              marginLeft={23}
            >
              <Button
                onClick={handleModalFacilityNoteClose}
                variant="outlined"
                color="inherit"
                sx={{ backgroundColor: "white" }}
              >
                Clear
              </Button>
              <Button
                onClick={handleSaveFacilityNote}
                variant="outlined"
                color="inherit"
                sx={{ backgroundColor: "white" }}
              >
                Save
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          open={modalPharmacyNoteOpen}
          onClose={handleModalPharmacyNoteClose}
          maxWidth="md"
        >
          <DialogTitle
            sx={{
              backgroundColor: "#D9D9D9",
              color: "#0455A6",
              fontWeight: "800",
              padding: 1,
            }}
          >
            Note To Pharmacy
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1 }}>
            {/* <label className="pharmacy-field-label">Note</label> */}
            <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
            <textarea
              type="text"
              className="modal-field notes-field"
              name="note"
              autoFocus
              placeholder="Write your note here!!!"
              value={pharmacyNote}
              onChange={(e) => setPharmacyNote(e.target.value)}
              rows={4}
            />
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              gap="20px"
            >
              <Button
                onClick={handleModalPharmacyNoteClose}
                variant="outlined"
                color="inherit"
                sx={{ backgroundColor: "white" }}
              >
                Delete For Everyone
              </Button>
              <Button
                onClick={handleSavePharmacyNote}
                variant="outlined"
                color="inherit"
                sx={{ backgroundColor: "white" }}
              >
                Send To Pharmacy
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          open={twilioModel}
          onClose={handleTwilioModelClose}
          maxWidth="md"
        >
          <DialogTitle
            sx={{
              backgroundColor: "#D9D9D9",
              color: "#0455A6",
              fontWeight: "800",
              padding: 1,
            }}
          >
            Send Message to client
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1 }}>
            <input
              type="number"
              name="toNumber"
              className="modal-field"
              value={selectedNumber}
              placeholder="Write number here!!"
            />
            <textarea
              type="text"
              className="modal-field notes-field"
              name="text"
              autoFocus
              placeholder="Write your message here!!!"
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows={4}
            />
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              gap="20px"
            >
              <Button
                onClick={handleSendTwilioSms}
                variant="outlined"
                color="inherit"
                sx={{ backgroundColor: "white" }}
              >
                Send Message
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          open={modalPilldropNoteList}
          onClose={handleListPilldropNoteClose}
          maxWidth="md"
          style={{ minWidth: "400px" }}
        >
          <DialogTitle
            sx={{
              backgroundColor: "#D9D9D9",
              color: "#0455A6",
              fontWeight: "800",
              padding: 1,
            }}
          >
            Note From Pilldrop
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1 }}>
            {order.admin_note && (
              <p className="driverNote">{order.admin_note}</p>
            )}
            {driverNotes.length > 0 &&
              driverNotes?.map((note, i) => (
                <p key={i} className="driverNote">
                  {note.note}
                </p>
              ))}
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              gap="20px"
              mt="20px"
            >
              <Button
                onClick={handleListPilldropNoteClose}
                variant="outlined"
                color="inherit"
                sx={{ backgroundColor: "white" }}
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          open={modalDriverNoteList}
          onClose={handleListDriverNoteClose}
          maxWidth="md"
          style={{ minWidth: "400px" }}
        >
          <DialogTitle
            sx={{
              backgroundColor: "#D9D9D9",
              color: "#0455A6",
              fontWeight: "800",
              padding: 1,
            }}
          >
            Driver Notes
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1 }}>
            {driverNotes.length > 0 &&
              driverNotes?.map((note, i) => (
                <p key={i} className="driverNote">
                  {note.note}
                </p>
              ))}
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              gap="20px"
              mt="20px"
            >
              <Button
                onClick={handleListDriverNoteClose}
                variant="outlined"
                color="inherit"
                sx={{ backgroundColor: "white" }}
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          // minWidth="lg"
          className="hisotryModal"
          open={modalHistoryOpen}
          onClose={handleModalHistoryClose}
          // style={{minWidth: "600px"}}
          // sx={{ '& .MuiDialog-paper': { p: 0 }, '& .MuiBackdrop-root': { opacity: '0.5 !important' } }}
          fullWidth
          scroll="body"
          PaperProps={{
            sx: {
              verticalAlign: "bottom",
              position: "fixed",
              right: 0,
              bottom: 0,
            },
          }}
        >
          <DialogTitle>
            <div className="orderHistoryline1">
              <h5 className="historyLine1">
                <span>{history?.orderDetail?.pharmacy?.name}</span>
              </h5>
              <h5 className="historyLine1" style={{ display: "flex" }}>
                <span style={{ marginLeft: 10, display: "flex" }}>
                  <img
                    width="16px"
                    height="auto"
                    style={{ marginRight: 10, display: "flex" }}
                    src={cellphone}
                    alt="phone"
                  />{" "}
                  {history?.orderDetail?.pharmacy?.contact_no}
                </span>
                <span style={{ marginLeft: 10, display: "flex" }}>
                  <img
                    width="16px"
                    height="auto"
                    style={{ marginRight: 10, display: "flex" }}
                    src={cellphone}
                    alt="phone"
                  />{" "}
                  {history?.orderDetail?.pharmacy?.emergency_contact}
                </span>
              </h5>
            </div>
            <Box className="history-icon-and-text-container">
              <img src={action8} className="history-icon" />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography className="history-text">History</Typography>
              </Stack>
            </Box>
            <div className="orderHistoryline1">
              <h5 className="historyLine1">
                Order ID:
                <span style={{ marginLeft: 10, textDecoration: "underline" }}>
                  {history?.orderDetail?.slug}
                </span>
              </h5>
              <h5 className="historyLine1">
                CLIENT:
                <span style={{ marginLeft: 10, textDecoration: "underline" }}>
                  {history?.orderDetail?.name}
                </span>
              </h5>
            </div>
            <div className="orderHistoryline1">
              <p className="historyLine2">
                Delivery Date:{" "}
                {moment(history?.orderDetail?.deliverAt).format("MM-DD-YY")}
              </p>
              <p className="historyLine2">
                Time Window: {history?.orderDetail?.deliverydate}
              </p>
            </div>
            <div className="orderHistoryline1">
              <p className="historyLine2">
                Delivery Preference: {history?.orderDetail?.parcelPlace}
              </p>
              <p className="historyLine2">
                Signatures: &nbsp;
                {history?.orderDetail?.signature ? "Required" : "Not Required"}
              </p>
            </div>
            <div className="orderHistoryline1">
              {(order.hippaform || order.lineform || order.nfaqbform) && (
                <>
                  <p>Get Forms Signed:</p>
                  <p
                    className="historyLine2"
                    style={{ margin: "0", fontWeight: 700 }}
                  >
                    {order.hippaform && <span>Hipaa Form</span>}
                    {order.lineform && (
                      <span
                        style={{
                          marginLeft: "12px",
                          fontFamily: "Saira, sans-serif",
                        }}
                      >
                        Lien Form
                      </span>
                    )}
                    {order.nfaqbform && (
                      <span
                        style={{
                          marginLeft: "12px",
                          fontFamily: "Saira, sans-serif",
                        }}
                      >
                        NF AOB Form
                      </span>
                    )}
                  </p>
                </>
              )}
              <p className="historyLine2">
                {order.refrigrated && order.photoid
                  ? "REFRIGERATED / Photo ID"
                  : order.photoid
                    ? "Photo ID"
                    : order.refrigrated && "Refrigerated"}
              </p>
            </div>
            <div className="orderHistoryline3">
              {history?.orderDetail?.notes !== "" &&
                history?.orderDetail?.notes !== null ? (
                <p className="historyLine3">
                  Note:{" "}
                  <span style={{ fontWeight: "400" }}>
                    {history?.orderDetail?.notes}
                  </span>{" "}
                  :
                </p>
              ) : (
                <p></p>
              )}
              {history?.orderDetail?.totalcopay !== 0 && (
                <>
                  <p className="historyLine4">
                    Copay: &nbsp; &nbsp; $
                    {history?.orderDetail?.totalcopay.toFixed(2)}
                  </p>
                  {order.status === "5" && order.iscollected && (
                    <p className="collect-text">
                      Collected By:{" "}
                      {history?.orderDetail?.driver?.firstName +
                        " " +
                        history?.orderDetail?.driver?.lastName}
                    </p>
                  )}
                  {order.status === "5" && order.ispaidpharmacy && (
                    <p className="collect-text">
                      Received By: {history?.orderDetail?.receiverName}
                    </p>
                  )}
                  {order.status === "5" && order.iswaivedpharmacy && (
                    <p className="collect-text">
                      Waived By: {history?.orderDetail?.waivedBy}
                    </p>
                  )}
                </>
              )}
            </div>
            <div className="orderHistoryline1">
              {history?.orderDetail?.orderticket ? (
                <>
                  <p className="historyLine5">
                    Ticket:{" "}
                    <span style={{ color: "grey", fontWeight: "400" }}>
                      {
                        history?.orderDetail?.orderticket?.ticketcomment_set[0]
                          .comment
                      }
                    </span>
                  </p>
                  <div style={{ textAlign: "right" }}>
                    <p className="historyLine2">
                      Created At: &nbsp;
                      {moment(
                        history?.orderDetail?.orderticket?.created_at
                      ).format("MM-DD-YY")}{" "}
                      &nbsp;
                      {moment(
                        history?.orderDetail?.orderticket?.created_at
                      ).format("hh:mm:ssa")}
                    </p>
                    <p className="historyLine2">
                      By: &nbsp;
                      {history?.orderDetail?.orderticket?.created_by?.username}
                    </p>
                    {history?.orderDetail?.orderticket?.ticket_status ===
                      "Completed" && (
                        <>
                          <p className="historyLine6">
                            <span style={{ color: "red" }}>Closed At: </span>
                            &nbsp;
                            {moment(
                              history?.orderDetail?.orderticket?.updated_at
                            ).format("MM-DD-YY")}{" "}
                            &nbsp;
                            {moment(
                              history?.orderDetail?.orderticket?.updated_at
                            ).format("hh:mm:ssa")}
                          </p>
                          <p className="historyLine2">
                            By: &nbsp;
                            {history?.orderDetail?.orderticket?.updated_by
                              ?.first_name +
                              history?.orderDetail?.orderticket?.updated_by
                                ?.last_name}
                          </p>
                        </>
                      )}
                  </div>
                </>
              ) : (
                <p></p>
              )}
            </div>
          </DialogTitle>
          {/* <Divider /> */}
          <div style={{ height: "400px", overflowY: "auto" }}>
            {history?.orderDetail?.cancelAt === "" ||
              history?.orderDetail?.cancelAt === null ? (
              <Timeline
                sx={{
                  "& .MuiTimelineItem-root": { minHeight: 35 },
                  "& .MuiTimelineOppositeContent-root": { mt: 0.5 },
                  "& .MuiTimelineDot-root": {
                    borderRadius: 1.25,
                    boxShadow: "none",
                    margin: 0,
                    ml: 1.25,
                    mr: 1.25,
                    p: 1,
                    "& .MuiSvgIcon-root": { fontSize: "1.2rem" },
                  },
                  "& .MuiTimelineContent-root": {
                    borderRadius: 1,
                    bgcolor: "secondary.lighter",
                  },
                  "& .MuiTimelineConnector-root": {
                    border: "1px dashed",
                    borderColor: "secondary.light",
                    bgcolor: "transparent",
                  },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <TimelineItem>
                  <TimelineContent>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "12px", fontWeight: "700" }}
                      component="span"
                    >
                      Created By: {history?.orderDetail?.createdby?.username}
                    </Typography>
                  </TimelineContent>
                  <TimelineOppositeContent
                    align="right"
                    style={{ fontSize: "12px", fontWeight: "400" }}
                  >
                    At :{" "}
                    {moment(history?.orderDetail?.createdAt).format("MM-DD-YY")}{" "}
                    &nbsp;
                    {moment(history?.orderDetail?.createdAt).format(
                      "hh:mm:ssa"
                    )}
                  </TimelineOppositeContent>
                </TimelineItem>
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <TimelineItem>
                  <TimelineContent>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "12px", fontWeight: "700" }}
                      component="span"
                    >
                      At Ready For Pick Up
                    </Typography>
                  </TimelineContent>
                  <TimelineSeparator>
                    <div
                      style={{
                        backgroundColor: "#E4F2FF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <img
                        src={readyPickOrder}
                        style={{ fontSize: "1rem", color: "#30abf8" }}
                      />
                    </div>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineOppositeContent
                    align="right"
                    style={{ fontSize: "12px", fontWeight: "400" }}
                  >
                    At :{" "}
                    {moment(history?.orderDetail?.createdAt).format("MM-DD-YY")}{" "}
                    &nbsp;
                    {moment(history?.orderDetail?.createdAt).format(
                      "hh:mm:ssa"
                    )}
                    {history?.orderhistory?.length > 0 &&
                      history.orderhistory.map((item, i) => {
                        if (item.name === "update_Order") {
                          return (
                            <span>
                              <br />
                              Updated At: {moment(item.date).format(
                                "MM-DD-YY"
                              )}{" "}
                              &nbsp;
                              {moment(item.date).format("hh:mm:ssa")}
                              <br />
                              By: {item.updatedBy}
                            </span>
                          );
                        }
                      })}
                  </TimelineOppositeContent>
                </TimelineItem>
                {history?.orderhistory?.length > 0 ? (
                  history.orderhistory.map((item, i, array) => {
                    if (item.name === "update_Order") {
                      // Skip this iteration if the name is "update_Order"
                      return null;
                    }

                    const isOutForDelivery = item.name === "out_For_Delivery";
                    const isDelivered = item.name === "Delivered";
                    const isReturned = item.name === "returned_Failed";
                    // Count the occurrences of "returned_Failed" before the current item
                    const attempt = array
                      .slice(0, i + 1)
                      .filter((obj) => obj.name === "returned_Failed").length;
                    console.log("attemot", attempt);
                    // Construct the attempt message if it's a returned failed status and attempt number exists
                    const attemptMessage = isReturned
                      ? ` (Attempt ${attempt})`
                      : "";

                    return (
                      <React.Fragment key={i}>
                        <div
                          style={{
                            width: "100%",
                            height: "8px",
                            backgroundColor: "#E2E2E2",
                          }}
                        ></div>
                        <TimelineItem>
                          <TimelineContent>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.display_name}
                              {attemptMessage}
                            </Typography>
                            {isOutForDelivery && (
                              <>
                                <Typography
                                  variant="h1"
                                  component="span"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    marginLeft: "20px",
                                  }}
                                >
                                  ETD {item.comment}
                                </Typography>

                                {order?.out_delivery_sms && (
                                  <Typography
                                    color="textSecondary"
                                    style={{ fontSize: "12px", fontWeight: "400" }}
                                  >
                                    Delivery SMS At: {moment(order?.out_delivery_at).format("MM-DD-YY")} &nbsp;{" "}
                                    {moment(order?.out_delivery_at).format("hh:mm:ssa")}
                                    <br />
                                    <Tooltip title={order?.out_delivery_sms || "No SMS available"} arrow>
                                      <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                        {order?.out_delivery_sms ? "View SMS" : "No SMS"}
                                      </span>
                                    </Tooltip>
                                  </Typography>
                                )}
                                {order?.navigate_sms && (
                                  <Typography
                                    color="textSecondary"
                                    style={{ fontSize: "12px", fontWeight: "400" }}
                                  >
                                    Navigate At: {moment(order?.navigate_at).format("MM-DD-YY")} &nbsp;{" "}
                                    {moment(order?.navigate_at).format("hh:mm:ssa")}
                                    <br />
                                    <Tooltip title={order?.navigate_sms || "No SMS available"} arrow>
                                      <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                        {order?.navigate_sms ? "View SMS" : "No SMS"}
                                      </span>
                                    </Tooltip>
                                  </Typography>
                                )}
                                {order?.e_sign_sms && (
                                  <Typography
                                    color="textSecondary"
                                    style={{ fontSize: "12px", fontWeight: "400" }}
                                  >
                                    E-Sign At: {moment(order?.e_sign_at).format("MM-DD-YY")} &nbsp;{" "}
                                    {moment(order?.e_sign_at).format("hh:mm:ssa")}
                                    <br />
                                    <Tooltip title={order?.e_sign_sms || "No SMS available"} arrow>
                                      <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                        {order?.e_sign_sms ? "View SMS" : "No SMS"}
                                      </span>
                                    </Tooltip>
                                  </Typography>
                                )}
                              </>
                            )}
                            {isDelivered && (
                              <Typography
                                color="textSecondary"
                                style={{ fontSize: "12px", fontWeight: "400" }}
                              >
                                {item.comment}
                              </Typography>
                            )}
                            {isReturned && (
                              <Typography
                                color="textSecondary"
                                style={{ fontSize: "12px", fontWeight: "400" }}
                              >
                                {item.comment}
                              </Typography>
                            )}
                          </TimelineContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <div
                              style={{
                                backgroundColor: item.icon_bg,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30px",
                                width: "30px",
                              }}
                            >
                              <img
                                src={item.icon_url}
                                style={{ fontSize: "1rem" }}
                              />
                            </div>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineOppositeContent
                            align="right"
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            At: {moment(item.date).format("MM-DD-YY")} &nbsp;{" "}
                            {moment(item.date).format("hh:mm:ssa")}
                            <br />
                            By: {item.updatedBy}
                          </TimelineOppositeContent>
                        </TimelineItem>
                      </React.Fragment>
                    );
                  })
                ) : history?.orderDetail?.recievedFaciltyAt !== null ? (
                  <TimelineItem>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="span"
                        style={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Received at Facility
                      </Typography>
                    </TimelineContent>

                    <TimelineSeparator>
                      <TimelineConnector />
                      <div
                        style={{
                          backgroundColor: "#ECF7FF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "30px",
                          width: "30px",
                        }}
                      >
                        <img
                          src={recievedFacilityOrder}
                          style={{ fontSize: "1rem" }}
                        />
                      </div>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineOppositeContent
                      align="right"
                      style={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      At:{" "}
                      {moment(history?.orderDetail?.recievedFaciltyAt).format(
                        "MM-DD-YY"
                      )}{" "}
                      &nbsp;
                      {moment(history?.orderDetail?.recievedFaciltyAt).format(
                        "hh:mm:ssa"
                      )}
                    </TimelineOppositeContent>
                  </TimelineItem>
                ) : (
                  history?.orderDetail?.trasitAt !== null && (
                    <>
                      <TimelineItem>
                        <TimelineContent>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ fontSize: "12px", fontWeight: "700" }}
                          >
                            At Transit
                          </Typography>
                        </TimelineContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <div
                            style={{
                              backgroundColor: "#FFF8EE",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "30px",
                              width: "30px",
                            }}
                          >
                            <img
                              src={transitOrder}
                              style={{ fontSize: "1rem" }}
                            />
                          </div>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineOppositeContent
                          align="right"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          At:{" "}
                          {moment(history?.orderDetail?.trasitAt).format(
                            "MM-DD-YY"
                          )}
                          {moment(history?.orderDetail?.trasitAt).format(
                            "hh:mm:ssa"
                          )}
                        </TimelineOppositeContent>
                      </TimelineItem>
                    </>
                  )
                )}
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
              </Timeline>
            ) : (
              <Timeline
                sx={{
                  "& .MuiTimelineItem-root": { minHeight: 35 },
                  "& .MuiTimelineOppositeContent-root": { mt: 0.5 },
                  "& .MuiTimelineDot-root": {
                    borderRadius: 1.25,
                    boxShadow: "none",
                    margin: 0,
                    ml: 1.25,
                    mr: 1.25,
                    p: 1,
                    "& .MuiSvgIcon-root": { fontSize: "1.2rem" },
                  },
                  "& .MuiTimelineContent-root": {
                    borderRadius: 1,
                    bgcolor: "secondary.lighter",
                  },
                  "& .MuiTimelineConnector-root": {
                    border: "1px dashed",
                    borderColor: "secondary.light",
                    bgcolor: "transparent",
                  },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <TimelineItem>
                  <TimelineContent>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "12px", fontWeight: "700" }}
                      component="span"
                    >
                      Created By: {history?.orderDetail?.createdby?.first_name}{" "}
                      {history?.orderDetail?.createdby?.last_name}
                    </Typography>
                  </TimelineContent>
                  <TimelineOppositeContent
                    align="right"
                    style={{ fontSize: "12px", fontWeight: "400" }}
                  >
                    At :{" "}
                    {moment(history?.orderDetail?.createdAt).format("MM-DD-YY")}{" "}
                    &nbsp;
                    {moment(history?.orderDetail?.createdAt).format(
                      "hh:mm:ssa"
                    )}
                  </TimelineOppositeContent>
                </TimelineItem>
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <TimelineItem>
                  <TimelineContent>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "12px", fontWeight: "700" }}
                      component="span"
                    >
                      At Ready For Pick Up
                    </Typography>
                  </TimelineContent>
                  <TimelineSeparator>
                    <div
                      style={{
                        backgroundColor: "#E4F2FF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <img src={readyPickOrder} style={{ fontSize: "1rem" }} />
                    </div>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineOppositeContent
                    align="right"
                    style={{ fontSize: "12px", fontWeight: "400" }}
                  >
                    At :{" "}
                    {moment(history?.orderDetail?.createdAt).format("MM-DD-YY")}{" "}
                    &nbsp;
                    {moment(history?.orderDetail?.createdAt).format(
                      "hh:mm:ssa"
                    )}
                  </TimelineOppositeContent>
                  {history?.orderhistory?.length > 0 &&
                    history.orderhistory.map((item, i) => {
                      if (item.name === "update_Order") {
                        <TimelineOppositeContent
                          align="right"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          Updated At: {moment(item.date).format("MM-DD-YY")}{" "}
                          &nbsp;
                          {moment(item.date).format("hh:mm:ssa")}
                          <br />
                          By: {item.updatedBy}
                        </TimelineOppositeContent>;
                      }
                    })}
                </TimelineItem>

                {history?.orderhistory?.length > 0 ? (
                  history.orderhistory.map((item, i, array) => {
                    if (item.name === "update_Order") {
                      // Skip this iteration if the name is "update_Order"
                      return null;
                    }

                    const isOutForDelivery = item.name === "out_For_Delivery";
                    const isCancelled = item.name === "cancelled";
                    const isReturned = item.name === "returned_Failed";
                    // Count the occurrences of "returned_Failed" before the current item
                    const attempt = array
                      .slice(0, i + 1)
                      .filter((obj) => obj.name === "returned_Failed").length;
                    console.log("attemot", attempt);
                    // Construct the attempt message if it's a returned failed status and attempt number exists
                    const attemptMessage = isReturned
                      ? ` (Attempt ${attempt})`
                      : "";

                    return (
                      <React.Fragment key={i}>
                        <div
                          style={{
                            width: "100%",
                            height: "8px",
                            backgroundColor: "#E2E2E2",
                          }}
                        ></div>
                        <TimelineItem>
                          <TimelineContent>
                            <Typography
                              variant="h6"
                              component="span"
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.display_name}
                              {attemptMessage}
                            </Typography>
                            {isOutForDelivery && (
                              <>
                                <Typography
                                  variant="h1"
                                  component="span"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    marginLeft: "20px",
                                  }}
                                >
                                  ETD {item.comment}
                                </Typography>
                                {isOutForDelivery && (
                                  <>
                                    <Typography
                                      variant="h1"
                                      component="span"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        marginLeft: "20px",
                                      }}
                                    >
                                      ETD {item.comment}
                                    </Typography>
                                    {order?.out_delivery_sms && (
                                      <Typography
                                        color="textSecondary"
                                        style={{ fontSize: "12px", fontWeight: "400" }}
                                      >
                                        Delivery SMS At: {moment(order?.out_delivery_at).format("MM-DD-YY")} &nbsp;{" "}
                                        {moment(order?.out_delivery_at).format("hh:mm:ssa")}
                                        <br />
                                        <Tooltip title={order?.out_delivery_sms || "No SMS available"} arrow>
                                          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                            {order?.out_delivery_sms ? "View SMS" : "No SMS"}
                                          </span>
                                        </Tooltip>
                                      </Typography>
                                    )}
                                    {order?.navigate_sms && (
                                      <Typography
                                        color="textSecondary"
                                        style={{ fontSize: "12px", fontWeight: "400" }}
                                      >
                                        Navigate At: {moment(order?.navigate_at).format("MM-DD-YY")} &nbsp;{" "}
                                        {moment(order?.navigate_at).format("hh:mm:ssa")}
                                        <br />
                                        <Tooltip title={order?.navigate_sms || "No SMS available"} arrow>
                                          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                            {order?.navigate_sms ? "View SMS" : "No SMS"}
                                          </span>
                                        </Tooltip>
                                      </Typography>
                                    )}
                                    {order?.e_sign_sms && (
                                      <Typography
                                        color="textSecondary"
                                        style={{ fontSize: "12px", fontWeight: "400" }}
                                      >
                                        E-Sign At: {moment(order?.e_sign_at).format("MM-DD-YY")} &nbsp;{" "}
                                        {moment(order?.e_sign_at).format("hh:mm:ssa")}
                                        <br />
                                        <Tooltip title={order?.e_sign_sms || "No SMS available"} arrow>
                                          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                                            {order?.e_sign_sms ? "View SMS" : "No SMS"}
                                          </span>
                                        </Tooltip>
                                      </Typography>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {isCancelled && (
                              <Typography
                                color="textSecondary"
                                style={{ fontSize: "12px", fontWeight: "400" }}
                              >
                                {history?.orderDetail?.cancel_note}
                              </Typography>
                            )}
                            {isReturned && (
                              <Typography
                                color="textSecondary"
                                style={{ fontSize: "12px", fontWeight: "400" }}
                              >
                                {item.comment}
                              </Typography>
                            )}
                          </TimelineContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <div
                              style={{
                                backgroundColor: item.icon_bg,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30px",
                                width: "30px",
                              }}
                            >
                              <img
                                src={item.icon_url}
                                style={{ fontSize: "1rem" }}
                              />
                            </div>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineOppositeContent
                            align="right"
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            At: {moment(item.date).format("MM-DD-YY")} &nbsp;{" "}
                            {moment(item.date).format("hh:mm:ssa")}
                            <br />
                            By: {item.updatedBy}
                          </TimelineOppositeContent>
                        </TimelineItem>
                      </React.Fragment>
                    );
                  })
                ) : history?.orderDetail?.recievedFaciltyAt !== null ? (
                  <TimelineItem>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="span"
                        style={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Received at Facility
                      </Typography>
                    </TimelineContent>

                    <TimelineSeparator>
                      <TimelineConnector />
                      <div
                        style={{
                          backgroundColor: "#ECF7FF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "30px",
                          width: "30px",
                        }}
                      >
                        <img
                          src={recievedFacilityOrder}
                          style={{ fontSize: "1rem" }}
                        />
                      </div>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineOppositeContent
                      align="right"
                      style={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      At:{" "}
                      {moment(history?.orderDetail?.recievedFaciltyAt).format(
                        "MM-DD-YY"
                      )}{" "}
                      &nbsp;
                      {moment(history?.orderDetail?.recievedFaciltyAt).format(
                        "hh:mm:ssa"
                      )}
                    </TimelineOppositeContent>
                  </TimelineItem>
                ) : (
                  history?.orderDetail?.trasitAt !== null && (
                    <>
                      <TimelineItem>
                        <TimelineContent>
                          <Typography
                            variant="h6"
                            component="span"
                            style={{ fontSize: "12px", fontWeight: "700" }}
                          >
                            At Transit
                          </Typography>
                        </TimelineContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <div
                            style={{
                              backgroundColor: "#FFF8EE",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "30px",
                              width: "30px",
                            }}
                          >
                            <img
                              src={transitOrder}
                              style={{ fontSize: "1rem" }}
                            />
                          </div>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineOppositeContent
                          align="right"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          At:{" "}
                          {moment(history?.orderDetail?.trasitAt).format(
                            "MM-DD-YY"
                          )}
                          {moment(history?.orderDetail?.trasitAt).format(
                            "hh:mm:ssa"
                          )}
                        </TimelineOppositeContent>
                      </TimelineItem>
                    </>
                  )
                )}
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundColor: "#E2E2E2",
                  }}
                ></div>
              </Timeline>
            )}
          </div>
          {/* <Divider /> */}
          <DialogActions sx={{ p: 2.5 }}>
            <Button
              onClick={handleModalHistoryClose}
              color="primary"
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={numberSeleteModal}
          onClose={handleNumberSeleteClose}
          maxWidth="xl"
        >
          <DialogTitle
            sx={{
              backgroundColor: "#D9D9D9",
              color: "#0455A6",
              fontWeight: "800",
              padding: 1,
            }}
          >
            Select number for call
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1 }}>
            <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
            <div className="phone-container">
              {order.contact && (
                <div className={`phone-entry ${selectedNumber === order.contact ? 'selected' : ''}`}>
                  <span className="phone-label">Phone:</span>
                  <span className="phone-number">{order.contact}</span>
                  <span className="icon" onClick={() => handleMakeCall(order.contact)}>📞</span>
                  <span className="icon" onClick={handleTwilioModel}>💬</span>
                </div>
              )}
              {order.cell && (
                <div className={`phone-entry ${selectedNumber === order.cell ? 'selected' : ''}`}>
                  <span className="phone-label">Cell:</span>
                  <span className="phone-number">{order.cell}</span>
                  <span className="icon" onClick={() => handleMakeCall(order.cell)}>📞</span>
                  <span className="icon" onClick={handleTwilioModel}>💬</span>
                </div>
              )}
              {order.workNumber && (
                <div className={`phone-entry ${selectedNumber === order.workNumber ? 'selected' : ''}`}>
                  <span className="phone-label">Work:</span>
                  <span className="phone-number">{order.workNumber}</span>
                  <span className="icon" onClick={() => handleMakeCall(order.workNumber)}>📞</span>
                  <span className="icon" onClick={handleTwilioModel}>💬</span>
                </div>
              )}
            </div>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              gap={"15px"}
              marginLeft={23}
            >
              <Button
                onClick={handleNumberSeleteClose}
                variant="outlined"
                color="inherit"
                sx={{ backgroundColor: "white" }}
              >
                close
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={chatModel} onClose={handleCloseChatModel} disableEnforceFocus disableRestoreFocus minWidth={700} style={{ padding: "10px" }}>
          <DialogTitle>SMS Chat</DialogTitle>
          <DialogContent style={{ height: '500px', overflowY: 'auto', overflowX: 'hidden' }}>
            <List>
              {chatList.map((msg, index) => (

                <ListItem key={index} style={{ justifyContent: msg.type === 'incoming' ? 'flex-start' : 'flex-end', width: '100%' }}>
                  {msg.type === 'incoming' && (
                    <Avatar style={{ marginRight: '8px' }}>C</Avatar>
                  )}
                  <ListItemText
                    primary={msg.body}
                    secondary={new Date(msg.created_at).toLocaleString()}
                    style={{
                      backgroundColor: msg.type === 'incoming' ? '#e0f7fa' : '#b2dfdb',
                      borderRadius: '10px',
                      padding: '10px',
                      maxWidth: '60%',
                      wordBreak: 'break-word'
                    }}
                  />
                  {msg.type === 'outgoing' && (
                    <Avatar style={{ marginLeft: '8px' }}>A</Avatar>
                  )}
                </ListItem>
              ))}
              <div ref={endOfMessagesRef} />
            </List>
          </DialogContent>
          <Divider />
          <div style={{ padding: "20px" }}>
            <TextField
              fullWidth
              variant="outlined"
              label="New Message"
              name="newMessage"
              autoFocus
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSendTwilioSmsList();
                }
              }}
            />
            <Button variant="contained" onClick={handleSendTwilioSmsList} disabled={newMessage === ""} color="primary" style={{ width: '100%' }}>
              Send
            </Button>
          </div>
        </Dialog>

        <div style={{ display: "none" }}>
          <div
            style={{ margin: "10px", padding: "16px", maxWidth: "3.75in" }}
            ref={componentRef}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p
                  style={{
                    margin: "0",
                    fontSize: "11px",
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  Date: {moment(order.createdAt).format("MM/DD/YYYY")}
                </p>
                <p
                  style={{
                    margin: "0",
                    fontSize: "11px",
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  Time: {moment(order.createdAt).format("hh:mm:ss a")}
                </p>
              </div>
              {/* <img src={pdfLogo} alt="logo" style={{width: '100px', height: '100px'}} /> */}
              <img src={pdfLogo} style={{ width: "120px" }} alt="logo" />
              <div>
                <p
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  Order #: {order.daily_order_id}
                </p>
              </div>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>

                <h3 style={{ margin: '0', fontSize: '14px', fontWeight: 700, padding: '5px 0', fontFamily: "Saira, sans-serif" }}>Order ID: <span style={{ textDecoration: 'underline' }}>{order.slug}</span></h3>
                <h3 style={{ margin: '0', fontSize: '14px', fontWeight: 700, padding: '5px 0', fontFamily: "Saira, sans-serif", maxWidth: '2in' }}>CLIENT: <span style={{ textDecoration: 'underline' }}>{order.name}</span></h3>
                <h5 style={{ margin: '0', fontSize: '14px', fontWeight: 300, padding: '5px 0', fontFamily: "Saira, sans-serif" }}>Rx Count: {order.orderdetail_length}</h5>
                <h5 style={{ margin: '0', fontSize: '14px', fontWeight: 700, padding: '5px 0', fontFamily: "Saira, sans-serif" }}>Delivery Preference:</h5>
                <h5 style={{ margin: '0', fontSize: '14px', fontWeight: 300, padding: '5px 0', textTransform: "uppercase", fontFamily: "Saira, sans-serif" }}>{order.parcelPlace}</h5>
                <h4 style={{ margin: '0', fontSize: '14px', fontWeight: 700, padding: '5px 0', fontFamily: "Saira, sans-serif" }}>Delivery Date: {moment(order.deliverAtSelected).format('MM-DD-YYYY')}</h4>
                <h4 style={{ margin: '0', fontSize: '14px', fontWeight: 700, padding: '5px 0', fontFamily: "Saira, sans-serif" }}>Time Window: {order.deliverydate}</h4>
                {order.notes !== "" && order.notes !== null && <p style={{ margin: '0', fontSize: '13px', fontFamily: "Saira, sans-serif" }}><span style={{ fontWeight: 600 }}>Delivery Note:</span></p>}
                {order.notes !== "" && order.notes !== null && <p style={{ margin: '0', padding: '5px 0', fontSize: '13px', fontFamily: "Saira, sans-serif", width: '2.1in' }}>{order.notes}</p>}

              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "5px 0",
                }}
              >
                {order.totalcopay !== 0 && (
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "18px",
                      border: "2px solid black",
                      padding: "0 2px",
                      textAlign: "center",
                      marginBottom: "10px",
                      width: "90px",
                    }}
                  >
                    COPAY: ${" "}
                    {order.totalcopay === 0
                      ? "Nil"
                      : order.totalcopay.toFixed(2)}
                  </p>
                )}
                {order && Object.keys(order).length !== 0 ? (
                  <QRCode
                    value={order.id.toString()}
                    renderAs="svg"
                    size={80}
                  />
                ) : (
                  <QRCode renderAs="svg" size={80} value="no Order" />
                )}
                <h5
                  style={{
                    margin: "10px 0",
                    fontSize: "22px",
                    fontWeight: 800,
                    textTransform: "uppercase",
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  {order.city_code ? (
                    <>
                      {order.city_code}
                      {order.zone_code ? ` - ${order.zone_code}` : ""}
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {(order.hippaform || order.lineform || order.nfaqbform) && (
                <>
                  <h4
                    style={{
                      margin: "0",
                      fontSize: "14px",
                      fontWeight: 700,
                      padding: "10px 0",
                      fontFamily: "Saira, sans-serif",
                    }}
                  >
                    Get Forms Signed:
                  </h4>
                  <h5
                    style={{
                      margin: "0",
                      fontSize: "12px",
                      fontWeight: 700,
                      fontFamily: "Saira, sans-serif",
                    }}
                  >
                    {order.hippaform && <span>Hipaa Form</span>}
                    {order.lineform && (
                      <span
                        style={{
                          marginLeft: "12px",
                          fontFamily: "Saira, sans-serif",
                        }}
                      >
                        Lien Form
                      </span>
                    )}
                    {order.nfaqbform && (
                      <span
                        style={{
                          marginLeft: "12px",
                          fontFamily: "Saira, sans-serif",
                        }}
                      >
                        NF AOB Form
                      </span>
                    )}
                  </h5>
                </>
              )}
              <h4
                style={{
                  margin: "0",
                  fontSize: "16px",
                  fontWeight: "700",
                  textAlign: "center",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                {order.refrigrated && order.photoid
                  ? "Refrigerated / Photo ID"
                  : order.photoid
                    ? "Photo ID"
                    : order.refrigrated
                      ? "Refrigerated"
                      : " "}
                {order.refrigrated && order.photoid ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      style={{ marginRight: "5px" }}
                      src={registered}
                      alt="logo"
                    />{" "}
                    <img
                      src={photoId}
                      style={{ marginLeft: "10px" }}
                      alt="logo"
                    />
                  </div>
                ) : order.photoid ? (
                  <img style={{ margin: "auto" }} src={photoId} alt="logo" />
                ) : order.refrigrated ? (
                  <img style={{ margin: "auto" }} src={registered} alt="logo" />
                ) : (
                  " "
                )}
              </h4>
              <h5
                style={{
                  margin: "0",
                  marginTop: "10px",
                  fontSize: "13px",
                  fontWeight: 700,
                  textAlign: "center",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                From
              </h5>
              <h4
                style={{
                  margin: "0",
                  fontSize: "13px",
                  fontWeight: 700,
                  textAlign: "center",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                {order.pharmacy.name}
              </h4>
              <h4
                style={{
                  margin: "0",
                  fontSize: "13px",
                  fontWeight: 300,
                  textAlign: "center",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                {order.pharmacy.location}
              </h4>
              <h4
                style={{
                  margin: "0",
                  fontSize: "13px",
                  fontWeight: 300,
                  textAlign: "center",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Phone {order.pharmacy.contact_no}
              </h4>
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          {orderDetail && (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                paddingTop: "10px",
              }}
              ref={componentRef2}
            >
              <PrintDocList orderDetail={orderDetail} pharmacy={pharmacy} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default OrderObject;
