import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router';
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Paper,
  InputBase,
  IconButton,
  Divider,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
} from "@material-ui/core";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForwardIcon from "@mui/icons-material/Forward";
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";
import { ArrowDropDownCircle, CheckBox } from "@mui/icons-material";
import { toast } from "react-toastify";
import moment from 'moment';
import { AttachFile, CloseRounded, Send } from "@mui/icons-material";
import TicketCard from "./TicketCard/TicketCard";
import { closeIconRed } from "../../../assests";
import { BiCheckDouble } from "react-icons/bi";
import "./TicketList.css";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { getApiWithAuth, postApiWithAuth, patchApiWithAuth, putApiWithAuth } from "../../../utils/api";
import { CREATE_TICKET, CREATE_COMMENT, ALL_PHARMACY_LIST} from "../../../utils/apiUrls";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  chatContainer: {
    flex: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  message: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  inputContainer: {
    background: "#E2E2E2",
    display: "flex",
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
}));

const TicketList = () => {
  const classes = useStyles();
  const { pid, oid } = useParams();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [ticketOrders, setTicketOrders] = useState([]);
  const [ticketId, setTicketId] = useState("");
  const [ticketDetail, setTicketDetail] = useState();
  const [search, setSearch] = useState("");
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [pharmacy, setPharmacy] = useState("");
  const [timer, setTimer] = useState("");
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formState, setFormState] = useState({
    ticket: ticketId,
    comment: "",
  });
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      key: "selection",
    },
]);
  
  const calendarRef = useRef(null);

  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST, {'justList': true});
      if (response.data !== undefined) {
        setUniquePharmacy(response.data.data.map((item) => ({id: item.id, name: item.name})));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSaveTicket = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    console.log(formState);
    const formDataComment = new FormData();
    formDataComment.append("comment", formState.comment);
    formDataComment.append("ticket", ticketId);
    
    try {
      const responseComment = await postApiWithAuth(CREATE_COMMENT, formDataComment);
      
      if (responseComment.data !== undefined) {
        getTicketOrders();
        getTicketDetail(ticketId);
      } else {
        console.log("Error");
      }

      setFormState({
        ticket: ticketId,
        comment: ""
      });
    } catch (error) {
      console.error("Error while saving ticket", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSaveTicket();
    }
  };
  const handleTicketClick = (ticketId) => {
    setSelectedTicketId(ticketId);
    // getTicketDetail(ticketId); // Assuming you want to call this here
  };
  const handleSelect = (ranges) => {
    console.log("ranges inside the handleselect",ranges)
    if (ranges.selection.endDate) {
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };
  const getTicketOrders = async () => {
    setLoading(true);
      let apiUrl = `${CREATE_TICKET}?data=all&pharmacy=${pharmacy}&search=${search}&from=${moment(
        dateRange[0].startDate
      ).format("MM-DD-YYYY")}&to=${moment(dateRange[0].endDate).format(
        "MM-DD-YYYY"
      )}`;
      if (timer === "Pending") {
        apiUrl += "&pending=true";
      } else if (timer === "Completed") {
        apiUrl += "&completed=true";
      }
      const response = await getApiWithAuth(apiUrl);
      if (response.data && response.data.data && response.data.data.length > 0) {
          console.log(response.data.data);
          setTicketOrders(response.data.data);
          setLoading(false);
          if (ticketId === "" && response.data.data[0].id) {
              setSelectedTicketId(response.data.data[0].id);
              setTicketId(response.data.data[0].id);
              getTicketDetail(response.data.data[0].id);
          }
      } else {
      setLoading(false);
    }
  };
  
  const getTicketDetail = async (id) => {
    setLoading(true);
    const response = await getApiWithAuth(CREATE_TICKET+`${id}/`);
    if (response.data !== undefined) {
      console.log(response.data)
      setTicketId(id)
      setTicketDetail(response.data.data)
      setLoading(false)
    } else {
      setTicketId(id)
      setLoading(false);
    }
  };

  const userType = localStorage.getItem("userType");

  const HandleReopenTicket = async (id) => {
    setLoading(true);
    const response = await patchApiWithAuth(CREATE_TICKET+`${id}/`, {"ticket_status": "Pending"});
    console.log(response)
    if (response.data !== undefined) {
      setTicketId(id)
      setLoading(false)
      getTicketOrders();
      setTicketDetail(ticketId);
      toast.success("Ticket Re-Open Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setTicketId(id)
      setLoading(false);
      getTicketOrders();
      setTicketDetail(ticketId);
    }
  };

  useEffect(() => {
    getTicketOrders();
  }, [pharmacy,search, timer,dateRange]);
  useEffect(() => {
    fetchPharmacies();
  }, []);

  useEffect(() => {
    const pharmacyId = localStorage.getItem('pharmacyId')
    if (pid !== undefined) {
      if (parseInt(pid) !== parseInt(pharmacyId)) {
        navigate('/404')
      }
    }
  }, [pid])

  const handleCloseTicket = async (ticketId) => {
    // Send a PUT request to update the ticket status to 'Completed'
    const response = await patchApiWithAuth(CREATE_TICKET + `${ticketId}/`, {
      ticket_status: "Completed",
    });
    if (response.data !== undefined) {
      getTicketOrders();
      setTicketDetail(ticketId);
      toast.success("Ticket Closed Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      console.log("Error");
      toast.error("Some Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getTicketOrders();
      setTicketDetail(ticketId);
    }
  };
  const [filterName, setFilterName] = React.useState([]);
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      setSearch(inputValue);
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterName(
      typeof value === 'string' ? value.split(',') : value,
    );

    const pharmacyIds = uniquePharmacy
      .filter(pharmacy => value.includes(pharmacy.name))
      .map(pharmacy => pharmacy.id);
    setPharmacy(pharmacyIds);
  };

  return (
    <div className="OrderList">
    {/* {loading && <CustomLoader />} */}
    <div className="second-row">
        <div className="filter-container">
          <div className="search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              // onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleSearchKeyDown}
              placeholder="Order ID, Name, Address, Phone, Rx"
              className="search-input"
              autoComplete="off"
            />
          </div>
          <div className="search-filter-container">
            <Select
              defaultValue="0"
              className="filter-input no-underline-select"
              style={{
                width: "300px",
                padding: "0", 
                backgroundColor: "primary.lighter", // Adjust the background color as needed
                // border: "0px solid",
                // borderColor: "transparent",
              }}
              onChange={(e) => setTimer(e.target.value)}
              IconComponent={() => <ArrowDropDownCircle sx={{ color: 'black', width: 20 }} />}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null, // Remove default behavior of trying to find the anchor element
            style: {
              marginTop: '10px', // Adjust the margin-top as needed
            },
          }}
        >
          <MenuItem className="dropdown-text" value="0" disabled>Select</MenuItem>
          <MenuItem className="dropdown-text" value="Pending">Pending</MenuItem>
          <MenuItem className="dropdown-text" value="Completed">Completed</MenuItem>
        </Select>
          </div>
          {userType === "Admin" && (
          <div className="search-filter-container">
            <FormControl className="filter-input">
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                className="no-underline-select"
                IconComponent={(props) => <ArrowDropDownCircle {...props} sx={{ color: 'black', width: 20 }} />}
                multiple
                displayEmpty
                sx={{
                  fontSize: '11px',
                  "& .MuiSvgIcon-root": {
                    color: 'black'
                  }
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null, // Remove default behavior of trying to find the anchor element
                  style: {
                    marginTop: '10px', // Adjust the margin-top as needed
                  },
                }}
                value={filterName}
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <p style={{ fontSize: '14px', opacity: 1,fontFamily: "Saira Extra Condensed" }}>Filter Pharmacy</p>;
                  }
                  return <p style={{ fontSize: '14px', opacity: 1,fontFamily: "Saira Extra Condensed" }}>{selected.length > 1 ? `${selected.slice(0, 1)}  ...` : selected}</p>
                }}
              >
                {uniquePharmacy?.map((pharmacy, index) => (
                  <MenuItem
                    key={index}
                    value={pharmacy.name}
                    sx={{
                      fontSize: '13px',
                    }}
                  >
                    <Checkbox size="small" checked={filterName.indexOf(pharmacy.name) > -1} />
                    {pharmacy.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          )}
          <div className="date-input-container">
          <div type="text" className="date-input">
            <CalendarMonthIcon
              style={{ fontSize: "28px", cursor: "pointer" }}
              onClick={toggleCalendar}
            />
            {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
            <ForwardIcon style={{ fontSize: "32px" }} />
            {dateRange[0].endDate
              ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
              : ""}
          </div>
          {isCalendarVisible && (
            <div className="calendar-container">
              <DateRange
                ranges={dateRange}
                onChange={handleSelect}
                retainEndDateOnFirstSelection={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="customer_support max-h-screen grid grid-cols-12">
      <div className="ticket col-span-4 max-h-screen min-h-[80vh] height-auto overflow-auto">
        <div className="flex flex-col gap-sm">
          {ticketOrders.length > 0 && ticketOrders.map((ticket, i) => (
            <a   className={`ticket-link ${selectedTicketId === ticket.id ? 'ticket-selected' : ''}`}
             onClick={() => {
              handleTicketClick(ticket.id);
              getTicketDetail(ticket.id);
            }} >
              <TicketCard ticket={ticket} />
            </a>
          ))}
        </div>
      </div>

      <div className={`classes.root col-span-8`}>
        <div className="flex flex-col gap-md items-end w-full p-md">
          <div>
            <div
              className="flex items-center gap-sm"
            >
              {ticketDetail !== undefined && ticketDetail.ticket_status === "Completed" ? (
                <p className="text-[16px] font-[700] text-center" style={{color: 'green'}}>
                  Ticket Closed
                </p>
              ) : (
                <>
                  <button onClick={() => handleCloseTicket(ticketId)}  style={{color: 'gold'}} className="text-[16px] font-[700] text-center">
                    Issue Resolved <br /> Close Ticket
                  </button>
                  <CloseRounded className="cursor-pointer" />
                </>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col justify-between max-h-screen min-h-[80vh] height-auto">
            <Paper elevation={0} className={classes.chatContainer}>
              {ticketDetail !== undefined && ticketDetail.comments !== undefined && ticketDetail.comments.length > 0 && ticketDetail.comments.map((message, index) => (
                <div
                  key={index}
                  className={
                    message.sender === "user"
                      ? `classes.message flex gap-sm mb-sm`
                      : `classes.message flex gap-sm mb-sm flex-row-reverse`
                  }
                >
                  <Avatar className={classes.avatar}>
                    {message.sender === "user" ? "U" : "R"}
                  </Avatar>

                  <div className={"flex flex-col w-full"}>
                    <div
                      className={
                        message.sender === "user"
                          ? "flex items-center justify-between"
                          : "flex items-center justify-between flex-row-reverse"
                      }
                    >
                      <p
                        className={
                          message.sender === "user"
                            ? "text-blue text-[20px] font-[700] text"
                            : "text-text text-[20px] font-[700] text-end"
                        }
                      >
                        {message.comment_by.first_name} {message.comment_by.last_name}
                      </p>
                      <p
                        className={
                          message.sender === "user"
                            ? "flex gap-sm text-blue text"
                            : "flex gap-sm flex-row-reverse text-text text-end"
                        }
                      >
                        {/* Read <BiCheckDouble color="#2757FF" /> */}
                        {message.created_at.split("T")[0]} {message.created_at.split("T")[1].split(".")[0]}

                         {/* {moment(message.created_at).format('MM-DD-YYYY')} | {moment(message.created_at).format('hh:mm:ss a')} */}
                      </p>
                    </div>
                    {/* <div
    className={`${
        message.sender === "user"
            ? "bg-blue text-white"
            : (message.sender === "receiver" && (message.comment_by.type.type === "Admin" || message.comment_by.type.type === "Staff"))
                ? "bg-blue bg-opacity-80 text-white"
                : "bg-gray text-text"
    } p-sm rounded-sm h-[50px] flex items-center`}
>
    {message.comment}
</div> */}
                <div
  className={`${
    (message.comment_by.type.type === "PharmacyOwner" || message.comment_by.type.type === "PharmacyEmployee")
      ? "bg-gray text-text "
      : (message.comment_by.type.type === "Admin" || message.comment_by.type.type === "Staff")
        ? "bg-blue text-white"
        : ""
  } p-sm rounded-sm h-[50px] flex items-center`}
>
  {message.comment}
</div>


                  </div>
                </div>
              ))}
            </Paper>

            <div className="relative rounded-md mx-auto w-full max-w-[800px] flex justify-center bg-[#E2E2E2]">

              {ticketDetail !== undefined && ticketDetail.ticket_status !== "Completed" ?
              <Paper
                elevation={0}
                className={`classes.inputContainer background bg-[#E2E2E2] items-start flex justify-between p-sm w-full`}
              >
                <InputBase
                  placeholder="Type a message..."
                  name="comment"
                  value={formState.comment}
                  autoComplete="off"
                  onChange={handleInputChange}
                  className={"classes.input bg-[#E2E2E2] w-[80%]"}
                  onKeyPress={handleKeyPress}
                />

                <div className="bg-[#E2E2E2]">
                  <IconButton color="primary" onClick={handleSaveTicket} disabled={isSubmitting}>
                    <Send />
                  </IconButton>
                </div>
              </Paper>
              :
              userType === "PharmacyOwner" ?
              <button onClick={() => HandleReopenTicket(ticketId)}  style={{color: 'red'}} className="text-[16px] font-[700] text-center">
                Re-Open Ticket
              </button>
              :
              userType === "PharmacyEmployee" &&
              <button onClick={() => HandleReopenTicket(ticketId)}  style={{color: 'red'}} className="text-[16px] font-[700] text-center">
                Re-Open Ticket
              </button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TicketList;
