import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputMask from 'react-input-mask';
// import Autocomplete from "@mui/material/Autocomplete";
import Autocomplete from 'react-google-autocomplete';
import TextField from "@mui/material/TextField";
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode.react';
import Printdoc from '../../CommonComponents/PrintDoc/PrintDoc';
import PrintDocDown from '../../CommonComponents/PrintDoc/PrintDocDown';
import jsPDF from 'jspdf';
import { PDFDownloadLink, PDFViewer, Document, Page } from '@react-pdf/renderer';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';

import axios from "axios";
import moment from "moment";

import { 
    CREATE_ORDER,
    ORDER_FILE_UPLOAD,
    ALL_PHARMACY_LIST
 } from "../../../utils/apiUrls";
import {
  postApiWithAuth,
  getApiWithAuth
} from "../../../utils/api";

import {
  landline,
  mobile,
  iphone,
  buildings,
  route_report,
  locationImg,
  upload,
  pdfLogo,
  registered,
  photoId,
  pharmacyCallBlack
} from '../../../assests';

import "./addOrder.css";
import { Checkbox } from "@mui/material";
import PrintableComponent from "../../CommonComponents/Printable/PrintableComponent";
import ShowPDF from "../../CommonComponents/ShowPDF/ShowPDF";

function AddOrder() {

  const createDate = new Date();

  const [orderDetail, setOrderDetail] = useState({
    createdAt: new Date(),
    deliveryDate: new Date(createDate.getTime() + 24 * 60 * 60 * 1000),
    timeWindow: 'Anytime',
    name: '',
    language: 'english',
    address: '',
    apart: '',
    phoneNumber: '',
    cellNumber: '',
    workNumber: '',
    zip: '',
    note: '',
    totalCopay: 0,
    signRequired: true,
    reqPhotoId: false,
    registed: false,
    collectCopay: false,
    hippaForm: false,
    lineForm: false,
    nfForm: false,
    bussAddress: false,
    parcelPlace: 'Face to face',
    lat: '',
    lng: '',
    city_code:'',
    rxList: [{
      quantity: '',
      rf_number: '',
      date_filled: '',
      pat_pay_amount: 0,
      rx_number: 'M - ',
      select: false,
    }]
  });
  
  const [orderDetailView, setOrderDetailView] = useState({
    createdAt: new Date(),
    deliveryDate: new Date(),
    timeWindow: 'Anytime',
    name: '',
    language: 'english',
    address: '',
    apart: '',
    phoneNumber: '',
    cellNumber: '',
    workNumber: '',
    zip: '',
    note: '',
    totalCopay: 0,
    signRequired: true,
    reqPhotoId: false,
    registed: false,
    collectCopay: false,
    hippaForm: false,
    lineForm: false,
    nfForm: false,
    bussAddress: false,
    parcelPlace: 'Face to face',
    lat: '',
    lng: '',
    rxLists: [{
      quantity: '',
      rf_number: '',
      date_filled: '',
      pat_pay_amount: 0,
      rx_number: 'M - ',
      select: false,
    }]
  });
  

  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState('');
  const [selectedOrderFile, setSelectedOrderFile] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [streetViewUrl, setStreetViewUrl] = useState('');
  const [mapError, setMapError] = useState(false);
  const [orderResponse, setOrderResponse] = useState({});
  const [pharmacyDetail, setPharmacyDetail] = useState({});
  const [activeTab, setActiveTab] = useState('Upload slip');
  const [address, setAddress] = useState('');
  const [results, setResults] = useState('');
  const [addressError, setAddressError] = useState(null);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  

  const { pid } = useParams();
  const navigation = useNavigate();

  useEffect(() => {
    const pharmacyId = localStorage.getItem('pharmacyId')
    if (pid !== undefined) {
      if (parseInt(pid) !== parseInt(pharmacyId)) {
        navigation('/404')
      }
    }
  }, [pid])

  const componentRef = useRef();
  const reportTemplateRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentRef2 = useRef();

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });

  const GOOGLE_MAPS_API_KEY = 'AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ';

  const options = {
    types: ['address'],
    componentRestrictions: { country: 'us' },
    bounds: {
      north: 42.0505,  // Northernmost point roughly in CT
      south: 38.9285,  // Southernmost point roughly in NJ
      west: -79.7624,  // Westernmost point roughly in NY
      east: -71.1851   // Easternmost point roughly in CT
    },
    strictBounds: true
  };

  const DownloadBtn = () => {
    // doc.addImage(process.env.REACT_APP_API_URL + brand.image, 'PNG', 20, 10, 20, 15)
    const doc = new jsPDF({
        format: 'a4',
        unit: 'px'
    });

    doc.html(reportTemplateRef.current, {
        async callback(doc) {
            await doc.save('orderSlip');
        },
        html2canvas: { scale: 0.65 }
    });
  };

  const getPharmacyDetail = async() => {

    const response = await getApiWithAuth(ALL_PHARMACY_LIST+`/${pid}`);

    if (response.data !== undefined) {
      setPharmacyDetail(response.data.data)
    } else {
      console.log(response.status)
    }
  };

  const handleOrderChange = (event, index) => {
    const { name, value, type, checked } = event.target;
    console.log(value,type, name)

    let updatedValue = value;

    // If the input type is checkbox, update accordingly
    if (type === 'checkbox') {
      updatedValue = checked;
    }
    if (name.includes('.')) {
      const [list, rxIndex, fieldName] = name.split('.');
      console.log(list, rxIndex, fieldName)
      const updatedRxList = [...orderDetail.rxList];
      updatedRxList[index][fieldName] = updatedValue;

      let totalCopay = 0;

      orderDetail.rxList.forEach((rx) => {
        if(rx.select){
          totalCopay += parseFloat(rx.pat_pay_amount);
        }
      });

      setOrderDetail({
        ...orderDetail,
        rxList: updatedRxList,
        totalCopay: totalCopay,
      });
    } else if (name === 'collectCopay'){
      
      const updatedRxList = orderDetail.rxList.map((rx) => ({
        ...rx,
        select: checked,
      }));

      let totalCopay = 0;

      if (checked) {
        orderDetail.rxList.forEach((rx) => {
          totalCopay += parseFloat(rx.pat_pay_amount);
        });
      }

      setOrderDetail({
        ...orderDetail,
        [name]: updatedValue,
        rxList: updatedRxList,
        totalCopay: totalCopay,
      });
    } else if ( name === "zip" ) {
      const trimmedValue = value.replace(/\s/g, '');

        // Check the length of the input without spaces, limit to 5 characters
        console.log(trimmedValue, trimmedValue.length)
      if (trimmedValue.length <= 5) {
          setOrderDetail(prevState => ({
              ...prevState,
              [name]: trimmedValue
          }));
      }
    } else {
      console.log('work')
      // It's a top-level field in orderDetail
      setOrderDetail({
        ...orderDetail,
        [name]: updatedValue,
      });
    }

  };

  const handleDeliveryDateChange = (date) => {
    console.log("1223")
    // Convert the date to the desired format (MM/DD/YYYY)
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    // Update the orderDetail state with the formatted date
    setOrderDetail({
      ...orderDetail,
      deliveryDate: formattedDate,
    });
  };

  const handleApartmentChange = (event) => {
    console.log("1224")
    const apartmentNumber = event.target.value;
    // const lastCommaIndex = orderDetail.address.lastIndexOf(',');
    // console.log(orderDetail.address)
    // const firstPart = orderDetail.address.substring(0, lastCommaIndex).trim();
    // const secondPart = orderDetail.address.substring(lastCommaIndex + 1).trim();
    // console.log(firstPart, secondPart)

    // let updatedAddress;
    // if (add1 !== '' || add2 !== '') {
    //   console.log('workkff')
    //   if (apartmentNumber === '') {
    //     updatedAddress = `${add1}, ${add2}`;
    //   } else {
    //     updatedAddress = `${add1} ${apartmentNumber}, ${add2}`;
    //   }
    // } else {
    //   updatedAddress = `${firstPart} ${apartmentNumber}, ${secondPart}`;
    //   setAdd1(firstPart);
    //   setAdd2(secondPart);
    // }
    // console.log(updatedAddress);
    // Update the apartment number in the resulting array and join it back into a string with the comma separator
    setOrderDetail({
      ...orderDetail,
      // address: updatedAddress,
      apart: apartmentNumber
    });
  };

  const handleAddRxItem = () => {
    const newRxItem = {
      quantity: '1',
      rf_number: '0',
      date_filled: '',
      pat_pay_amount: '',
      rx_number: 'M - ',
      select: false,
    };

    setOrderDetail({
      ...orderDetail,
      rxList: [...orderDetail.rxList, newRxItem],
    });
  };

  const handleDeleteRxItem = (index) => {
    const updatedRxList = [...orderDetail.rxList];
    updatedRxList.splice(index, 1);

    setOrderDetail({
      ...orderDetail,
      rxList: updatedRxList,
    });
  };


  const handlePlaceSelect = (place) => {
    if (parseInt(pharmacyDetail.id) === 2){
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        address: place.formatted_address
      }));
      setAddress(place.formatted_address)
    } else {
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        address: place.title
      }));
      setAddress(place.title)
    }

    setResults([]);
  };

  useEffect(() => {
    if (orderDetail.address, orderDetail.lat, orderDetail.lng) {
      const streetViewUrlApi = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${orderDetail.lat},${orderDetail.lng}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
      setStreetViewUrl(streetViewUrlApi);
    }
  }, [orderDetail.address, orderDetail.lat, orderDetail.lng]);

  useEffect(() => {
    if (orderDetail.address !== '') {
      const fetchLocation = async () => {
        try {
          if (parseInt(pharmacyDetail.id) === 2) {
            const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(orderDetail.address)}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
            const response = await axios.get(geocodingUrl);

            const {results} = response.data

            if (results.length > 0) {
              console.log(results)
              const { lat, lng } = results[0].geometry.location;
              const formattedLat = parseFloat(lat.toFixed(5));
              const formattedLng = parseFloat(lng.toFixed(5));
              const type = results[0].geometry.location_type;
              const postalComponent = results[0]?.address_components.find(component => component.types.includes('postal_code'));

              if (lat.toString().split('.')[1]?.length < 4 || lng.toString().split('.')[1]?.length < 4) {
                setAddressError("Latitude or longitude has less than 4 decimal places");
              } else if (type === "APPROXIMATE") {
                setAddressError("Address is invalid. Please select a valid address");
              } else {
                setAddressError("");
                setAddress(results[0]?.formatted_address);
                setOrderDetail((prevOrderDetail) => ({
                  ...prevOrderDetail,
                  lat: formattedLat,
                  lng: formattedLng,
                  zip: postalComponent?.long_name
                }));
                setMapError(false);
              }
              
              // we will use this if needed for testing remove it!
              // else if (results[0].partial_match) {
              //   setAddressError("Address match is partial and might be inaccurate. Please confirm the address.");
              // } 
            } else {
              setMapError(true);
              console.log("QWewq come here")
              setOrderDetail((prevOrderDetail) => ({
                ...prevOrderDetail,
                lat: "",
                lng: "",
                zip: ""
              }));
            }
          } else {
            const hereURL = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(orderDetail.address)}&apiKey=6CgVwXbgNzlj3uCnA38M0Du16b2o_0DKBOLGYyc1SH8`
            const responseHere = await axios.get(hereURL);
            const results = responseHere.data.items;
            if (results.length > 0) {
              console.log(results);
              // Find the first item where resultType is 'houseNumber'
              const houseNumberResult = results.find(result => result.resultType === 'houseNumber');
              
              if (houseNumberResult) {
                  const { lat, lng } = houseNumberResult.position;
                  const postalCode = houseNumberResult.address.postalCode.substring(0, 5); // Get first 5 characters
                  const title = houseNumberResult.title;
          
                  setAddressError("");
                  console.log(lat, lng, "=======lat,lng");
                  setAddress(title);
                  setOrderDetail((prevOrderDetail) => ({
                      ...prevOrderDetail,
                      lat: lat,
                      lng: lng,
                      zip: postalCode,
                      address: title
                  }));
                  setMapError(false);
              } else {
                  // Handle the case where no 'houseNumber' result is found
                  setAddressError("Address is invalid. Please select a valid address");
                  setMapError(true);
                  setOrderDetail((prevOrderDetail) => ({
                      ...prevOrderDetail,
                      lat: "",
                      lng: "",
                      zip: "",
                      title: ""
                  }));
              }
            } else {
                setMapError(true);
                console.log("No results found");
                setOrderDetail((prevOrderDetail) => ({
                    ...prevOrderDetail,
                    lat: "",
                    lng: "",
                    zip: ""
                }));
            }
          }
        
        } catch (error) {
          console.log("Google map give error yet please try again later!")
        }
      };

      fetchLocation();
    }
  }, [orderDetail.address]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (orderDetail.rxList.length === 0) {
      toast.error('Order details are empty. Cannot process the order.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
  
    if (addressError === "" && orderDetail.zip !== "") {
      console.log('Order Details:', orderDetail);
      setLoading(true);
  
      const formData = new FormData();
  
      // Add orderDetail data to formData order_file_name
      formData.append('deliverydate', orderDetail.timeWindow);
      formData.append('order_file_name', fileName); 
      formData.append('language', orderDetail.language);
      formData.append('apart', orderDetail.apart);
      formData.append('deliveryTime', moment(orderDetail.deliveryDate).format('MM/DD/YYYY'));
      formData.append('name', orderDetail.name);
      formData.append('address', orderDetail.address);
      formData.append('contact', orderDetail.phoneNumber);
      formData.append('cell', orderDetail.cellNumber);
      formData.append('workNumber', orderDetail.workNumber);
      formData.append('zip', orderDetail.zip);
      formData.append('notes', orderDetail.note);
      formData.append('status', "1");
      formData.append('location_image', streetViewUrl);
      formData.append('signature', orderDetail.signRequired);
      formData.append('photoid', orderDetail.reqPhotoId);
      formData.append('refrigrated', orderDetail.registed);
      formData.append('collectcopay', orderDetail.collectCopay);
      formData.append('hippaform', orderDetail.hippaForm);
      formData.append('lineform', orderDetail.lineForm);
      formData.append('nfaqbform', orderDetail.nfForm);
      formData.append('totalcopay', orderDetail.totalCopay);
      formData.append('addresstype', orderDetail.bussAddress);
      formData.append('parcelPlace', orderDetail.parcelPlace);
      formData.append('lat', orderDetail.lat);
      formData.append('lng', orderDetail.lng);
      formData.append('daily_order_id', parseInt(pharmacyDetail.todayOrderCount) + 1);
  
      // Add selectedOrderFile to formData
      console.log("selectedOrderFile 510",selectedOrderFile);
      formData.append('order_file', selectedOrderFile);
  
      formData.append('products', JSON.stringify(orderDetail.rxList));
      console.log("formdate beforsubmission",formData);
      const responsePersonal = await postApiWithAuth(CREATE_ORDER, formData);
      console.log(responsePersonal.data.data);
      console.log(responsePersonal.data.error);
      if (responsePersonal.data.data !== undefined) {
        toast.success('Order Created Successfully!', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setOrderResponse(responsePersonal.data.data);
      } else if (responsePersonal.data.error) {
        console.log("Order File Format Doesn't Match Our Criteria.");
        toast.error('Order File Format Does Not Match Our Criteria', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (orderResponse && Object.keys(orderResponse).length > 0) {
      handlePrint();
      navigation(`/pharmacy/${pid}/list/order`);
    }
  }, [orderResponse]);
  

  const HandleFetchFileDetail = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile || selectedFile.type !== 'application/pdf') {
      console.log('Please upload a valid file');
    } else {
      try {
        console.log("selectedFile 563", selectedFile);
        setSelectedOrderFile(selectedFile);

        const reader = new FileReader();
        reader.onload = () => {
          const content = reader.result;
          setPdfFile(content);
        };
        reader.readAsDataURL(selectedFile);

        const formData = new FormData();
        formData.append('file_uploaded', selectedFile);
        const response = await postApiWithAuth(ORDER_FILE_UPLOAD, formData);
        console.log(response);

        if (response.data !== undefined) {
          const clientData = response.data.data.client || {};
          setOrderDetail({
            name: clientData.name || '',
            address: clientData.address || '',
            phoneNumber: clientData.phone_number || '',
            cellNumber: clientData.cell_number || '',
            workNumber: clientData.work_number || '',
            zip: clientData.zip || '',
            timeWindow: 'Anytime',
            parcelPlace: 'Face to face',
            signRequired: true,
            reqPhotoId: false,
            registed: false,
            collectCopay: false,
            hippaForm: false,
            lineForm: false,
            nfForm: false,
            bussAddress: false,
            deliveryDate: new Date(createDate.getTime() + 24 * 60 * 60 * 1000),
            totalCopay: 0,
            language: 'english',
            apart: '',
            note: '',
            lat: '',
            lng: '',
            rxList: response.data.data.text || [] // Store 'text' data in rxList
          });
          setOrderDetailView({
            name: clientData.name || '',
            address: clientData.address || '',
            phoneNumber: clientData.phone_number || '',
            cellNumber: clientData.cell_number || '',
            workNumber: clientData.work_number || '',
            zip: clientData.zip || '',
            timeWindow: 'Anytime',
            parcelPlace: 'Face to face',
            signRequired: true,
            reqPhotoId: false,
            registed: false,
            collectCopay: false,
            hippaForm: false,
            lineForm: false,
            nfForm: false,
            bussAddress: false,
            deliveryDate: new Date(createDate.getTime() + 24 * 60 * 60 * 1000),
            totalCopay: 0,
            language: 'english',
            apart: '',
            note: '',
            lat: '',
            lng: '',
            rxLists: response.data.data.text || [] // Store 'text' data in rxList
          });
          setAddress(clientData.address || '');
        }
      } catch (error) {
        console.error('Error fetching file details:', error);
      }
    }
  };
  useEffect(() => {
    if (selectedOrderFile) {
      console.log("selectedOrderFile", selectedOrderFile);
    }
  }, [selectedOrderFile]);
  useEffect(() => {
    getPharmacyDetail();
  }, [])

  useEffect(() => {
    const storedOrderDetail = localStorage.getItem('orderDetail');
    const storedOrderDetail2 = localStorage.getItem('orderDetails');
    const fileName = localStorage.getItem('myFile');
    const file = localStorage.getItem('File');
    setPdfFile(file);
    console.log(fileName)
    if (fileName) {
      setFileName(fileName);
      localStorage.removeItem('myFile');
      localStorage.removeItem('File');
    }

    // Check if data exists in local storage
    if (storedOrderDetail) {
      const orderDetailData = JSON.parse(storedOrderDetail);
      
      // Set the state with the retrieved data
      setOrderDetail(orderDetailData);
      // Remove the data from local storage if needed
      localStorage.removeItem('orderDetail');
    }

    if (storedOrderDetail2) {
      const orderDetailData = JSON.parse(storedOrderDetail2);
      
      setOrderDetailView(orderDetailData);
      setAddress(orderDetailData.address)
      // Remove the data from local storage if needed
      localStorage.removeItem('orderDetails');
    }

  }, []);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const currentHour = new Date().getHours();
    if (orderDetail.timeWindow === 'Anytime') {
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        deliveryDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      }));
    } else if (currentHour >= 17) {
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        deliveryDate: new Date(currentTime + 24 * 60 * 60 * 1000)
      }));
    } else {
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        deliveryDate: new Date(new Date().getTime())
      }));
    }
  }, [orderDetail.timeWindow]);

  const handleSearch = async (event) => {
    const { value } = event.target;
    setAddress(value);
    if (value.length > 2) { // To limit requests, only search if query length > 2
        const apikey = "6CgVwXbgNzlj3uCnA38M0Du16b2o_0DKBOLGYyc1SH8";
        const endpoint = `https://autosuggest.search.hereapi.com/v1/autosuggest?at=40.8301933,-73.8521865&in=countryCode:USA&lang=en-US&limit=5&q=${value}&apikey=${apikey}`;

        try {
            const response = await axios.get(endpoint);
            setResults(response.data.items || []);
        } catch (error) {
            console.error('Error fetching data: ', error);
            setResults([]);
        }
    } else {
        setResults([]);
    }
  };


  return (
    <div className="addOrderMain">
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div className="addOrder-top">
              <div className="topFields">
                <p className="topFieldLabel">Created Date</p>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-top-field"
                  name="name"
                  placeholder="Created Date"
                  value={moment(createDate).format('MM-DD-YYYY')}
                  readOnly110px
                />
              </div>
              <div className="topFields">
                <p className="topFieldLabel">Delivery Date</p>
                {/* <input
                  autoComplete="off"
                  type="date"
                  className="order-top-field"
                  name="deliveryDate"
                  placeholder="Delivery Date"
                  value={orderDetail.deliveryDate}
                  onChange={handleOrderChange}
                /> */}
                <DatePicker
                  className="order-top-field"
                  selected={new Date(orderDetail.deliveryDate)}
                  onChange={handleDeliveryDateChange}
                  dateFormat="MM/dd/yyyy"
                />
              </div>
              <div className="topFields">
                <p className="topFieldLabel">Time Window</p>
                <select name="timeWindow" className="order-top-field" id="timeWindow" value={orderDetail.timeWindow} onChange={handleOrderChange}>
                  <option value="Anytime">Anytime</option>
                  <option value="9am-3pm">9am-3pm</option>
                  <option value="2pm-6pm">2pm-6pm</option>
                  <option value="5pm-9pm">5pm-9pm</option>
                  <option value="Urgent">Urgent</option>
                </select>
              </div>
              {/* <div className="topFields">
                <select className="order-top-field" name="language" id="language" value={orderDetail.language} onChange={handleOrderChange}>
                  <option value="">
                    <em>Select Language</em>
                  </option>
                  <option value="english">English</option>
                  <option value="french">French</option>
                  <option value="spanish">Spanish</option>
                </select>
              </div> */}
            </div>
            <div>
              {/* <input
                autoComplete="off" accept="application/pdf" id="pdf-upload" style={{ display: 'none' }} type="file" onChange={(e) => HandleFetchFileDetail(e)} />
                <label htmlFor="pdf-upload">
                  <Button className='upload-btn' component="span" startIcon={<img className="upload-icon" src={upload} alt="Logo" />} sx={{ textTransform: 'none' }}>
                    Upload
                  </Button>
                </label> */}
              <input
                autoComplete="off"
                accept="application/pdf"
                id="pdf-upload"
                style={{ display: "none" }}
                type="file"
                onChange={(e) => HandleFetchFileDetail(e)}
              />
              <label htmlFor="pdf-upload">
                {/* <button >
                  <img className="upload-icon" src={upload} alt="Logo" />
                  Click to Upload
                </button> */}
                <Button
                  className="upload-btn"
                  component="span"
                  startIcon={
                    <img className="upload-icon" src={upload} alt="Logo" />
                  }
                  sx={{ textTransform: "none" }}
                >
                  Upload
                  
                </Button>
              </label>

            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="orderBasicDiv">
              <div className="orderBasicInfo">
                <h2>To</h2>
                <div className="orderMainDiv">
                  <i className="fa fa-user detail-in-icon" />
                  <h3 className="orderMainText">{orderDetail.name}</h3>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={buildings} alt="Logo" />
                  <p className="orderMainText">{orderDetail.address}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={pharmacyCallBlack} alt="Logo" />
                  <p className="orderMainText">{orderDetail.phoneNumber}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={iphone} alt="Logo" />
                  <p className="orderMainText">{orderDetail.cellNumber}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={landline} alt="Logo" />
                  <p className="orderMainText">{orderDetail.workNumber}</p>
                </div>
              </div>
              <div className="locImgDiv">
                {/* <h1>Location View</h1> */}
                  {orderDetail.lat && orderDetail.lng && (
                    <img
                      src={streetViewUrl}
                      alt="Google Maps Street View"
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                  )}
                {/* <img className="locatioImg" src={locationImg} alt="location img" /> */}
              </div>
            </div>
            
            <Grid container className="order-form-grid" spacing={2}>
              {/* First Row */}
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Name</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="name"
                  placeholder="Name"
                  value={orderDetail.name}
                  onChange={handleOrderChange}
                />
              </Grid>
              <Grid item className="order-grid" xs={3}>
      <label className="order-field-label">Phone Number</label>
      <InputMask
        mask="(999) 999-9999"
        value={orderDetail.phoneNumber}
        onChange={handleOrderChange}
      >
        {(inputProps) => (
          <input
            {...inputProps}
            autoComplete="off"
            type="text"
            className="order-field"
            name="phoneNumber"
            placeholder="(___) ___-____"
          />
        )}
      </InputMask>
    </Grid>
    <Grid item className="order-grid" xs={3}>
        <label className="order-field-label">Cell Number</label>
        <InputMask
          mask="(999) 999-9999"
          value={orderDetail.cellNumber}
          onChange={handleOrderChange}
        >
          {() => (
            <input
              autoComplete="off"
              type="text"
              className="order-field"
              name="cellNumber"
              placeholder="(___) ___-____"
              />
          )}
        </InputMask>
      </Grid>
      <Grid item className="order-grid" xs={3}>
        <label className="order-field-label">Work Number</label>
        <InputMask
          mask="(999) 999-9999"
          value={orderDetail.workNumber}
          onChange={handleOrderChange}
        >
          {() => (
            <input
              autoComplete="off"
              type="text"
              className="order-field"
              name="workNumber"
              placeholder="(___) ___-____"
              />
          )}
        </InputMask>
      </Grid>

              {/* Second Row */}
              <Grid item className="order-grid" xs={6}>
                <label className="order-field-label">Address</label>
                {/* <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="address"
                  placeholder="Address"
                  value={orderDetail.address}
                  onChange={handleOrderChange}
                /> */}

                {parseInt(pharmacyDetail.id) === 2 ?
                  <Autocomplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={handlePlaceSelect}
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    console.log(e.target.value)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      type="text"
                      name="address"
                      id="address"
                      className="order-field"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value)
                      }}
                    />
                  )}
                  // style={{
                  //   border: '0px',
                  //   width: '100%',
                  //   borderRadius: '5px',
                  //   font: 'inherit',
                  //   letterSpacing: 'inherit',
                  //   color: 'currentColor',
                  //   boxSizing: 'content-box',
                  //   background: 'none',
                  //   height: '1.4375em',
                  //   margin: 0,
                  //   display: 'block',
                  //   minWidth: 0,
                  //   animationName: 'mui-auto-fill-cancel',
                  //   animationDuration: '10ms',
                  //   padding: '10.5px 14px 10.5px 12px'
                  // }}
                  className="order-field"
                  inputProps={{
                    style: {
                      '&:hover': {
                        borderColor: '#69c0ff'
                      },
                      '&:active': {
                        borderColor: '#69c0ff'
                      },
                      '&:focus': {
                        borderColor: '#69c0ff'
                      }
                    }
                  }}
                  options={options}
                />
                :
                <div>
                    <input type="text" className="order-field" value={address} onChange={handleSearch} placeholder="Address" />
                    {results.length > 0 && 
                      <ul className="addressList">
                          {results.map((item, index) => (
                              <li key={index} style={{ borderBottom: "1px solid grey", cursor: 'pointer'}} onClick={() => handlePlaceSelect(item)}>
                                  {item.title}
                              </li>
                          ))}
                      </ul>
                    }
                </div>}
                {addressError !== "" && <p style={{color: "red"}}>{addressError}</p>}
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Zip Code</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="zip"
                  placeholder="Zip Code"
                  value={orderDetail.zip}
                  onChange={handleOrderChange}
                />
                {orderDetail.zip === "" && <p style={{color: "red"}}>ZipCode is mandatory!</p>}
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Apt Number</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="apart"
                  placeholder="Apt Number"
                  value={orderDetail.apart}
                  onChange={(e) => handleApartmentChange(e)}
                />
              </Grid>
            </Grid>


            <div className="textLine">
              <p className="textLine-p"><span style={{fontWeight: '700'}}>Please Confirm</span> with the patient for apartment number if they don’t live in a private house </p>
              <div className="textCheckDiv">
                <p>Business Address</p>
                <Checkbox name="bussAddress" checked={orderDetail.bussAddress} onChange={handleOrderChange} />
              </div>
            </div>

            <div className="rxDetail">
              <h2>Details</h2>
              <Grid container className="order-form-grid" spacing={2}>
                {/* First Row */}
                <Grid item className="order-grid" xs={2}>
                  <h3>Count</h3>
                </Grid>
                <Grid item className="order-grid" xs={3}>
                  <h3>RX Number</h3>
                </Grid>
                <Grid item className="order-grid" xs={2}>
                  <h3>Date</h3>
                </Grid>
                <Grid item className="order-grid" xs={3}>
                  <h3>Copay</h3>
                </Grid>
                <Grid item className="order-grid" xs={2}>
                  <h3 style={{textAlign: 'center'}}>Remove</h3>
                </Grid>
              </Grid>
              {orderDetail.rxList.map((rx, i) => (
                <Grid container className="order-form-grid" spacing={2}>
                  {/* First Row */}
                  <Grid item className="order-grid" xs={2}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="i"
                      style={{textAlign: 'center'}}
                      placeholder="Count"
                      value={i + 1}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-grid" xs={3}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name={`rxList.${i}.rx_number`}
                      placeholder="RX Number"
                      value={rx.rx_number}
                      onChange={(event) => handleOrderChange(event, i)}
                    />
                  </Grid>
                  <Grid item className="order-grid" xs={2}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name={`rxList.${i}.date_filled`}
                      placeholder="Date Filled"
                      value={rx.date_filled}
                      onChange={(event) => handleOrderChange(event, i)}
                    />
                  </Grid>
                  <Grid item className="order-grid" style={{display: 'flex', alignItems: 'center'}}  xs={3}>
                    <input
                      autoComplete="off"
                      type="number"
                      className="order-field"
                      name={`rxList.${i}.pat_pay_amount`}
                      placeholder="Copay"
                      value={rx.pat_pay_amount}
                      onChange={(event) => handleOrderChange(event, i)}
                    />
                    <Checkbox name={`rxList.${i}.select`} checked={rx.select} onChange={(event) => handleOrderChange(event, i)} />
                  </Grid>
                  <Grid item style={{textAlign: 'center'}}  className="order-grid" xs={2}>
                    <DeleteIcon style={{color: '#000000', textAlign: 'center', cursor: 'pointer'}} onClick={() => handleDeleteRxItem(i)} />
                  </Grid>
                </Grid>
              ))}
              <div className="addRx" onClick={handleAddRxItem}>
                <AddBoxIcon />
                Add Item
              </div>
            </div>

            <Grid container className="order-form-grid" spacing={2}>
              <Grid item className="order-grid" xs={6}>
                <h2>Delivery Note</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid" xs={12}>
                    <textarea
                      rows="9" 
                      className="order-textarea-field"
                      name="note"
                      placeholder="Write any indications / missing information for delivery driver and facility"
                      value={orderDetail.note}
                      onChange={handleOrderChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <h2>Actions</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600}} className="order-field">Signature Required</p>
                    <Checkbox style={{padding: '0px'}} name="signRequired" checked={orderDetail.signRequired} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#FF950F", color: "#FF950F", fontSize: "16px", fontWeight: 600}} className="order-field">Required Photo ID</p>
                    <Checkbox style={{padding: '0px'}} name="reqPhotoId" checked={orderDetail.reqPhotoId} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#0094FF", color: "#0094FF", fontSize: "16px", fontWeight: 600}} className="order-field">Refrigerated</p>
                    <Checkbox style={{padding: '0px'}} name="registed" checked={orderDetail.registed} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#05B321", color: "#05B321", fontSize: "16px", fontWeight: 600}} className="order-field">Copay ${orderDetail.totalCopay.toFixed(2)}</p>
                    <Checkbox style={{padding: '0px'}} name="collectCopay" checked={orderDetail.collectCopay} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <select name="parcelPlace" id="parcelPlace" style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600, backgroundColor: "#fff"}} className="order-field" value={orderDetail.parcelPlace} onChange={handleOrderChange}>
                      <option value="">
                        <em>Select Parcel Place</em>
                      </option>
                        <option value="Face to face">FACE TO FACE</option>
                        <option value="Leave with receptionist">LEAVE WITH RECEPTIONIST</option>
                        <option value="Leave at door if pt e-signs">LEAVE AT DOOR IF PT E-SIGNS</option>
                        <option value="Leave at door">LEAVE AT DOOR</option>
                        <option value="Leave in the mailbox">LEAVE IN THE MAILBOX</option>
                        <option value="Leave in mail box if pt e-signs">LEAVE IN MAIL BOX IF PT E-SIGNS</option>
                    </select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <h2>Get Forms Signed</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600}} className="order-field">Hipaa Form</p>
                    <Checkbox style={{padding: '0px'}} name="hippaForm" checked={orderDetail.hippaForm} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600}} className="order-field">Lien Form</p>
                    <Checkbox style={{padding: '0px'}} name="lineForm" checked={orderDetail.lineForm} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600}} className="order-field">NF AOB Form</p>
                    <Checkbox style={{padding: '0px'}} name="nfForm" checked={orderDetail.nfForm} onChange={handleOrderChange} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            <div className="order-btn">
              <button type="button" className="del-order" onClick={() => navigation(`/pharmacy/${pid}/list/order`)}>Cancel</button>
              <button type="submit" disabled={loading} className="save-order">Save & Print <PrintIcon style={{color: '#0455A6', marginLeft: '10px'}} /></button>
            </div>
          </form>
        </Grid>
        <Grid item xs={5}>
          <div className="actiontabsmain">
            <div className="actiontabs">
              <p
                className={`actiontabButtons ${pdfFile === null && 'disable'}`}
                onClick={() => handleTabClick('Upload slip')}
              >
                Upload slip
              </p>
              <p
                className={`actiontabButtons disable`}
              >
                Signed slip
              </p>
              <p className="actiontabButtons disable">Delivery Proof</p>
              <p className="actiontabButtons disable">Conversation</p>
            </div>
            
            {/* <PDFDownloadLink document={<PrintDocDown orderDetail={orderDetailView} edit={false} view={true} pharmacy={pharmacyDetail} />} fileName="client-details.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : error ? error : <img src={route_report} alt="print" />)}
              </PDFDownloadLink> */}
              {/* <img src={route_report} onClick={() => handlePrintSlip()} alt="print" /> */}
          </div>

          {activeTab === 'Upload slip' && (
            <div style={{ display: 'contents' }}>
              {/* <PDFViewer style={{ width: '100%', height: '100%' }}> */}
              {/* <Printdoc orderDetail={orderDetailView} upload={true} edit={false} view={true} pharmacy={pharmacyDetail} /> */}
              {/* </PDFViewer> */}
              {/* <iframe src={pdfFile} width={'100%'} height={'100%'} style={{marginTop: 10}}/> */}
              {/* <ShowPDF pdf={pdfFile} /> */}
              {pdfFile !== null && <iframe src={`${pdfFile}#toolbar=0&navpanes=0&scrollbar=0`} width='100%' height='100%' style={{marginTop: 10}} />}
            </div>
          )}

          {activeTab === 'Signed slip' && (
            <div style={{ display: 'contents' }}>
              <Printdoc orderDetail={orderDetail} upload={false} edit={false} view={false} pharmacy={pharmacyDetail} />
            </div>
          )}
          
          {/* <button onClick={DownloadBtn}>Download now!</button>
           */}
          {/* <PDFViewer width="600" height="800">
            
          </PDFViewer> */}
          
          
        </Grid>
      </Grid>
      <div style={{display: 'none'}}>
        <div ref={reportTemplateRef} style={{ display: 'contents' }}>
          <Printdoc orderDetail={orderDetail} edit={false} />
        </div>
      </div>
      <div style={{display: 'none'}}>
        <div style={{margin: '13px', padding: '16px', maxWidth: '3.75in'}} ref={componentRef}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <p style={{margin: '0', fontSize: '11px',fontFamily: "Saira, sans-serif"}}>Date: {moment(createDate).format('MM/DD/YYYY')}</p>
              <p style={{margin: '0', fontSize: '11px',fontFamily: "Saira, sans-serif"}}>Time: {moment(createDate).format('hh:mm:ss A')}</p>
            </div>
            {/* <img src={pdfLogo} alt="logo" style={{width: '100px', height: '100px'}} /> */}
            <img src={pdfLogo} style={{width: "120px", fontFamily: "Saira, sans-serif"}} alt="logo" />
            <div>
              <p style={{margin: '0', fontSize: '11px',fontFamily: "Saira, sans-serif"}}>Order #: {parseInt(pharmacyDetail.todayOrderCount) + 1}</p>
            </div>
          </div>
          <hr />
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
  <div>
    <h3 style={{margin: '0', fontSize: '15px', fontWeight: 700, padding: '5px 0', fontFamily: "Saira, sans-serif"}}>
      Order ID: <span style={{textDecoration: 'underline'}}>{orderResponse && orderResponse.slug}</span>
    </h3>
    <h3 style={{margin: '0', fontSize: '15px', fontWeight: 700, padding: '5px 0', fontFamily: "Saira, sans-serif"}}>
      CLIENT: <span style={{textDecoration: 'underline'}}>{orderDetail.name}</span>
    </h3>
    <h5 style={{margin: '0', fontSize: '15px', fontWeight: 300, padding: '5px 0', fontFamily: "Saira, sans-serif"}}>
      Rx Count: {orderDetail.rxList.length}
    </h5>
      <h5 style={{margin: '0', fontSize: '15px', fontWeight: 700, fontFamily: "Saira, sans-serif", width: '150px'}}>
        Delivery Preference:
      </h5>
      <h5 style={{margin: '0', fontSize: '14px', fontWeight: 300, padding: '0 5px', textTransform: "uppercase", fontFamily: "Saira, sans-serif", width: '150px'}}>
        {orderDetail.parcelPlace}
      </h5>
    <h4 style={{margin: '0', fontSize: '15px', fontWeight: 700, padding: '5px 0', fontFamily: "Saira, sans-serif"}}>
      Delivery Date: {moment(orderDetail.deliveryDate).format('MM-DD-YYYY')}
    </h4>
    <h4 style={{margin: '0', fontSize: '15px', fontWeight: 700, padding: '5px 0', fontFamily: "Saira, sans-serif"}}>
      Time Window: {orderDetail.timeWindow}
    </h4>
    {orderDetail.note !== "" && (
      <>
        <p style={{margin: '0', fontSize: '13px', fontFamily: "Saira, sans-serif"}}>
          <span style={{fontWeight: 600, fontFamily: "Saira, sans-serif"}}>Delivery Note:</span>
        </p>
        <p style={{margin: '0', fontSize: '13px', padding: '5px 0', fontFamily: "Saira, sans-serif", width: '2.1in'}}>
          {orderDetail.note}
        </p>
      </>
    )}
  </div>
  <div style={{display: "flex", flexDirection: "column", alignItems: 'center', padding: '5px 0'}}>
    {orderDetail.totalCopay !== undefined && orderDetail.totalCopay !== 0 && (
      <p style={{fontWeight: 700, fontSize: '16px', border: '2px solid black', padding: '0 2px', textAlign: 'center', marginBottom: "10px", width: "90px"}}>
        COPAY: $ {orderDetail.totalCopay.toFixed(2)}
      </p>
    )}
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {orderResponse && Object.keys(orderResponse).length !== 0 ? (
        <QRCode value={orderResponse.id.toString()} renderAs="svg" size={80} />
      ) : (
        <QRCode renderAs="svg" size={80} value="no Order" />
      )}
     <h5 style={{margin: '10px 0', fontSize: '22px', fontWeight: 800, textTransform: "uppercase", fontFamily: "Saira, sans-serif"}}>
  {orderResponse.city_code ? (
    <>
      {orderResponse.city_code}
      {orderResponse.zone_code ? ` - ${orderResponse.zone_code}` : ''}
    </>
  ) : ''}
</h5>
    </div>
  </div>
</div>


          <div style={{display: 'flex', flexDirection: "column",justifyContent: 'space-between',fontFamily: "Saira, sans-serif"}}>
            { (orderDetail.hippaForm || orderDetail.lineForm || orderDetail.nfForm) && (
              <>
                <h4 style={{ margin: '0', fontSize: '14px', fontWeight: 700,padding: '10px 0', fontFamily: "Saira, sans-serif" }}>
                  Get Forms Signed:
                </h4>
                <h5 style={{ margin: '0', fontSize: '12px', fontWeight: 700,fontFamily: "Saira, sans-serif" }}>
                  {orderDetail.hippaForm && <span>Hipaa Form</span>}
                  {orderDetail.lineForm && <span style={{ marginLeft: '8px',fontFamily: "Saira, sans-serif" }}>Lien Form</span>}
                  {orderDetail.nfForm && <span style={{ marginLeft: '8px',fontFamily: "Saira, sans-serif" }}>NF AOB Form</span>}
                </h5>
              </>
            )}
            <h4 style={{margin: '0', fontSize: '16px', textAlign: 'center', fontWeight: "700",fontFamily: "Saira, sans-serif"}}>
              { 
                orderDetail.registed && orderDetail.reqPhotoId ? "Refrigerated / Photo ID" : 
                orderDetail.reqPhotoId ? "Photo ID" :
                orderDetail.registed ? "Refrigerated" : " "
              }
              { 
                orderDetail.registed && orderDetail.reqPhotoId ? 
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={registered} style={{marginRight: '5px'}} alt="logo" /> <img style={{marginLeft: '10px'}} src={photoId} alt="logo" /> 
                </div> 
                : 
                orderDetail.reqPhotoId ? <img style={{margin: 'auto'}} src={photoId} alt="logo" /> :
                orderDetail.registed ? <img style={{margin: 'auto'}} src={registered} alt="logo" /> : " "
              }
            </h4>
            <h5 style={{margin: '0', marginTop: "10px", fontSize: '13px', fontWeight: 700, textAlign: 'center',fontFamily: "Saira, sans-serif"}}>From</h5>
            <h4 style={{margin: '0', fontSize: '13px', fontWeight: 700, textAlign: 'center',fontFamily: "Saira, sans-serif"}}>{pharmacyDetail.name}</h4>
            <h4 style={{margin: '0', fontSize: '13px', fontWeight: 300, textAlign: 'center',fontFamily: "Saira, sans-serif"}}>{pharmacyDetail.location}</h4>
            <h4 style={{margin: '0', fontSize: '13px', fontWeight: 300, textAlign: 'center',fontFamily: "Saira, sans-serif"}}>Phone {pharmacyDetail.contact_no}</h4>
          </div>
        </div>
      </div>

      <div style={{display: 'none'}}>
        <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px'}} ref={componentRef2}>
          <Printdoc orderDetail={orderDetail} edit={true} upload={false} view={false} pharmacy={pharmacyDetail} />
        </div>
      </div>
    </div>
  );
}

export default AddOrder;
