import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Device } from 'twilio-client';
import {
    Dialog,
    DialogTitle,
    Button,
    DialogContent
} from "@mui/material";
import { getApiWithAuth, patchApiWithAuth } from '../../../utils/api';
import { AGENTS, VOCIE_CONNECT } from '../../../utils/apiUrls';
import CallEndIcon from '@mui/icons-material/CallEnd';
import Phone from "@mui/icons-material/Phone";
import CustomModel from '../../CommonComponents/CustomModel/CustomModel';


function VoiceComponent({ orderCall, setSelectedOrder }) {
    const [loading, setLoading] = useState(null);
    const [device, setDevice] = useState(null);
    const [seconds, setSeconds] = useState(null);
    const [callStatus, setCallStatus] = useState('ringing');

    const [number, setNumber] = useState('');
    const [agent, setAgent] = useState('');
    const [callerName, setCallerName] = useState('');
    const [incomingCall, setIncomingCall] = useState(false);
    const [callModel, setCallModel] = useState(false);
    const [accept, setAccept] = useState(false);
    const [busy, setBusy] = useState(false);
    const [isDeviceReady, setIsDeviceReady] = useState(false);


    // const [ringtonePlayer, setRingtonePlayer] = useState(new Audio('./ringtown.mp3'));

    // 

    useEffect(() => {
        if (orderCall && device && isDeviceReady) {
            setCallModel(true);
            setNumber(orderCall.number);
            setCallerName(orderCall?.name);
            setCallStatus("connected");
            console.log("workk")
            device.connect({ To: orderCall.number, order: orderCall?.id, agent: agent });
            setAccept(true);
        }
    }, [orderCall, device, isDeviceReady]);


    useEffect(() => {
        const userType = localStorage.getItem("userType");
        if (userType === "Admin" || userType === "Staff" || userType === "SubAdmin" || userType === "Driver") {
            let isActive = true;

            if (!device) {
                const newDevice = new Device();

                const fetchTokenAndSetupDevice = async () => {
                    try {
                        if (isActive) setLoading(true);

                        let set_agent = localStorage.getItem("sessionId")
                        const response = await getApiWithAuth(VOCIE_CONNECT, { "session_id": set_agent });
                        if (response.data.status === 200 && isActive) {
                            newDevice.setup(response.data.data.token, { debug: true });
                            setAgent(set_agent);


                            newDevice.on('ready', () => {
                                setIsDeviceReady(true);
                            });

                            newDevice.on('offline', () => {
                                setIsDeviceReady(false);
                            });

                            newDevice.on('error', async function (error) {
                                setCallModel(false);
                                setNumber("");
                                setCallerName("");
                                setAccept(false);
                                await patchApiWithAuth(
                                    AGENTS + `update-by-session/?session_id=${set_agent}`,
                                    { status: "Active" }
                                );
                                console.error(`Twilio Error: ${error.message}`, {
                                    position: "top-right",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                });
                            });

                            newDevice.on('connect', async function (conn) {
                                await patchApiWithAuth(
                                    AGENTS + `update-by-session/?session_id=${set_agent}`,
                                    { status: "Busy" }
                                );
                                toast.success('Successfully established call!', {
                                    position: "top-right",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                });
                            });

                            newDevice.on('disconnect', async function (conn) {
                                setCallModel(false);
                                setNumber("");
                                setCallerName("");
                                setAccept(false);
                                await patchApiWithAuth(
                                    AGENTS + `update-by-session/?session_id=${set_agent}`,
                                    { status: "Active" }
                                );
                                if (conn.status() === "pending") {
                                    toast.info('Incoming call was cancelled by the caller.', {
                                        position: "top-right",
                                        autoClose: 1000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                } else {
                                    toast.info('Call ended.', {
                                        position: "top-right",
                                        autoClose: 1000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                }
                            });

                            newDevice.on('cancel', async function (conn) {
                                if (!accept) {
                                    setCallModel(false);
                                    setNumber("");

                                    setCallerName("");
                                    await patchApiWithAuth(
                                        AGENTS + `update-by-session/?session_id=${set_agent}`,
                                        { status: "Active" }  // Set agent back to "Active" on cancel
                                    );

                                    toast.info('Incoming call was cancelled by the caller.', {
                                        position: "top-right",
                                        autoClose: 1000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                }
                            });

                            newDevice.on('incoming', async function (conn) {

                                const paramsString = decodeURIComponent(conn.parameters.Params);
                                const params = new URLSearchParams(paramsString);
                                const callersName = params.get('callerName');
                                const busy = params.get('busy');
                                if (busy === 'true') {
                                    conn.reject()
                                    toast.warn('Incoming call detected.', {
                                        position: "top-right",
                                        autoClose: 1000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                } else {
                                    setBusy(true);
                                    setCallerName(callersName)
                                    setIncomingCall(true);
                                    setCallModel(true);
                                    setNumber(conn.options.callParameters.From);
                                    await patchApiWithAuth(
                                        AGENTS + `update-by-session/?session_id=${set_agent}`,
                                        { status: "Busy" }
                                    );
                                    toast.info('Incoming call detected.', {
                                        position: "top-right",
                                        autoClose: 1000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                }
                            });

                            if (isActive) setDevice(newDevice);
                        } else {
                            throw new Error('Failed to fetch token');
                        }
                    } catch (error) {
                        console.error('Error fetching token:', error);
                    } finally {
                        if (isActive) setLoading(false);
                    }
                };

                fetchTokenAndSetupDevice();
            }

            return () => {
                isActive = false;
                if (device) {
                    device.destroy();
                }
            };
        }

    }, [device]);


    useEffect(() => {
        if (callModel && accept) {
            const interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 1000);
            return () => clearInterval(interval);
        }
        // Reset timer when dialog closes
        else {
            setSeconds(0);
        }
    }, [callModel, incomingCall, accept]);

    useEffect(() => {
        const updateAgentStatus = async () => {
            if (accept && agent !== '') {
                await patchApiWithAuth(
                    AGENTS + `update-by-session/?session_id=${agent}`,
                    { status: "Busy" }
                );
            }
        };

        const timeoutId = setTimeout(() => {
            updateAgentStatus();
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [accept, agent]);

    // Format the seconds into HH:MM:SS
    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return [hours, minutes, seconds]
            .map(val => val < 10 ? `0${val}` : val)
            .join(':');
    };

    const hangUp = async () => {
        if (device) {
            device.disconnectAll();
            await patchApiWithAuth(
                AGENTS + `update-by-session/?session_id=${agent}`,
                { status: "Active" }
            );
            setSelectedOrder(null);
        } else {
            console.error("No active device to disconnect calls.");
        }
        setCallModel(false)
        setNumber("")
        setCallerName("")
        setAccept(false)
        setDevice(null)
    };

    const acceptCall = async () => {
        if (device && device.activeConnection()) {
            // Accept the call
            device.activeConnection().accept();
            const conn = device.activeConnection()
            const paramsString = decodeURIComponent(conn.parameters.Params);
            const params = new URLSearchParams(paramsString);
            const callSID = params.get('callSID');
            setIncomingCall(false);
            setAccept(true);
            setCallStatus("connected");
            await patchApiWithAuth(
                AGENTS + `update-by-session/?session_id=${agent}&callSID=${callSID}`,
                { status: "Busy" }
            );

        }
    };

    const cancelCall = async () => {
        const connection = device && device.activeConnection();
        if (device) {
            connection.reject();
            setIncomingCall(false);
            setCallModel(false)
            setAccept(false);
            setNumber("")
            setCallerName("")
            await patchApiWithAuth(
                AGENTS + `update-by-session/?session_id=${agent}`,
                { status: "Active" }
            );
            setDevice(null)
        }
    };

    const handleCallModelClose = () => {
        hangUp();
        setCallModel(false);
        setNumber("")
        setCallerName("")
    }


    if (!callModel || callStatus === 'ended') return null;

    return (
        <>
            {/* {busy ?
            <CustomModel isOpen={callModel} top={true}>
                <div className="phone-busy-modal-body">
                    <p>{callerName}</p>
                    <p>{number}</p>
                </div>
                <div className="phone-busy-modal-footer">
                    {incomingCall ? (
                        <>
                            <button onClick={acceptCall} className='acceptCall'><Phone /></button>
                            <button onClick={cancelCall} className='cancelCall'><CallEndIcon /></button>
                        </>
                    ) : (
                        <button onClick={hangUp} className='hangUp'><CallEndIcon /></button>
                    )}
                </div>
            </CustomModel>
            :
            <CustomModel isOpen={callModel}>
                <div className="phone-modal-header">
                    {callStatus === 'ringing' ? 'Ringing' : 'Connected'}
                </div>
                <div className="phone-modal-body">
                    <p>{callerName}</p>
                    <p>{number}</p>
                    <p>{formatTime(seconds)}</p>
                </div>
                <div className="phone-modal-footer">
                    {incomingCall ? (
                        <>
                            <button onClick={acceptCall} className='acceptCall'><Phone /></button>
                            <button onClick={cancelCall} className='cancelCall'><CallEndIcon /></button>
                        </>
                    ) : (
                        <button onClick={hangUp} className='hangUp'><CallEndIcon /></button>
                    )}
                </div>
            </CustomModel>
            } */}

            <CustomModel isOpen={callModel}>
                <div className="phone-modal-header">
                    {callStatus === 'ringing' ? 'Ringing' : 'Connected'}
                </div>
                <div className="phone-modal-body">
                    <p>{callerName}</p>
                    <p>{number}</p>
                    <p>{formatTime(seconds)}</p>
                </div>
                <div className="phone-modal-footer">
                    {incomingCall ? (
                        <>
                            <button onClick={acceptCall} className='acceptCall'><Phone /></button>
                            <button onClick={cancelCall} className='cancelCall'><CallEndIcon /></button>
                        </>
                    ) : (
                        <button onClick={hangUp} className='hangUp'><CallEndIcon /></button>
                    )}
                </div>
            </CustomModel>
        </>
    );
}

export default VoiceComponent;
