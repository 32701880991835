import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import H from '@here/maps-api-for-javascript';
import Autocomplete from "react-google-autocomplete";
import {
  Menu,
  MenuItem,
  Box,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
} from "@mui/material";
import axios from "axios";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Link } from "react-router-dom";
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


import { toast } from "react-toastify";

import { ROUTE_DETAIL, ALL_ROUTES, REOPTIMIZED_ROUTE, CREATE_ORDER ,ORDER_FAILED} from "../../../utils/apiUrls";
import { getApiWithAuth, patchApiWithAuth , postApiWithAuth, putApiWithAuth} from "../../../utils/api";

import {
  landline,
  iphone,
  cellphone,
  buildings,
  open_route,
  info,
  userBlack,
  refreshimg,
} from "../../../assests";

import RouteObject from "../../MiniComponents/RouteObject";
import "./routeDetail.css";
import moment from "moment";
import { CoPresentOutlined } from "@mui/icons-material";

const mapStyles = {
  width: "100%",
  height: "100%",
};

const MAX_WAYPOINTS_PER_REQUEST = 25;

function floatToTime(floatValue) {
  const hours = Math.floor(floatValue / 60);
  const remainingMinutes = floatValue % 60;
  const minutes = Math.floor(remainingMinutes);
  const seconds = Math.round((remainingMinutes % 1) * 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours} hour${hours !== 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
  }

  return parts.join(" ");
}

function modifyDateTime(dateTimeString, addedMinutes) {
  const dateTime = new Date(dateTimeString);
  dateTime.setMinutes(dateTime.getMinutes() + Math.floor(addedMinutes));
  return dateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

const apikey = process.env.REACT_APP_HERE_API;

function RouteHereDetail() {

  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const ui = useRef(null);

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [route, setRoute] = useState(null);
  const [routes, setRoutes] = useState();
  const [routeOrders, setRouteOrders] = useState([]);
  const [userPosition, setUserPosition] = useState([]);
  const [allRouteOrders, setAllRouteOrders] = useState([]);
  const [selectedOption, setSelectedOption] = useState(routes?.routeTrip);
  const [modalOpen, setModalOpen] = useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [reOrdering, setReOrdering] = useState(false);
  const [order, setOrder] = useState(0);
  const [routeOrderId, setRouteOrderId] = useState(0);
  const [address, setAddress] = useState("");
  const [results, setResults] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [currentBubble, setCurrentBubble] = useState(null)
  const [formState, setFormState] = useState({
    address: "",
  });
  const [modalDeliveryNote, setModalDeliveryNote] = useState(false);
  const [deliveryNote, setDeliveryNote] = useState("");
  const [mapType, setMapType] = useState('StorePolyline');
  const [optimizeFor, setOptimizeFor] = useState('DISTANCE');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [estimatedTime, setEstimatedTime] = useState();
  const [distanceTotal, setDistanceTotal] = useState();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [deliveryImages, setDeliveryImages] = useState([]);


  // Function to handle checkbox changes
  const handleCheckboxChange = (type) => {
    setSelectedOption(type);
    setRoutes([])
    setMarkers([])
    setRouteOrders([])
    setAllRouteOrders([])
    setReOrdering(false)
  };


  const handleMapTypeChange = (e) => {
    setMapType(e.target.value);
    setRoutes([])
    setMarkers([])
    setRouteOrders([])
    setAllRouteOrders([])
    setReOrdering(false)
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
    setRoutes([])
    setMarkers([])
    setRouteOrders([])
    setAllRouteOrders([])
    setReOrdering(false)
  };

  useEffect(() => {
    if (routes?.routeTrip && mapType === "StorePolyline") {
      setSelectedOption(routes.routeTrip);
    }
  }, [routes]);

  const handleOrderSelect = (order, index) => {
    if (order.id === selectedOrder) {
      setSelectedOrder(null);
      ui.current.removeBubble(currentBubble);
      setCurrentBubble(null);
    } else {
      setSelectedOrder(order.id);

      const infoContent = `
        <div style="width: 400px; font-size: 12px;">
          <div>
            <p>
              <span style="font-weight: 700;">Order Id: </span>
              ${order.order.slug}
            </p>
            <p>
              <span style="font-weight: 700;">Name: </span>
              ${order.order.name}
            </p>
            <p>
              <span style="font-weight: 700;">Address: </span>
              ${order.order.address}
            </p>
            ${routes?.routeStatus === "In Progress" ? (
          `<p>
                <span style="font-weight: 700;">
                  Planned Arrival Time: 
                </span>
                ${modifyDateTime(routes?.startTime, order.estimatedTimeCal)}
              </p>`
        ) : routes?.routeStatus === "Done" ? (
          `<div>
                <p>
                  <span style="font-weight: 700;">
                    Planned Arrival Time: 
                  </span>
                  ${modifyDateTime(routes?.startTime, order.estimatedTimeCal)}
                </p>
                <p>
                  <span style="font-weight: 700;">
                    Delivery Time: 
                  </span>
                  ${moment(order.order.deliverAt).format("hh:mm A")}
                </p>
              </div>`
        ) : (
          `<p>
                <span style="font-weight: 700;">
                  Planned Arrival Time: 
                </span>
                ${floatToTime(order.estimatedTimeCal)} (after start)
              </p>`
        )}
          </div>
        </div>
      `;
      // Find the marker associated with the selected order
      const selectedMarker = markers.find((marker, i) => i === index);

      if (currentBubble) {
        ui.current.removeBubble(currentBubble);
        setCurrentBubble(null);
      }

      if (selectedMarker) {
        // map.current.setCenter({lat:parseFloat(order.order.latitude), lng:parseFloat(order.order.longitude)});
        // map.current.setZoom(14);
        showInfoBubble(selectedMarker, infoContent);
      }
    }

  };

  const handleOptimizeForChange = (e) => {
    setOptimizeFor(e.target.value);
    setReOrdering(false)
    setMarkers([])
  }

  const { rid, copay } = useParams();

  const getRouteDetail = async () => {
    setLoading(true);

    const response = await getApiWithAuth(ROUTE_DETAIL + `?route=${rid}`);
    if (response.data !== undefined) {
      if (copay && copay === "copay") {
        const responseCopay = await getApiWithAuth(
          ALL_ROUTES + `getCopayOrders/?route_id=${rid}`
        );
        setAllRouteOrders(responseCopay.data.data);
      } else {
        setAllRouteOrders(response.data.data.orders);
      }
      setRouteOrders(response.data.data.orders);
      setRoutes(response.data.data.route);
      setEstimatedTime(response.data.data.route.estimatedTimeCal)
      setDistanceTotal(response.data.data.route.distanceCal)
      setUserPosition({ lat: response.data.data.route.latitude, lng: response.data.data.route.longitude })
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const filteredOrders = routeOrders.filter(order =>
      order.order.slug.toLowerCase().includes(e.toLowerCase())
    );
    setAllRouteOrders(filteredOrders);
  };

  const reOptimizedRoute = async () => {
    setLoading(true);
    const response = await getApiWithAuth(REOPTIMIZED_ROUTE + `${rid}/`);
    if (response.data !== undefined) {
      getRouteDetail();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleOptimizedRoute = async () => {
    setLoading(true);
    let totalDistance = 0
    let totalDuration = 0
    for (const routeOrder of routeOrders) {
      const { id: routeOrderId, number, polyline, duration, distance } = routeOrder;
      totalDuration += duration;
      // Accumulate total distance
      totalDistance += distance;
      // Prepare formData
      const formData = {
        number: number,
        polyline: polyline,
        distanceCal: distance,
        estimatedTimeCal: totalDuration,
        routeTrip: selectedOption,
      };

      // Make API call
      try {
        const response = await patchApiWithAuth(
          ROUTE_DETAIL + `${routeOrderId}/`,
          formData
        );
        console.log('API call successful:', response);
      } catch (error) {
        console.error('API call failed:', error);
      }
    }
    const modifiedTotalDuration = totalDuration;
    const formData = {
      distanceCal: totalDistance,
      estimatedTimeCal: modifiedTotalDuration,
    };
    try {
      const response = await patchApiWithAuth(
        ALL_ROUTES + `${routes.id}/`,
        formData
      );
      if (response.status !== 400) {
        toast.success("Route Optimized Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Backend Error!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!map.current && routeOrders?.length > 0) {
      platform.current = new H.service.Platform({ apikey });
      const defaultLayers = platform.current.createDefaultLayers({
        pois: true
      });

      map.current = new H.Map(
        mapRef.current,
        defaultLayers.vector.normal.map, {
        zoom: 14,
        center: userPosition,
      }
      );

      ui.current = H.ui.UI.createDefault(map.current, defaultLayers);
      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(map.current)
      );

      // if (routeOrders.length > 0) {
      //   if (routeOrders[0].polyline === null || routeOrders[0].polyline === "") {
      //     findAndDisplayRoute(platform.current, map.current, userPosition, routeOrders);
      //   } else {
      //       routeResponseHandler(map.current, routeOrders, null);
      //   }
      // }
    }
    if (routeOrders?.length > 0) {
      if ((mapType === "LiveMap" && !reOrdering)) {
        setLoading(true)
        findAndDisplayRoute(platform.current, map.current, userPosition, routeOrders, optimizeFor);
      } else if (mapType === "StorePolyline") {
        routeResponseHandler(map.current, routeOrders, null);
      }
    }

    if (routeOrders?.length < 1) {
      getRouteDetail();
    }
  }, [apikey, routeOrders, optimizeFor, mapType, selectedOption]);

  // Function to find and display the best route,
  async function findAndDisplayRoute(platform, map, start, orderList, optimizeFor) {
    const destination = orderList[orderList.length - 1]; // Assuming last restaurant as the final destination
    // const viaPoints = orderList.slice(0, -1); // All except the last point

    const bestRoute = await findBestRouteSequence(apikey, start, orderList, destination, optimizeFor);

    if (bestRoute) {
      const waypoints = bestRoute[0].waypoints

      const sortedWaypoints = sortOrder === 'ascending'
        ? waypoints
        : [waypoints[0], ...waypoints.slice(1).reverse()];

      const allSections = [];

      const lastIndex = sortedWaypoints.length - 1;

      for (let i = 0; i < (selectedOption === "startToEnd" ? lastIndex - 1 : lastIndex); i++) {
        const start = sortedWaypoints[i];
        const end = sortedWaypoints[i + 1];
        const routeDetails = await fetchRouteDetails(apikey, start, end);
  
        if (routeDetails && routeDetails.routes[0] && routeDetails.routes[0].sections) {
          allSections.push(...routeDetails.routes[0].sections);
        }
      }
      // Once all sections are collected, display them on the map
      const reorderList = await reorderOrderList(routeOrders, allSections);
      if (reorderList) {
        routeResponseHandler(map, allSections, reorderList);
      }
    }
  }

  async function reorderOrderList(orderList, sections) {
    const locationToOrder = orderList.reduce((acc, order) => {
      const latLngKey = `${parseFloat(order.order.latitude).toFixed(5)},${parseFloat(order.order.longitude).toFixed(5)}`;
      if (!acc[latLngKey]) {
        acc[latLngKey] = [];
      }
      acc[latLngKey].push(order);
      return acc;
    }, {});

    const newOrderList = [];
    const processedOrders = new Set();
    let time = 0.0
    let distance = 0.0
    sections.forEach((section, index) => {
      const arrivalLocation = section.arrival.place.originalLocation;
      const duration = section.summary.baseDuration;
      const sectionDistance = section.summary.length;
      const durationMinutes = (parseInt(duration) / 60) + 5;
      const distanceMiles = parseInt(sectionDistance) / 1609.34;
      const latLngKey = `${arrivalLocation.lat.toFixed(5)},${arrivalLocation.lng.toFixed(5)}`;
      time += durationMinutes;
      distance += distanceMiles;
      if (latLngKey in locationToOrder) {
        locationToOrder[latLngKey].forEach((order, i) => {
          if (!processedOrders.has(order)) {
            processedOrders.add(order);
            order.number = index + i + 1;
            order.duration = durationMinutes;
            order.distance = distanceMiles;
            order.polyline = section.polyline; // Placeholder for the polyline URL
            newOrderList.push(order);
          }
        })
      }

    })


    setEstimatedTime(time)
    setDistanceTotal(distance)
    setRouteOrders(newOrderList);
    setAllRouteOrders(newOrderList);
    setReOrdering(true);
    setLoading(false)
    return newOrderList
  };

  async function findBestRouteSequence(apikey, start, waypoints, end, optimizeFor) {
    const baseUrl = "https://wps.hereapi.com/v8/findsequence2";
    const departureTime = encodeURIComponent("2021-10-15T10:30:00+05:00"); // Correctly encoding the time string
    
    let url = `${baseUrl}?mode=fastest;car;traffic:enabled&start=${start.lat},${start.lng}&apikey=${apikey}&departure=${departureTime}&improveFor=${optimizeFor}`;

    if (selectedOption === "startToEnd") {
      url += `&end=${start.lat},${start.lng}`;
    }
    waypoints.forEach((point, index) => {
      url += `&destination${index + 1}=${point.order.latitude},${point.order.longitude}`;
    });

    try {
      const copyUrl = `https://wps.hereapi.com/v8/findsequence2?mode=fastest;car;traffic:enabled&start=40.5967467,-73.7387068&apikey=6CgVwXbgNzlj3uCnA38M0Du16b2o_0DKBOLGYyc1SH8&departure=2024-06-03T01:25:08-0400&improveFor=TIME&destination1=40.85271,-73.90732&destination2=40.85371,-73.90545&destination3=40.85362,-73.90502&destination4=40.85269,-73.90503`
      const response = await fetch(url);
      const data = await response.json();
      return data.results;
    } catch (error) {
      console.error('Error finding best route sequence:', error);
    }
  }

  async function fetchRouteDetails(apikey, origin, destination) {
    const baseUrl = 'https://router.hereapi.com/v8/routes';
    const url = `${baseUrl}?apikey=${apikey}&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&routingMode=fast&transportMode=car&return=polyline,summary`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching route details:', error);
    }
  }

  async function routeResponseHandler(map, response, reorderList) {
    const sections = response;
    if (reorderList === null) {
      async function fetchPolyline(url) {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Failed to fetch polyline');
        const data = await response.text();
        return H.geo.LineString.fromFlexiblePolyline(data);
      }

      // Map over sections and convert polylines
      const promises = sections.map(section => {
        return fetchPolyline(section.polyline);
      });

      const lineStrings = await Promise.all(promises);
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const bounds = multiLineString.getBoundingBox();
      const routePolyline = new H.map.Polyline(multiLineString, { style: { lineWidth: 5 } });
      map.removeObjects(map.getObjects());
      map.addObject(routePolyline);

      const startMarker = createMarker({ order: { latitude: routes?.latitude, longitude: routes?.longitude, route: routes } }, '#0b6699', "S", true);
      // const endMarker = createMarker(restaurantList[restaurantList.length - 1], 'green');

      let lastWaypoint = null;
      let lastIndex = 0;
      let indices = [];

      response?.forEach((waypoint, i) => {
        const currentLatLng = `${parseFloat(waypoint.order.latitude).toFixed(5)},${parseFloat(waypoint.order.longitude).toFixed(5)}`;

        if (lastWaypoint && currentLatLng === lastWaypoint) {
          indices.push(i + 1);
        } else {
          if (indices.length) {
            const markerLabel = indices.join(',');
            const waypointMarker = createMarker(response[lastIndex], '#FF7733', markerLabel, false, indices.length);
            map.addObject(waypointMarker);
          }
          indices = [i + 1];
          lastIndex = i;
        }
        lastWaypoint = currentLatLng;
      });

      if (indices.length) {
        const markerLabel = indices.join(',');
        const waypointMarker = createMarker(response[lastIndex], '#FF7733', markerLabel, false, indices.length);
        map.addObject(waypointMarker);
      }
      map.addObjects([startMarker]);
      map.getViewModel().setLookAtData({ bounds });
    } else {
      const lineStrings = sections.map(section => H.geo.LineString.fromFlexiblePolyline(section.polyline));
      const multiLineString = new H.geo.MultiLineString(lineStrings);
      const bounds = multiLineString.getBoundingBox();

      const routePolyline = new H.map.Polyline(multiLineString, { style: { lineWidth: 5 } });
      map.removeObjects(map.getObjects());
      map.addObject(routePolyline);

      const startMarker = createMarker({ order: { latitude: routes?.latitude, longitude: routes?.longitude }, route: routes }, '#0b6699', "S", true);
      // const endMarker = createMarker(restaurantList[restaurantList.length - 1], 'green');
      let lastWaypoint = null;
      let lastIndex = 0;
      let indices = [];

      reorderList?.forEach((waypoint, i) => {
        const currentLatLng = `${parseFloat(waypoint.order.latitude).toFixed(5)},${parseFloat(waypoint.order.longitude).toFixed(5)}`;

        if (lastWaypoint && currentLatLng === lastWaypoint) {
          indices.push(i + 1);
        } else {
          if (indices.length) {
            console.log(indices.length)
            const markerLabel = indices.join(',');
            const waypointMarker = createMarker(reorderList[lastIndex], '#FF7733', markerLabel, false, indices.length);
            map.addObject(waypointMarker);
          }
          indices = [i + 1];
          lastIndex = i;
        }
        lastWaypoint = currentLatLng;
      });

      if (indices.length) {
        const markerLabel = indices.join(',');
        const waypointMarker = createMarker(reorderList[lastIndex], '#FF7733', markerLabel, false, indices.length);
        map.addObject(waypointMarker);
      }
      map.addObjects([startMarker]);
      map.getViewModel().setLookAtData({ bounds });
    }
  }

  function createMarker(coords, color, number, home, count = 1) {
    if (!home) {
      if (coords?.status === 'failed') {
        color = 'red';
      } else if (coords?.status === 'delivered') {
        color = 'green';
      } else {
        color = '#FF7733'
      }
    }
    const icon = getMarkerIcon(color, number);
    const marker = new H.map.Marker({ lat: coords.order.latitude, lng: coords.order.longitude }, { icon });
    const infoContent = `
    <div style="width: 400px; font-size: 12px;">
      ${home ? (
        `<div>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${routes?.address}
          </p>
          ${routes?.routeStatus === "In Progress" ? (
          `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(routes?.startTime, 0.0)}
            </p>`
        ) : (
          `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              Not Started Yet
            </p>`
        )}
        </div>`
      ) : (
        `<div>
          <p>
            <span style="font-weight: 700;">Order Id: </span>
            ${coords.order.slug}
          </p>
          <p>
            <span style="font-weight: 700;">Name: </span>
            ${coords.order.name}
          </p>
          <p>
            <span style="font-weight: 700;">Address: </span>
            ${coords.order.address}
          </p>
          ${routes?.routeStatus === "In Progress" ? (
          `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${modifyDateTime(routes?.startTime, coords.estimatedTimeCal)}
            </p>`
        ) : routes?.routeStatus === "Done" ? (
          `<div>
              <p>
                <span style="font-weight: 700;">
                  Planned Arrival Time: 
                </span>
                ${modifyDateTime(routes?.startTime, coords.estimatedTimeCal)}
              </p>
              <p>
                <span style="font-weight: 700;">
                  Delivery Time: 
                </span>
                ${moment(coords.order.deliverAt).format("hh:mm A")}
              </p>
            </div>`
        ) : (
          `<p>
              <span style="font-weight: 700;">
                Planned Arrival Time: 
              </span>
              ${floatToTime(coords.estimatedTimeCal)} (after start)
            </p>`
        )}
        </div>`
      )}
    </div>
    `;

    if (currentBubble) {
      ui.current.removeBubble(currentBubble);
      setCurrentBubble(null);
    }

    marker.addEventListener('tap', () => showInfoBubble(marker, infoContent));

    const markers = [];
    for (let i = 0; i < count; i++) {
      markers.push(marker)
    }

    setMarkers(prevMarkers => [...prevMarkers, ...markers]);
    return marker;
  }

  function getMarkerIcon(color, number) {
    return new H.map.Icon(
      `<svg width="30" height="20" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="30" height="20" fill="${color}" stroke="${color}" stroke-width="2" />
        <path d="M189.5 350L111.441 257.726L267.559 257.726L189.5 350Z" fill="${color}"/>
        <text x="50%" y="50%" font-family="Arial" font-size="10" font-weight="bold" text-anchor="middle" alignment-baseline="middle" fill="white">${number}</text>
      </svg>`, { anchor: { x: 15, y: 15 } }
    );
  }

  function showInfoBubble(marker, text) {
    const bubble = new H.ui.InfoBubble(marker.getGeometry(), { content: text });
    ui.current.addBubble(bubble);
    setCurrentBubble(bubble);
  }

  const GOOGLE_MAPS_API_KEY = "AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ";

  const options = {
    types: ["address"],
    componentRestrictions: { country: "us" },
    bounds: {
      north: 42.0505,  // Northernmost point roughly in CT
      south: 38.9285,  // Southernmost point roughly in NJ
      west: -79.7624,  // Westernmost point roughly in NY
      east: -71.1851   // Easternmost point roughly in CT
    },
    strictBounds: true,
  };

  const handlePlaceSelect = (place) => {
    //   setFormState({
    //     ...formState,
    //     address: place.formatted_address,
    //   });
    //   setAddress(place.formatted_address);

    setFormState((prevOrderDetail) => ({
      ...prevOrderDetail,
      address: place.title
    }));
    setAddress(place.title)
    setResults([]);
  };

  const handleRouteAddressModalOpen = async (id, address) => {
    setModalOpen(true);
    setFormState({
      ...formState,
      address: routes?.address,
    });
    setAddress(routes?.address);
  };

  const handleRouteAddressModalClose = () => {
    setModalOpen(false);
    getRouteDetail();
  };

  const handleImageModalOpen = () => {
    setDeliveryImages(route?.order?.ordermaster_delivery?.map(item => item.delivery_proof_image) || []);
    setOpenImageModal(true);
  };
  const handleImageModalClose = () => {
    setOpenImageModal(false);
    getRouteDetail();
  };

  const handleRouteOrderingModalOpen = async (number, id) => {
    setOrderModalOpen(true);
    setOrder(number);
    setRouteOrderId(id);
  };
  const handleRouteOrderingModalClose = () => {
    setOrderModalOpen(false);
    setOrder(0);
    setRouteOrderId(0);
    getRouteDetail();
  };

  const handleSaveRouteAddress = async () => {
    setLoading(true);
    const response = await patchApiWithAuth(
      ALL_ROUTES + `${routes?.id}/update_address/`,
      formState
    );
    if (response.status !== 400) {
      toast.success("Route Address Updated Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setModalOpen(false);
    setFormState({
      address: "",
    });
    getRouteDetail();
  };
  const handleFailOrder = async (order) => {
    try {
      setLoading(true);
      console.log("ordeeereeeeeeeeeeeeee",order)
      const response = await postApiWithAuth(ORDER_FAILED + `${order?.order?.id}`);
      
      if (response.status !== 400) {
        toast.success("Order Updated Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Backend Error!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("An error occurred!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };
  

  const handleSaveDeliveryNote = async () => {
    setLoading(true);
    const data = { 'deliveryNote': deliveryNote }
    const response = await patchApiWithAuth(
      CREATE_ORDER + `/${routeOrderId}`,
      data
    );
    if (response.status !== 400) {
      toast.success("Delivery Note Updated Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setModalDeliveryNote(false);
    setDeliveryNote("");
    getRouteDetail();
  };

  const handleSaveRouteOrdering = async () => {
    setLoading(true);
    const formData = {
      number: order,
    };
    const response = await patchApiWithAuth(
      ROUTE_DETAIL + `${routeOrderId}/update_order_number/`,
      formData
    );
    if (response.status !== 400) {
      toast.success("Route Address Updated Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error("Backend Error!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setOrderModalOpen(false);
    getRouteDetail();
  };

  const handleOpenMenu = (event, order) => {
    setAnchorEl(event.currentTarget);
    setRoute(order);
  };

  const handleUpdateDeliveryNote = (id, note) => {
    setRouteOrderId(id);
    setDeliveryNote(note)
    setModalDeliveryNote(true)
  }

  const handleDeliveryNoteClose = () => {
    setModalDeliveryNote(false);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleHereSearch = async (event) => {
    const { value } = event.target;
    setAddress(value);
    if (value.length > 2) { // To limit requests, only search if query length > 2
      const endpoint = `https://autosuggest.search.hereapi.com/v1/autosuggest?at=40.8301933,-73.8521865&in=countryCode:USA&lang=en-US&limit=5&q=${value}&apikey=${apikey}`;

      try {
        const response = await axios.get(endpoint);
        setResults(response.data.items || []);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setResults([]);
      }
    } else {
      setResults([]);
    }
  };

  return (
    <div>
      {loading && <CustomLoader />}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className="routesAction" style={{ alignItems: 'center' }}>
            <div className="search-container" style={{ color: 'black' }}>
              <label htmlFor="search">Search:</label>
              <input
                type="text"
                id="search"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Order ID"
                className="search-input"
                autoComplete="off"
                style={{ paddingLeft: "5px" }}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <p style={{ marginRight: '10px' }}>Stops: {routeOrders?.length}</p>
              <p style={{ marginRight: '10px' }}>Miles: {(parseFloat(distanceTotal) * 0.621371).toFixed(2)} mi</p>
              <p>ETC: {floatToTime(estimatedTime)}</p>
            </div>
            <Link to={`/update/route/${rid}`} className="routeEditLink">
              Edit Route
            </Link>
          </div>
          <div className="routesOrderList">
            <div className="routePharmacy">
              <div
                style={{
                  position: "absolute",
                  top: "-10px", // Adjust the top position as needed
                  left: "-10px", // Adjust the left position as needed
                  width: "35px",
                  height: "35px",
                  backgroundColor: "#0B6699",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                <i className="fa fa-home" />
              </div>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <h4>{routes?.detail_name}</h4>
                  <div
                    className="route-order-address"
                    style={{ minWidth: "100%" }}
                  >
                    <img
                      className="route-detail-in-icon"
                      src={buildings}
                      alt="Logo"
                    />
                    <div className="route-addres-div">
                      <h3 className="route-pharmacy-addres-text">
                        {routes?.address}
                      </h3>
                      {/* <div className="address-div2">
                        <h3 className="apt-text">
                          Copay: <span className="copay-in">${order.order.totalcopay}</span>
                        </h3>
                      </div>
                      <div className="address-div2">
                        <p className="collect-text">Collected</p>
                      </div> */}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <div>
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={handleRouteAddressModalOpen}
                    >
                      Edit
                    </h3>
                  </div>
                </Grid>
              </Grid>
            </div>
            {allRouteOrders !== undefined &&
              allRouteOrders.length > 0 &&
              allRouteOrders.map((order, i) =>
                copay && copay === "note" ? (
                  order.order.notes !== null &&
                  order.order.notes !== "" && (
                    <div key={i}
                      className={`routeOrderObject hover:bg-slate-300 ${selectedOrder === order.id ? 'bg-slate-300' : ''
                        }`} onClick={() => handleOrderSelect(order, i + 1)}>
                      <div
                        style={{
                          position: "absolute",
                          top: "0px", // Adjust the top position as needed
                          left: "0px", // Adjust the left position as needed
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#0B6699",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {order.number}
                      </div>
                      {order.status !== "delivered" && <div
                        style={{
                          position: "absolute",
                          top: "0px", // Adjust the top position as needed
                          right: "-2px", // Adjust the left position as needed
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontWeight: "bold",
                          cursor: 'pointer'
                        }}
                      >
                        <ModeEditIcon
                          color="primary"
                          onClick={() =>
                            handleRouteOrderingModalOpen(
                              order.number,
                              order.id
                            )
                          }
                        />
                      </div>}
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <div className="route-order-main">
                            <div>
                              <h4>{order.order.pharmacy_name}</h4>
                              <a
                                href={order.order.status === "5" || order.order.status === "6"
                                  ? `/detail/order/${order.order.id}`
                                  : `/update/order/${order.order.id}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <h2 className="route-order-id">
                                  Order ID - {order.order.slug}  {order.status === "delivered" ? <CheckCircleIcon style={{ color: "green", fontSize: "18px" }} /> : order.status === "failed" && <CancelIcon style={{ color: 'red', fontSize: "18px" }} />}
                                </h2>
                              </a>
                              <h3 className="cust-name">
                                <img
                                  className="route-detail-in-icon"
                                  src={userBlack}
                                  alt="new Icon"
                                />{" "}
                                {order.order.name}
                              </h3>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <img
                                  className="route-detail-in-icon"
                                  src={cellphone}
                                  alt="new Icon"
                                />
                                {order.order.totalcopay !== 0 && (
                                  <div
                                    className="routeOrderTotalCopay"
                                    style={{ width: "100%" }}
                                  >
                                    <p>Copay: </p>
                                    <p>$ {order.order.totalcopay.toFixed(2)}</p>
                                  </div>
                                )}
                              </div>
                              {order.order.refrigrated && (
                                <div
                                  className="route-urgent-tag refri"
                                  style={{ fontSize: 13, padding: "0px 22px" }}
                                >
                                  Refrigerated
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="route-order-customer">
                            <div className="cust-div">
                              <div className="cust-detail">
                                <div className="detail-div">
                                  <img
                                    className="detail-in-icon"
                                    src={cellphone}
                                    alt="Logo"
                                  />
                                  <h3 className="detail-in-text">
                                    {order.order.contact}
                                  </h3>
                                </div>
                                <div className="detail-div">
                                  <img
                                    className="detail-in-icon"
                                    src={iphone}
                                    alt="Logo"
                                  />
                                  <h3 className="detail-in-text">
                                    {order.order.cell}
                                  </h3>
                                </div>
                                <div className="detail-div">
                                  <img
                                    className="detail-in-icon"
                                    src={landline}
                                    alt="Logo"
                                  />
                                  <h3 className="detail-in-text">
                                    {order.order.workNumber}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="route-order-address"
                            style={{ minWidth: "100%" }}
                          >
                            <img
                              className="route-detail-in-icon"
                              src={buildings}
                              alt="Logo"
                            />
                            <div className="addres-div">
                              <h3
                                className="addres-text"
                                style={{ display: "flex" }}
                              >
                                {order.order.address}
                              </h3>
                              {/* <h3 className="apt-text" style={{textAlign: 'right'}}>
                        Apt #: <span className="apt-in">{order.order.apart}</span>
                      </h3> */}
                              {/* <div className="address-div2">
                          <h3 className="apt-text">
                            Copay: <span className="copay-in">${order.order.totalcopay}</span>
                          </h3>
                        </div>
                        <div className="address-div2">
                          <p className="collect-text">Collected</p>
                        </div> */}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="route-note-div" onClick={() => handleUpdateDeliveryNote(order.order.id, order.order.notes)}>
                            <h3 className="route-note-head">Delivery Note</h3>
                            <p className="route-note-text">
                              {order.order.notes}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )
                ) : (
                  <div key={i}
                    className={`routeOrderObject hover:bg-slate-300 ${selectedOrder === order.id ? 'bg-slate-300' : ''
                      }`} onClick={() => handleOrderSelect(order, i + 1)}>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px", // Adjust the top position as needed
                        left: "0px", // Adjust the left position as needed
                        width: "25px",
                        height: "25px",
                        backgroundColor: "#0B6699",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {order.number}
                    </div>
                    {order.status !== "delivered" && <div
                      style={{
                        position: "absolute",
                        top: "0px", // Adjust the top position as needed
                        right: "-2px", // Adjust the left position as needed
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                        cursor: 'pointer'
                      }}
                    >
                      <ModeEditIcon
                        color="primary"
                        onClick={() =>
                          handleRouteOrderingModalOpen(
                            order.number,
                            order.id
                          )
                        }
                      />
                    </div>}
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div className="route-order-main">
                          <div>
                            <h4>{order.order.pharmacy_name}</h4>
                            <a
                              href={order.order.status === "5" || order.order.status === "6"
                                ? `/detail/order/${order.order.id}`
                                : `/update/order/${order.order.id}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h2 className="route-order-id">
                                Order ID - {order.order.slug}  {order.status === "delivered" ? <CheckCircleIcon style={{ color: "green", fontSize: "18px" }} /> : order.status === "failed" && <CancelIcon style={{ color: 'red', fontSize: "18px" }} />}
                              </h2>
                            </a>
                            <h3 className="cust-name">
                              <img
                                className="route-detail-in-icon"
                                src={userBlack}
                                alt="new Icon"
                              />{" "}
                              {order.order.name}
                            </h3>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                              {order.order.totalcopay !== 0 && (
                                <div
                                  className="routeOrderTotalCopay"
                                  style={{ width: "100%" }}
                                >
                                  <p>Copay: </p>
                                  <p>$ {order.order.totalcopay.toFixed(2)}</p>
                                </div>
                              )}
                              <img
                                className="route-detail-in-icon"
                                src={info}
                                alt="new Icon"
                                style={{ marginLeft: "10px" }}
                                onClick={(e) => handleOpenMenu(e, order)}
                              />
                              <img
                                className="route-detail-in-icon"
                                src={refreshimg}
                                alt="new Icon"
                                style={{ marginLeft: "10px" }}
                                onClick={(e) => handleFailOrder(order)}
                              />
                            </div>
                            {order.order.refrigrated && (
                              <div
                                className="route-urgent-tag refri"
                                style={{ fontSize: 13, padding: "0px 22px" }}
                              >
                                Refrigerated
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="route-order-customer">
                          <div className="cust-div">
                            <div className="cust-detail">
                              <div className="detail-div">
                                <img
                                  className="detail-in-icon"
                                  src={cellphone}
                                  alt="Logo"
                                />
                                <h3 className="detail-in-text">
                                  {order.order.contact}
                                </h3>
                              </div>
                              <div className="detail-div">
                                <img
                                  className="detail-in-icon"
                                  src={iphone}
                                  alt="Logo"
                                />
                                <h3 className="detail-in-text">
                                  {order.order.cell}
                                </h3>
                              </div>
                              <div className="detail-div">
                                <img
                                  className="detail-in-icon"
                                  src={landline}
                                  alt="Logo"
                                />
                                <h3 className="detail-in-text">
                                  {order.order.workNumber}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="route-order-address"
                          style={{ minWidth: "100%" }}
                        >
                          <img
                            className="route-detail-in-icon"
                            src={buildings}
                            alt="Logo"
                          />
                          <div className="addres-div">
                            <h3
                              className="addres-text"
                              style={{ display: "flex" }}
                            >
                              {order.order.address}
                            </h3>
                            {/* <h3 className="apt-text" style={{textAlign: 'right'}}>
                        Apt #: <span className="apt-in">{order.order.apart}</span>
                      </h3> */}
                            {/* <div className="address-div2">
                          <h3 className="apt-text">
                            Copay: <span className="copay-in">${order.order.totalcopay}</span>
                          </h3>
                        </div>
                        <div className="address-div2">
                          <p className="collect-text">Collected</p>
                        </div> */}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="route-note-div" onClick={() => handleUpdateDeliveryNote(order.order.id, order.order.notes)}>
                          <h3 className="route-note-head">Delivery Note</h3>
                          <p className="route-note-text">{order.order.notes}</p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )
              )}
          </div>
        </Grid>
        <Grid item xs={8}>
          <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", marginBottom: "5px" }}>
            <div>
              <label style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  value="LiveMap"
                  checked={mapType === 'LiveMap'}
                  onChange={handleMapTypeChange}
                />
                Live Request
              </label>
              <label>
                <input
                  type="radio"
                  value="StorePolyline"
                  checked={mapType === 'StorePolyline'}
                  onChange={handleMapTypeChange}
                />
                Stored Map
              </label>
            </div>
            <div>
              <label style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  disabled={mapType === 'StorePolyline'}
                  value="TIME"
                  checked={optimizeFor === 'TIME'}
                  onChange={handleOptimizeForChange}
                />
                Optimize by Time
              </label>
              <label>
                <input
                  type="radio"
                  disabled={mapType === 'StorePolyline'}
                  value="DISTANCE"
                  checked={optimizeFor === 'DISTANCE'}
                  onChange={handleOptimizeForChange}
                />
                Optimize by Mile
              </label>
            </div>
            {mapType === 'StorePolyline' ? null : (<div>
              <label style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  disabled={mapType === 'StorePolyline'}
                  value="ascending"
                  checked={sortOrder === 'ascending'}
                  onChange={handleSortChange}
                />
                Ascending
              </label>
              <label>
                <input
                  type="radio"
                  disabled={mapType === 'StorePolyline'}
                  value="descending"
                  checked={sortOrder === 'descending'}
                  onChange={handleSortChange}
                />
                Descending
              </label>
            </div>
            )}
            {mapType === 'StorePolyline' ? null : (
              <Button variant="contained" color="secondary" onClick={handleOptimizedRoute} size="small">Save</Button>
            )}
          </div>
          <div style={{ width: "100%", height: "85vh" }} ref={mapRef} />
        </Grid>
      </Grid>
      <div className="RouteMapToggles">
        <br />
        <label className="label">
          Start & End At Same Location
          <input
            type="checkbox"
            disabled={mapType === 'StorePolyline'}
            checked={selectedOption === "startToEnd"}
            autoComplete="off"
            style={{ marginRight: "15px" }}
            onChange={() => handleCheckboxChange("startToEnd")}
          />
        </label>
        <br />
        <label className="label">
          End At Any Location
          <input
            type="checkbox"
            disabled={mapType === 'StorePolyline'}
            checked={selectedOption === "endAnywhere"}
            autoComplete="off"
            style={{ marginRight: "15px" }}
            onChange={() => handleCheckboxChange("endAnywhere")}
          />
        </label>
        {/* <br />
        <label className="label">
          End At Specific Stop (Choose Stop #)
          <input
            type="checkbox"
            disabled={mapType === 'StorePolyline'}
            checked={selectedOption === "endSpecific"}
            autoComplete="off"
            style={{ marginRight: "15px" }}
            onChange={() => handleCheckboxChange("endSpecific")}
          />
        </label> */}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'black',
            color: 'white',
            padding: '0 10px',
            minWidth: '300px'
          },
        }}
      >
        <h2 style={{ textDecoration: "underline", fontWeight: "700" }}>Detail</h2>
        <h2 style={{ textDecoration: "underline", fontWeight: "400" }}>Order ID: {route?.order?.slug}</h2>
        <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
          <h2>ETA</h2>
          <h2>{
            routes?.startTime !== null ?
              modifyDateTime(
                routes?.startTime,
                route?.estimatedTimeCal
              )
              :
              `${floatToTime(route?.estimatedTimeCal)} after start`
          }
          </h2>
        </div>
        <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
          <h2>Distance</h2>
          <h2>{(parseFloat(route?.distanceCal)).toFixed(2)} mi</h2>
        </div>
        <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
          <h2>Started At</h2>
          <h2>{routes?.startTime !== null ? moment(routes?.startTime).format('hh:mm A') : "Not Started"}</h2>
        </div>
        <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
          <h2>Geo Location</h2>
          <h2>{parseFloat(route?.order?.latitude).toFixed(7)}, {parseFloat(route?.order?.longitude).toFixed(7)}</h2>
        </div>
        <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
          <h2>{route?.status === 'failed' ? "Failed" : "Delivered At"}</h2>
          <h2>{route?.status === 'delivered' ? moment(route?.order?.deliverAt).format('hh:mm A') : route?.status === 'failed' ? moment(route?.updated_at).format('hh:mm A') : "Not Delivered"}</h2>
        </div>
        {route?.status === "delivered" &&
          <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>

            {route?.order?.driver_name !== "" ?
              <>
                <h2>Copay Collected By: </h2>
                <h2>{route?.order?.driver_name}</h2>
              </> : route?.driverComment !== "" &&
              <>
                <h2>Copay Collected By: </h2>
                <h2>{route?.driverComment}</h2>
              </>
            }
            {route?.order?.receiverName !== "" ?
              <>
                <h2>Copay Received By: </h2>
                <h2>{route?.order?.receiverName}</h2>
              </> : route?.receiverName !== "" &&
              <>
                <h2>Copay Received By: </h2>
                <h2>{route?.receiverName}</h2>
              </>}
            {route?.order?.waivedBy !== "" ?
              <>
                <h2>Copay Waived By: </h2>
                <h2>{route?.order?.waivedBy}</h2>
              </> : route?.waivedBy !== "" &&
              <>
                <h2>Copay Waived By: </h2>
                <h2>{route?.waivedBy}</h2>
              </>}
          </div>}
        {route?.status === 'delivered' &&
          <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
            <h2>Signature</h2>
            <h2>{route?.eSignature ? "Done" : "False"}</h2>
          </div>
        }
        <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }} onClick={handleImageModalOpen}>
          <h2>Delivery Images</h2>
          <h2>{route?.order?.ordermaster_delivery?.length}</h2>
        </div>
        {route?.order?.status !== "5" && route?.order?.failed?.length > 0 && (
          <div style={{ fontWeight: "500", display: 'flex', justifyContent: "space-between" }}>
            <h2>Failed reason</h2>
            <h2>{route.order.failed[route.order.failed.length - 1]?.comment}</h2>
          </div>
        )}


      </Menu>
      <Dialog open={modalOpen} onClose={handleRouteAddressModalClose}>
        <DialogTitle>Update Route Address</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Address</label>
          {/* <input
            type="text"
            className="modal-field"
            name="address"
            placeholder="Address"
            style={{marginBottom: '10px'}}
            value={formState.address}
            onChange={handleInputChange}
          /> */}
          {/* <Autocomplete
            style={{ zIndex: 1500 }}
            apiKey={GOOGLE_MAPS_API_KEY}
            onPlaceSelected={handlePlaceSelect}
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                type="text"
                name="address"
                id="address"
                className="order-field"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            )}
            // style={{
            //   border: '0px',
            //   width: '100%',
            //   borderRadius: '5px',
            //   font: 'inherit',
            //   letterSpacing: 'inherit',
            //   color: 'currentColor',
            //   boxSizing: 'content-box',
            //   background: 'none',
            //   height: '1.4375em',
            //   margin: 0,
            //   display: 'block',
            //   minWidth: 0,
            //   animationName: 'mui-auto-fill-cancel',
            //   animationDuration: '10ms',
            //   padding: '10.5px 14px 10.5px 12px'
            // }}
            className="order-field"
            inputProps={{
              style: {
                "&:hover": {
                  borderColor: "#69c0ff",
                },
                "&:active": {
                  borderColor: "#69c0ff",
                },
                "&:focus": {
                  borderColor: "#69c0ff",
                },
              },
            }}
            options={options}
          /> */}
          <div>
            <input type="text" className="order-field" value={address} onChange={handleHereSearch} placeholder="Address" />
            {results.length > 0 &&
              <ul className="addressList">
                {results.map((item, index) => (
                  <li key={index} style={{ borderBottom: "1px solid grey", cursor: 'pointer' }} onClick={() => handlePlaceSelect(item)}>
                    {item.title}
                  </li>
                ))}
              </ul>
            }
          </div>
          <Button
            onClick={handleSaveRouteAddress}
            variant="contained"
            style={{ marginRight: "10px" }}
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={handleRouteAddressModalClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={orderModalOpen} onClose={handleRouteOrderingModalClose}>
        <DialogTitle>Update Route Ordering</DialogTitle>
        <DialogContent>
          <label className="pharmacy-field-label">Order</label>
          <select
            className="modal-field"
            name="number"
            placeholder="Order"
            style={{ marginBottom: "10px" }}
            value={order}
            onChange={(e) => setOrder(e.target.value)}
          >
            <option value="">Select Order</option>
            {/* Create options based on the order length */}
            {Array.from({ length: routeOrders.length }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
          <Button
            onClick={handleSaveRouteOrdering}
            variant="contained"
            style={{ marginRight: "10px" }}
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={handleRouteOrderingModalClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={modalDeliveryNote}
        onClose={handleDeliveryNoteClose}
        minWidth='xl'
        style={{ minWidth: '500px' }}
      >
        <DialogTitle sx={{ backgroundColor: '#D9D9D9', color: '#0455A6', fontWeight: '800', padding: 1 }}>Delivery Notes</DialogTitle>
        <DialogContent sx={{ backgroundColor: '#D9D9D9', padding: 1 }} >
          <textarea
            type="text"
            className="modal-field notes-field"
            name="delivery_note"
            autoFocus
            placeholder="Write your delivery note here!!!"
            value={deliveryNote}
            onChange={(e) => setDeliveryNote(e.target.value)}
            rows={4}
          />
          <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} gap='20px' mt='20px'>
            <Button
              onClick={handleDeliveryNoteClose}
              variant="outlined"
              color="inherit"
              sx={{ backgroundColor: 'white' }}
            >
              Close
            </Button>
            <Button
              onClick={handleSaveDeliveryNote}
              variant="outlined"
              color="inherit"
              sx={{ backgroundColor: 'white' }}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={openImageModal} onClose={handleImageModalClose} maxWidth="xl" fullWidth>
        <DialogTitle>Delivery Images</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {deliveryImages.map((image, index) => (
              <div key={index} style={{ width: '33%', padding: '5px' }}>
                <img
                  src={image}
                  alt={`delivery_image_${index}`}
                  style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                />
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RouteHereDetail;
