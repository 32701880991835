import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "react-google-autocomplete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import QRCode from "qrcode.react";
import PrintIcon from "@mui/icons-material/Print";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { useReactToPrint } from "react-to-print";
import {
  Avatar,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from "@material-ui/core";
import { BiCheckDouble } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Printdoc from "../../CommonComponents/PrintDoc/PrintDoc";
import PrintDocDown from "../../CommonComponents/PrintDoc/PrintDocDown";
import jsPDF from "jspdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import { pdfjs } from 'react-pdf';
import PDFfile from "../../../../src/assests/samplepdf.pdf";

import axios from "axios";
import moment from "moment";

import {
  CREATE_ORDER,
  ALL_PHARMACY_LIST
} from "../../../utils/apiUrls";
import {
  putApiWithAuth,
  getApiWithAuth,
  patchApiWithAuth,
} from "../../../utils/api";

import {
  landline,
  iphone,
  route_report,
  pharmacyCallBlack,
  pdfLogo,
  registered,
  photoId,
  buildings,
} from "../../../assests";

import "./editOrder.css";
import { Checkbox } from "@mui/material";
import ShowPDF from "../../CommonComponents/ShowPDF/ShowPDF";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  chatContainer: {
    flex: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  message: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  inputContainer: {
    background: "#E2E2E2",
    display: "flex",
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
}));

const GOOGLE_MAPS_API_KEY = "AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ";

function EditOrder() {
  const { pid, oid } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const createDate = new Date();

  const categorizeImagesByAttempt = (images) => {
    const categorized = {};

    images.forEach((image) => {
      const key = image.attempt || "null";
      if (!categorized[key]) {
        categorized[key] = [];
      }
      categorized[key].push(image);
    });

    return categorized;
  };

  const [orderDetail, setOrderDetail] = useState({
    createdAt: "",
    deliveryDate: new Date(createDate.getTime() + 24 * 60 * 60 * 1000),
    timeWindow: "Anytime",
    name: "",
    language: "english",
    address: "",
    apart: "",
    phoneNumber: "",
    cellNumber: "",
    workNumber: "",
    zip: "",
    note: "",
    slug: "",
    totalCopay: 0,
    signRequired: false,
    reqPhotoId: false,
    registed: false,
    collectCopay: false,
    hippaForm: false,
    lineForm: false,
    nfForm: false,
    bussAddress: false,
    parcelPlace: "Face to face",
    lat: "",
    lng: "",
    deliverAt: "",
    requestCouncelling: false,
    status: "",
    driver: "",
    signature_img: "",
    active: false,
    delivery_imgs: [],
    orderticket: {},
    pharmacyId: "",
    order_file: "",
    rxList: [
      {
        id: "",
        copay: "",
        rxnumber: "",
        rfnumber: "",
        quantity: "",
        date: "",
        masterid: "",
        select: false,
      },
    ],
  });
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [selectedOrderFile, setSelectedOrderFile] = useState(null);
  const [streetViewUrl, setStreetViewUrl] = useState("");
  const [mapError, setMapError] = useState(false);
  const [pharmacyDetail, setPharmacyDetail] = useState({});
  const [activeTab, setActiveTab] = useState("Upload slip");
  const [modalCancelNoteOpen, setModalCancelNoteOpen] = useState(false);
  const [adminNote, setAdminNote] = useState("");
  const [address, setAddress] = useState("");
  const [results, setResults] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [addressError, setAddressError] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeFullscreen = () => {
    setSelectedImage(null);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleCreateCancelNote = () => {
    setModalCancelNoteOpen(true);
  };

  const handleModalCancelNoteClose = () => {
    setModalCancelNoteOpen(false);
  };

  useEffect(() => {
    getDetailOrder(oid);
  }, [oid]);

  useEffect(() => {
    const pharmacyId = localStorage.getItem("pharmacyId");
    if (pid !== undefined) {
      if (parseInt(pid) !== parseInt(pharmacyId)) {
        navigate("/404");
      }
    }
  }, [pid]);

  const getPharmacyDetail = async (id) => {
    let response
    if (pid !== undefined) {
      response = await getApiWithAuth(ALL_PHARMACY_LIST + `/${pid}`);
    } else {
      response = await getApiWithAuth(ALL_PHARMACY_LIST + `/${id}`);
    }
    if (response.data !== undefined) {
      setPharmacyDetail(response.data.data);
    } else {
      console.log(response.status);
    }
  };

  const options = {
    types: ["address"],
    componentRestrictions: { country: "us" },
    bounds: {
      north: 42.0505, // Northernmost point roughly in CT
      south: 38.9285, // Southernmost point roughly in NJ
      west: -79.7624, // Westernmost point roughly in NY
      east: -71.1851, // Easternmost point roughly in CT
    },
    strictBounds: true,
  };

  const handlePlaceSelect = (place) => {
    if (parseInt(pharmacyDetail.id) === 2) {
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        address: place.formatted_address
      }));
      setAddress(place.formatted_address)
    } else {
      setOrderDetail((prevOrderDetail) => ({
        ...prevOrderDetail,
        address: place.title
      }));
      setAddress(place.title)
    }

    setResults([]);
  };

  const getDetailOrder = async (id) => {
    try {
      const response = await getApiWithAuth(CREATE_ORDER + `/${oid}`);
      // dispatch(getPdfData(formData));
      console.log(response.data.data);
      setOrderDetail({
        name: response.data.data?.name || "",
        address: response.data.data?.address || "",
        phoneNumber: response.data.data?.contact || "",
        cellNumber: response.data.data?.cell || "",
        workNumber: response.data.data?.workNumber || "",
        zip: response.data.data?.zip || "",
        slug: response.data.data?.slug || "",
        timeWindow: response.data.data?.deliverydate,
        parcelPlace: response.data.data?.parcelPlace,
        signRequired: response.data.data?.signature,
        reqPhotoId: response.data.data?.photoid,
        registed: response.data.data?.refrigrated,
        collectCopay: response.data.data?.collectcopay,
        hippaForm: response.data.data?.hippaform,
        lineForm: response.data.data?.lineform,
        nfForm: response.data.data?.nfaqbform,
        bussAddress: response.data.data?.addresstype,
        deliveryDate: response.data.data?.deliverAt,
        createdAt: response.data.data?.createdAt,
        totalCopay: response.data.data?.totalcopay,
        language: response.data.data?.language,
        apart: response.data.data?.apart,
        note: response.data.data?.notes,
        lat: response.data.data?.latitude,
        lng: response.data.data?.longitude,
        lng: response.data.data?.longitude,
        status: response.data.data?.status,
        requestCouncelling: response.data.data?.requestCouncelling,
        signature_img:
          response.data.data?.ordermaster_route4me !== null
            ? response.data.data?.ordermaster_route4me[
                response.data.data?.ordermaster_route4me?.length - 1
              ]?.signature_image
            : undefined,
        delivery_imgs: response.data.data?.ordermaster_delivery,
        orderticket:
          response.data.data?.orderticket !== null
            ? response.data.data?.orderticket[
                response.data.data?.orderticket?.length - 1
              ]
            : undefined,
        pharmacyId: response.data.data?.pharmacy?.id,
        order_file: response.data.data?.order_file,
        driver: response.data.data?.driver?.name,
        deliverAt: response.data.data?.deliverAt,
        active: response.data.data?.isActive,
        daily_order_id: response.data.data?.daily_order_id,
        rxList: response.data.data?.orderdetail || [], // Store 'text' data in rxList
      });
      setAddress(response.data.data?.address || "");
      getPharmacyDetail(response.data.data?.pharmacy?.id);
    } catch (error) {
      console.log(error)
    }
  };

  const categorizedImages = categorizeImagesByAttempt(
    orderDetail?.delivery_imgs
  );

  const handleOrderChange = (event, index) => {
    const { name, value, type, checked } = event.target;
    console.log(value, type, name)

    let updatedValue = value;

    // If the input type is checkbox, update accordingly
    if (type === "checkbox") {
      updatedValue = checked;
    }

    if (name.includes(".")) {
      const [list, rxIndex, fieldName] = name.split(".");
      console.log(list, rxIndex, fieldName);
      const updatedRxList = [...orderDetail.rxList];
      updatedRxList[index][fieldName] = updatedValue;

      let totalCopay = 0;

      orderDetail.rxList.forEach((rx) => {
        if (rx.select) {
          totalCopay += parseFloat(rx.copay);
        }
      });

      setOrderDetail({
        ...orderDetail,
        rxList: updatedRxList,
        totalCopay: totalCopay,
      });
    } else if (name === 'collectCopay') {

      const updatedRxList = orderDetail.rxList.map((rx) => ({
        ...rx,
        select: checked,
      }));

      let totalCopay = 0;

      if (checked) {
        orderDetail.rxList.forEach((rx) => {
          totalCopay += parseFloat(rx.copay);
        });
      }

      setOrderDetail({
        ...orderDetail,
        [name]: updatedValue,
        rxList: updatedRxList,
        totalCopay: totalCopay,
      });
    } else if (name === "zip") {
      const trimmedValue = value.replace(/\s/g, '');

      // Check the length of the input without spaces, limit to 5 characters
      console.log(trimmedValue, trimmedValue.length)
      if (trimmedValue.length <= 5) {
        setOrderDetail(prevState => ({
          ...prevState,
          [name]: trimmedValue
        }));
      }
    } else {
      // It's a top-level field in orderDetail
      setOrderDetail({
        ...orderDetail,
        [name]: updatedValue,
      });
    }
  };

  const handleDeliveryDateChange = (date) => {
    // Convert the date to the desired format (MM/DD/YYYY)
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Update the orderDetail state with the formatted date
    setOrderDetail({
      ...orderDetail,
      deliveryDate: formattedDate,
    });
  };
  useEffect(() => {
    if (orderDetail.status === "5") {
      setActiveTab("Signed slip");
    }
  }, [orderDetail.status]);
  const handleApartmentChange = (event) => {
    const apartmentNumber = event.target.value;
    // const lastCommaIndex = orderDetail.address.lastIndexOf(',');
    // console.log(orderDetail.address)
    // const firstPart = orderDetail.address.substring(0, lastCommaIndex).trim();
    // const secondPart = orderDetail.address.substring(lastCommaIndex + 1).trim();
    // console.log(firstPart, secondPart)

    // let updatedAddress;
    // if (add1 !== '' || add2 !== '') {
    //   console.log('workkff')
    //   if (apartmentNumber === '') {
    //     updatedAddress = `${add1}, ${add2}`;
    //   } else {
    //     updatedAddress = `${add1} ${apartmentNumber}, ${add2}`;
    //   }
    // } else {
    //   updatedAddress = `${firstPart} ${apartmentNumber}, ${secondPart}`;
    //   setAdd1(firstPart);
    //   setAdd2(secondPart);
    // }
    // console.log(updatedAddress);
    // Update the apartment number in the resulting array and join it back into a string with the comma separator
    setOrderDetail({
      ...orderDetail,
      // address: updatedAddress,
      apart: apartmentNumber,
    });
  };

  const handleAddRxItem = () => {
    const newRxItem = {
      id: "",
      copay: "",
      rxnumber: "M - ",
      rfnumber: "0",
      quantity: "1",
      date: "",
      masterid: "",
      select: false,
    };

    setOrderDetail({
      ...orderDetail,
      rxList: [...orderDetail.rxList, newRxItem],
    });
  };

  const handleDeleteRxItem = (index) => {
    const updatedRxList = [...orderDetail.rxList];
    updatedRxList.splice(index, 1);

    setOrderDetail({
      ...orderDetail,
      rxList: updatedRxList,
    });
  };

  useEffect(() => {
    if ((orderDetail.address, orderDetail.lat, orderDetail.lng)) {
      const streetViewUrlApi = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${orderDetail.lat},${orderDetail.lng}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
      setStreetViewUrl(streetViewUrlApi);
    }
  }, [orderDetail.address, orderDetail.lat, orderDetail.lng]);

  useEffect(() => {
    if (orderDetail.address !== "") {
      const fetchLocation = async () => {
        try {
          if (parseInt(pharmacyDetail.id) === 2) {
            const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(orderDetail.address)}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
            const response = await axios.get(geocodingUrl);

            const { results } = response.data

            if (results.length > 0) {
              const { lat, lng } = results[0].geometry.location;
              const formattedLat = parseFloat(lat.toFixed(5));
              const formattedLng = parseFloat(lng.toFixed(5));
              const type = results[0].geometry.location_type;
              const postalComponent = results[0]?.address_components.find(component => component.types.includes('postal_code'));
              if (lat.toString().split('.')[1]?.length < 5 || lng.toString().split('.')[1]?.length < 5) {
                setAddressError("Latitude or longitude has less than 5 decimal places");
              } else if (type === "APPROXIMATE") {
                setAddressError("Address is invalid. Please select a valid address");
              } else {
                // If all checks are passed, clear any previous error messages
                setAddressError("");
                console.log(formattedLat, formattedLng, "=======lat,lng");
                setAddress(results[0]?.formatted_address);
                setOrderDetail((prevOrderDetail) => ({
                  ...prevOrderDetail,
                  lat: formattedLat,
                  lng: formattedLng,
                  zip: postalComponent?.long_name
                }));
                setMapError(false);
              }
            } else {
              setMapError(true);
              console.log("QWewq come here")
              setOrderDetail((prevOrderDetail) => ({
                ...prevOrderDetail,
                lat: "",
                lng: "",
                zip: ""
              }));
            }
          } else {
            const hereURL = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(orderDetail.address)}&apiKey=6CgVwXbgNzlj3uCnA38M0Du16b2o_0DKBOLGYyc1SH8`
            const responseHere = await axios.get(hereURL);
            const results = responseHere.data.items;
            if (results.length > 0) {
              console.log(results);
              // Find the first item where resultType is 'houseNumber'
              const houseNumberResult = results.find(result => result.resultType === 'houseNumber');

              if (houseNumberResult) {
                const { lat, lng } = houseNumberResult.position;
                const postalCode = houseNumberResult.address.postalCode.substring(0, 5); // Get first 5 characters
                const title = houseNumberResult.title;

                setAddressError("");
                console.log(lat, lng, "=======lat,lng");
                setAddress(title);
                setOrderDetail((prevOrderDetail) => ({
                  ...prevOrderDetail,
                  lat: lat,
                  lng: lng,
                  zip: postalCode,
                  address: title
                }));
                setMapError(false);
              } else {
                // Handle the case where no 'houseNumber' result is found
                setAddressError("Address is invalid. Please select a valid address");
                setMapError(true);
                setOrderDetail((prevOrderDetail) => ({
                  ...prevOrderDetail,
                  lat: "",
                  lng: "",
                  zip: "",
                  title: ""
                }));
              }
            } else {
              setMapError(true);
              console.log("No results found");
              setOrderDetail((prevOrderDetail) => ({
                ...prevOrderDetail,
                lat: "",
                lng: "",
                zip: ""
              }));
            }
          }

        } catch (error) {
          console.log("Google map give error yet please try again later!");
        }
      };

      fetchLocation();
    }
  }, [orderDetail.address]);

  const handleSubmit = async (event) => {
    if (addressError === "" && orderDetail.zip !== "") {
      event.preventDefault();
      console.log("Order Details:", orderDetail);

      const formData = new FormData();

      // Add orderDetail data to formData order_file_name
      formData.append('deliverydate', orderDetail.timeWindow);
      formData.append('order_file_name', "");
      formData.append('language', orderDetail.language);
      formData.append('apart', orderDetail.apart);
      formData.append('deliveryTime', moment(orderDetail.deliveryDate).format('MM/DD/YYYY'));
      formData.append('name', orderDetail.name);
      formData.append('address', orderDetail.address);
      formData.append('contact', orderDetail.phoneNumber);
      formData.append('cell', orderDetail.cellNumber);
      formData.append('workNumber', orderDetail.workNumber);
      formData.append('zip', orderDetail.zip);
      formData.append('notes', orderDetail.note);
      formData.append('status', orderDetail.status);
      formData.append('location_image', streetViewUrl);
      formData.append('signature', orderDetail.signRequired);
      formData.append('photoid', orderDetail.reqPhotoId);
      formData.append('refrigrated', orderDetail.registed);
      formData.append('collectcopay', orderDetail.collectCopay);
      formData.append('hippaform', orderDetail.hippaForm);
      formData.append('lineform', orderDetail.lineForm);
      formData.append('nfaqbform', orderDetail.nfForm);
      formData.append('totalcopay', orderDetail.totalCopay);
      formData.append('addresstype', orderDetail.bussAddress);
      formData.append('parcelPlace', orderDetail.parcelPlace);
      formData.append('lat', orderDetail.lat);
      formData.append('lng', orderDetail.lng);
      formData.append('active', orderDetail.active);
      // Add selectedOrderFile to formData
      formData.append("order_file", selectedOrderFile);

      formData.append("products", JSON.stringify(orderDetail.rxList));

      const responsePersonal = await putApiWithAuth(
        CREATE_ORDER + `/${oid}`,
        formData
      );

      if (responsePersonal.data !== undefined) {
        handlePrint();
        pid !== undefined
          ? navigate(`/pharmacy/${pid}/list/order`)
          : navigate("/list/order");
      }
    }
  };

  const handleSaveCancelNote = async () => {
    const formState = {
      "cancel_note": adminNote,
      "cancelAt": "time"
    }
    const response = await patchApiWithAuth(CREATE_ORDER + `/${oid}`, formState);
    console.log(response)
    if (response.status !== 400) {
      toast.success("Order Cancelled Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setModalCancelNoteOpen(false);
      pid !== undefined ? navigate(`/pharmacy/${pid}/list/order`) : navigate("/list/order")
    }
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDeleteOrder = async () => {
    const formState = {
      "delete": true
    }
    const response = await patchApiWithAuth(CREATE_ORDER + `/${oid}`, formState);
    console.log(response)
    if (response.status !== 400) {
      toast.success("Order Deleted Successfully!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setModalCancelNoteOpen(false);
      pid !== undefined ? navigate(`/pharmacy/${pid}/list/order`) : navigate("/list/order")
    }
    else {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const componentRef2 = useRef();
  const componentRef = useRef();

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const downloadUploadSlip = () => {
    window.open(`${orderDetail.order_file}`, "_blank");
  };

  const handleSearch = async (event) => {
    const { value } = event.target;
    setAddress(value);
    if (value.length > 2) { // To limit requests, only search if query length > 2
      const apikey = "6CgVwXbgNzlj3uCnA38M0Du16b2o_0DKBOLGYyc1SH8";
      const endpoint = `https://autosuggest.search.hereapi.com/v1/autosuggest?at=40.8301933,-73.8521865&in=countryCode:USA&lang=en-US&limit=5&q=${value}&apikey=${apikey}`;

      try {
        const response = await axios.get(endpoint);
        setResults(response.data.items || []);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setResults([]);
      }
    } else {
      setResults([]);
    }
  };

  return (
    <div className="addOrderMain">
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="addOrder-top">
              <div className="topFields">
                <p className="topFieldLabel">Created Date</p>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-top-field"
                  name="name"
                  placeholder="Created Date"
                  value={moment(orderDetail.createdAt).format("MM/DD/YYYY")}
                  readOnly
                />
              </div>
              <div className="topFields">
                <p className="topFieldLabel">Delivery Date</p>
                {/* <input
                  autoComplete="off"
                  type="date"
                  className="order-top-field"
                  name="deliveryDate"
                  placeholder="Delivery Date"
                  value={orderDetail.deliveryDate}
                  onChange={handleOrderChange}
                /> */}
                <DatePicker
                  className="order-top-field"
                  selected={
                    orderDetail.deliveryDate ? new Date(orderDetail.deliveryDate) : null
                  }
                  onChange={handleDeliveryDateChange}
                  dateFormat="MM/dd/yyyy"
                />

              </div>
              <div className="topFields">
                <p className="topFieldLabel">Time Window</p>
                <select
                  name="timeWindow"
                  className="order-top-field"
                  id="timeWindow"
                  value={orderDetail.timeWindow}
                  onChange={handleOrderChange}
                >
                  <option value="Anytime">Anytime</option>
                  <option value="9am-3pm">9am-3pm</option>
                  <option value="2pm-6pm">2pm-6pm</option>
                  <option value="5pm-9pm">5pm-9pm</option>
                  <option value="Urgent">Urgent</option>
                </select>
              </div>
              {/* <div className="topFields">
                <select className="order-top-field" name="language" id="language" value={orderDetail.language} onChange={handleOrderChange}>
                  <option value="">
                    <em>Select Language</em>
                  </option>
                  <option value="english">English</option>
                  <option value="french">French</option>
                  <option value="spanish">Spanish</option>
                </select>
              </div> */}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="orderBasicDiv">
              <div className="orderBasicInfo">
                <h2>To</h2>
                <div className="orderMainDiv">
                  <i className="fa fa-user detail-in-icon" />
                  <h3 className="orderMainText">{orderDetail.name}</h3>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={buildings} alt="Logo" />
                  <p className="orderMainText">{orderDetail.address}</p>
                </div>
                <div className="orderMainDiv">
                  <img
                    className="detail-in-icon"
                    src={pharmacyCallBlack}
                    alt="Logo"
                  />
                  <p className="orderMainText">{orderDetail.phoneNumber}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={iphone} alt="Logo" />
                  <p className="orderMainText">{orderDetail.cellNumber}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={landline} alt="Logo" />
                  <p className="orderMainText">{orderDetail.workNumber}</p>
                </div>
              </div>
              <div className="locImgDiv">
                <h1>Location View</h1>
                {orderDetail.lat && orderDetail.lng && (
                  <img
                    src={streetViewUrl}
                    alt="Google Maps Street View"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                )}
                {/* <img className="locatioImg" src={locationImg} alt="location img" /> */}
              </div>
            </div>

            <Grid container className="order-form-grid" spacing={2}>
              {/* First Row */}
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Name</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="name"
                  placeholder="Name"
                  value={orderDetail.name}
                  onChange={handleOrderChange}
                />
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Phone Number</label>
                <InputMask
                  mask="(999) 999-9999"
                  value={orderDetail.phoneNumber}
                  onChange={handleOrderChange}
                >
                  {() => (
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="phoneNumber"
                      placeholder="Phone Number"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Cell Number</label>
                <InputMask
                  mask="(999) 999-9999"
                  value={orderDetail.cellNumber}
                  onChange={handleOrderChange}
                >
                  {() => (
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="cellNumber"
                      placeholder="Cell Number"
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Work Number</label>
                <InputMask
                  mask="(999) 999-9999"
                  value={orderDetail.workNumber}
                  onChange={handleOrderChange}
                >
                  {() => (
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="workNumber"
                      placeholder="Work Number"
                    />
                  )}
                </InputMask>
              </Grid>

              {/* Second Row */}
              <Grid item className="order-grid" xs={6}>
                <label className="order-field-label">Address</label>
                {/* <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="address"
                  placeholder="Address"
                  value={orderDetail.address}
                  onChange={handleOrderChange}
                /> */}
                {parseInt(pharmacyDetail.id) === 2 ?
                  <Autocomplete
                    apiKey={GOOGLE_MAPS_API_KEY}
                    onPlaceSelected={handlePlaceSelect}
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        type="text"
                        name="address"
                        id="address"
                        className="order-field"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value)
                        }}
                      />
                    )}
                    // style={{
                    //   border: '0px',
                    //   width: '100%',
                    //   borderRadius: '5px',
                    //   font: 'inherit',
                    //   letterSpacing: 'inherit',
                    //   color: 'currentColor',
                    //   boxSizing: 'content-box',
                    //   background: 'none',
                    //   height: '1.4375em',
                    //   margin: 0,
                    //   display: 'block',
                    //   minWidth: 0,
                    //   animationName: 'mui-auto-fill-cancel',
                    //   animationDuration: '10ms',
                    //   padding: '10.5px 14px 10.5px 12px'
                    // }}
                    className="order-field"
                    inputProps={{
                      style: {
                        '&:hover': {
                          borderColor: '#69c0ff'
                        },
                        '&:active': {
                          borderColor: '#69c0ff'
                        },
                        '&:focus': {
                          borderColor: '#69c0ff'
                        }
                      }
                    }}
                    options={options}
                  />
                  :
                  <div>
                    <input type="text" className="order-field" value={address} onChange={handleSearch} placeholder="Address" />
                    {results.length > 0 &&
                      <ul className="addressList">
                        {results.map((item, index) => (
                          <li key={index} style={{ borderBottom: "1px solid grey", cursor: 'pointer' }} onClick={() => handlePlaceSelect(item)}>
                            {item.title}
                          </li>
                        ))}
                      </ul>
                    }
                  </div>}
                {addressError !== "" && <p style={{ color: "red" }}>{addressError}</p>}
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Zip Code</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="zip"
                  placeholder="Zip Code"
                  value={orderDetail.zip}
                  onChange={handleOrderChange}
                />
                {orderDetail.zip === "" && <p style={{ color: "red" }}>ZipCode is mandatory!</p>}
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Apt Number</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="apart"
                  placeholder="Apt Number"
                  value={orderDetail.apart}
                  onChange={(e) => handleApartmentChange(e)}
                />
              </Grid>
            </Grid>

            <div className="textLine">
              <p className="textLine-p"><span style={{ fontWeight: '700' }}>Please Confirm</span> with the patient for apartment number if they don’t live in a private house </p>
              <div className="textCheckDiv">
                <p>Business Address</p>
                <Checkbox
                  name="bussAddress"
                  checked={orderDetail.bussAddress}
                  onChange={handleOrderChange}
                />
              </div>
            </div>

            <div className="rxDetail">
              <h2>Details</h2>
              <Grid container className="order-form-grid" spacing={2}>
                {/* First Row */}
                <Grid item className="order-grid" xs={1.5}>
                  <h3>Count</h3>
                </Grid>
                <Grid item className="order-grid" xs={2}>
                  <h3>RX Number</h3>
                </Grid>
                <Grid item className="order-grid" xs={3}>
                  <h3>Date</h3>
                </Grid>
                <Grid item className="order-grid" xs={3}>
                  <h3>Copay</h3>
                </Grid>
                <Grid item className="order-grid" xs={2}>
                  <h3 style={{ textAlign: 'center' }}>Remove</h3>
                </Grid>
              </Grid>
              {orderDetail.rxList.map((rx, i) => (
                <Grid container className="order-form-grid" spacing={2}>
                  {/* First Row */}
                  <Grid item className="order-grid" xs={1.5}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="i"
                      style={{ textAlign: 'center' }}
                      placeholder="Count"
                      value={i + 1}
                      readOnly
                    />
                  </Grid>
                  {pid !== undefined ?
                    <Grid item className="order-grid" xs={2}>
                      <input
                        autoComplete="off"
                        type="text"
                        className="order-field"
                        name={`rxList.${i}.rxnumber`}
                        placeholder="RX Number"
                        value={rx.rxnumber}
                        readOnly
                      />
                    </Grid>
                    :
                    <Grid item className="order-grid" xs={2}>
                      <input
                        autoComplete="off"
                        type="text"
                        className="order-field"
                        name={`rxList.${i}.rxnumber`}
                        placeholder="RX Number"
                        value={rx.rxnumber}
                        onChange={(event) => handleOrderChange(event, i)}
                      />
                    </Grid>
                  }
                  <Grid item className="order-grid" xs={3}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name={`rxList.${i}.date`}
                      placeholder="Date Filled"
                      value={rx.date}
                      onChange={(event) => handleOrderChange(event, i)}
                    />
                  </Grid>
                  <Grid item className="order-grid" style={{ display: 'flex', alignItems: 'center' }} xs={3}>
                    <input
                      autoComplete="off"
                      type="number"
                      className="order-field"
                      name={`rxList.${i}.copay`}
                      placeholder="Copay"
                      value={rx.copay}
                      onChange={(event) => handleOrderChange(event, i)}
                    />
                    <Checkbox
                      name={`rxList.${i}.select`}
                      checked={rx.select}
                      onChange={(event) => handleOrderChange(event, i)}
                    />
                  </Grid>
                  <Grid item style={{ textAlign: 'center' }} className="order-grid" xs={2}>
                    <DeleteIcon style={{ color: '#000000', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleDeleteRxItem(i)} />
                  </Grid>
                </Grid>
              ))}
              <div className="addRx" onClick={handleAddRxItem}>
                <AddBoxIcon />
                Add Item
              </div>
            </div>

            <Grid container className="order-form-grid" spacing={2}>
              <Grid item className="order-grid" xs={6}>
                <h2>Delivery Note</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid" xs={12}>
                    <textarea
                      rows="9"
                      className="order-textarea-field"
                      name="note"
                      placeholder="Write any indications / missing information for delivery driver and facility"
                      value={orderDetail.note}
                      onChange={handleOrderChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <h2>Actions</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{ borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600 }} className="order-field">Signature Required</p>
                    <Checkbox style={{ padding: '0px' }} name="signRequired" checked={orderDetail.signRequired} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{ borderColor: "#FF950F", color: "#FF950F", fontSize: "16px", fontWeight: 600 }} className="order-field">Required Photo ID</p>
                    <Checkbox style={{ padding: '0px' }} name="reqPhotoId" checked={orderDetail.reqPhotoId} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{ borderColor: "#0094FF", color: "#0094FF", fontSize: "16px", fontWeight: 600 }} className="order-field">Refrigerated</p>
                    <Checkbox style={{ padding: '0px' }} name="registed" checked={orderDetail.registed} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{ borderColor: "#05B321", color: "#05B321", fontSize: "16px", fontWeight: 600 }} className="order-field">Copay ${orderDetail.totalCopay.toFixed(2)}</p>
                    <Checkbox style={{ padding: '0px' }} name="collectCopay" checked={orderDetail.collectCopay} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <select name="parcelPlace" id="parcelPlace" style={{ borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600, backgroundColor: "#fff" }} className="order-field" value={orderDetail.parcelPlace} onChange={handleOrderChange}>
                      <option value="">
                        <em>Select Parcel Place</em>
                      </option>
                      <option value="Face to face">FACE TO FACE</option>
                      <option value="Leave with receptionist">LEAVE WITH RECEPTIONIST</option>
                      <option value="Leave at door if pt e-signs">LEAVE AT DOOR IF PT E-SIGNS</option>
                      <option value="Leave at door">LEAVE AT DOOR</option>
                      <option value="Leave in the mailbox">LEAVE IN THE MAILBOX</option>
                      <option value="Leave in mail box if pt e-signs">LEAVE IN MAIL BOX IF PT E-SIGNS</option>
                    </select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <h2>Get Forms Signed</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{ borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600 }} className="order-field">Hipaa Form</p>
                    <Checkbox style={{ padding: '0px' }} name="hippaForm" checked={orderDetail.hippaForm} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{ borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600 }} className="order-field">Lien Form</p>
                    <Checkbox style={{ padding: '0px' }} name="lineForm" checked={orderDetail.lineForm} onChange={handleOrderChange} />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p style={{ borderColor: "#000000", color: "#000000", fontSize: "16px", fontWeight: 600 }} className="order-field">NF AOB Form</p>
                    <Checkbox style={{ padding: '0px' }} name="nfForm" checked={orderDetail.nfForm} onChange={handleOrderChange} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className="order-btn">
              <button
                type="button"
                className="del-order"
                onClick={handleCreateCancelNote}
              >
                Cancel Order
              </button>
              {/* {pid === undefined && <button type="button" className="del-order" onClick={handleDeleteOrder}>Delete Order</button>} */}
              <button type="button" className="del-order" onClick={() => pid !== undefined ? navigate(`/pharmacy/${pid}/list/order`) : navigate("/list/order")}>Back</button>
              <button type="submit" className="save-order">Update<PrintIcon style={{ color: '#0455A6', marginLeft: '10px' }} /></button>
            </div>
          </form>
        </Grid>
        <Grid item xs={5}>
          <div className="actiontabsmain">
            <div className="actiontabs">
              <p
                className={`actiontabButtons ${
                  activeTab === "Upload slip" ? "activetab" : ""
                }`}
                onClick={() => handleTabClick("Upload slip")}
              >
                Upload slip
              </p>
              <p
                className={`actiontabButtons ${
                  activeTab === "Signed slip" ? "activetab" : ""
                }`}
                onClick={() => handleTabClick("Signed slip")}
              >
                Signed slip
              </p>
              <p className={`actiontabButtons ${activeTab === 'Delivery Proof' ? 'activetab' : ''}`}
                onClick={() => { handleTabClick('Delivery Proof') }}>Delivery Proof</p>
              <p className={orderDetail.orderticket !== undefined ? `actiontabButtons ${activeTab === 'Conversation' ? 'activetab' : ''}` : "actiontabButtons disable"}
                onClick={() => { orderDetail.orderticket !== undefined && handleTabClick('Conversation') }}>Conversation</p>
              {/* <p
                className={`actiontabButtons ${
                  activeTab === "Delivery Proof" ? "activetab" : ""
                }`}
                onClick={() => {
                  handleTabClick("Delivery Proof");
                }}
              >
                Delivery Proof
              </p>
              <p
                className={
                  orderDetail.orderticket !== undefined
                    ? `actiontabButtons ${
                        activeTab === "Conversation" ? "activetab" : ""
                      }`
                    : "actiontabButtons disable"
                }
                onClick={() => {
                  orderDetail.orderticket !== undefined &&
                    handleTabClick("Conversation");
                }}
              >
                Conversation
              </p> */}
            </div>
            {activeTab === 'Signed slip' ? (
              <img
                src={route_report}
                onClick={() => {
                  console.log("Signed slip tab active");
                  console.log("Post Type:", pharmacyDetail.pos_types);

                  if (pharmacyDetail.pos_types === 'PrimeRX') {
                    console.log("Post Type is PrimeRX, calling handlePrintSlip()");
                    handlePrintSlip();
                  } else if (pharmacyDetail.pos_types === 'BestRx' || pharmacyDetail.pos_types === 'PioneerRx') {
                    console.log("Post Type is BestRx or PioneerRx, opening PDF URL in new tab");
                    if (orderDetail.status === "5") {
                      console.log("Order status is 5, opening signed PDF");
                      window.open(`https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${orderDetail.slug}_signed.pdf?embedded=true`, '_blank');
                    } else {
                      console.log("Order status is not 5, opening order file URL");
                      window.open(orderDetail.order_file, "_blank");
                    }
                  } else {
                    console.log("Post Type does not match PrimeRX or BestRx/PioneerRx");
                  }
                }}
                alt="print"
              />
            ) : activeTab === 'Upload slip' && (
              <img
                src={route_report}
                onClick={() => {
                  console.log("Upload slip tab active, calling downloadUploadSlip()");
                  downloadUploadSlip();
                }}
                alt="print"
              />
            )}



          </div>
          {activeTab === 'Upload slip' && (
            <div style={{ display: 'contents' }}>
              <iframe src={`${orderDetail.order_file}#toolbar=0&navpanes=0&scrollbar=0`} width='100%' height='100%' style={{ marginTop: 10 }} />
            </div>
          )}
          {activeTab === "Signed slip" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
              }}
            >
              {pharmacyDetail.pos_types === "PrimeRX" ? (
                <Printdoc
                  orderDetail={orderDetail}
                  edit={true}
                  upload={false}
                  view={false}
                  pharmacy={pharmacyDetail}
                />
              ) : pharmacyDetail.pos_types === "BestRx" ? (
                <div style={{ flex: 1 }}>
                  {orderDetail.status === "5" ? (
                    <iframe
                      src={`https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${orderDetail.slug}_signed.pdf?embedded=true#toolbar=0&navpanes=0&scrollbar=0`}
                      width="100%"
                      height="100%"
                      style={{ border: "none", marginTop: 10 }}
                    />
                  ) : (
                    <iframe
                      src={`${orderDetail.order_file}#toolbar=0&navpanes=0&scrollbar=0`}
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                    />
                  )}
                </div>
              ) : pharmacyDetail.pos_types === "PioneerRx" ? (
                <div style={{ flex: 1 }}>
                  {orderDetail.status === "5" ? (
                    <iframe
                      src={`https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${orderDetail.slug}_signed.pdf?embedded=true#toolbar=0&navpanes=0&scrollbar=0`}
                      width="100%"
                      height="100%"
                      style={{ border: "none", marginTop: 10 }}
                    />
                  ) : (
                    <iframe
                      src={`${orderDetail.order_file}#toolbar=0&navpanes=0&scrollbar=0`}
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                    />
                  )}
                </div>
              ) : null}
            </div>
          )}

          {activeTab === "Delivery Proof" &&
            Object.keys(categorizedImages).map((attempt, index) => (
              <div key={attempt}>
                <h3 style={{ fontSize: "18px", fontWeight: 700 }}>
                  Attempt {attempt}
                </h3>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {categorizedImages[attempt].map((image, index) => (
                    <div
                      key={index}
                      style={{
                        width: "50%",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleImageClick(image.delivery_proof_image)
                      }
                    >
                      <img
                        src={image.delivery_proof_image}
                        alt={`delivery_proof_${image.id}`}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}

          {activeTab === "Conversation" && (
            <div className={`classes.root col-span-8`}>
              <div className="flex flex-col gap-md items-end w-full p-md">
                <div>
                  <div className="flex items-center gap-sm">
                    {orderDetail.orderticket !== undefined &&
                    orderDetail.orderticket.ticket_status === "Completed" ? (
                      <p
                        className="text-[16px] font-[700] text-center"
                        style={{ color: "red" }}
                      >
                        Ticket Closed
                      </p>
                    ) : (
                      <>
                        {/* <button onClick={() => handleCloseTicket(ticketId)} className="text-[16px] font-[700] text-center">
                          Issue Resolved <br /> Close Ticket
                        </button>
                        <CloseRounded className="cursor-pointer" /> */}
                        <p className="text-[16px] font-[700] text-center" style={{ color: 'red' }}>
                          Ticket Pending
                        </p>
                      </>
                    )}

                    {/* <img width="20px" height="auto" src={closeIconRed} alt="phone" /> */}
                  </div>
                </div>

                <div className="w-full flex flex-col justify-between max-h-screen min-h-[80vh] height-auto">
                  <Paper elevation={0} className={classes.chatContainer}>
                    {orderDetail.orderticket !== undefined &&
                      orderDetail.orderticket.ticketcomment_set !== undefined &&
                      orderDetail.orderticket.ticketcomment_set.length > 0 &&
                      orderDetail.orderticket.ticketcomment_set.map(
                        (message, index) => (
                          <div
                            key={index}
                            className={
                              message.sender === "user"
                                ? `classes.message flex gap-sm mb-sm`
                                : `classes.message flex gap-sm mb-sm flex-row-reverse`
                            }
                          >
                            <Avatar className={classes.avatar}>
                              {message.sender === "user" ? "U" : "R"}
                            </Avatar>

                            <div className={"flex flex-col w-full"}>
                              <div
                                className={
                                  message.sender === "user"
                                    ? "flex items-center justify-between"
                                    : "flex items-center justify-between flex-row-reverse"
                                }
                              >
                                <p
                                  className={
                                    message.sender === "user"
                                      ? "text-blue text-[20px] font-[700] text"
                                      : "text-text text-[20px] font-[700] text-end"
                                  }
                                >
                                  {message.comment_by.first_name}{" "}
                                  {message.comment_by.last_name}
                                </p>
                                <p
                                  className={
                                    message.sender === "user"
                                      ? "flex gap-sm text-blue text"
                                      : "flex gap-sm flex-row-reverse text-text text-end"
                                  }
                                >
                                  Read <BiCheckDouble color="#2757FF" />{" "}
                                  {moment(message.created_at).format(
                                    "MM-DD-YYYY"
                                  )}{" "}
                                  |{" "}
                                  {moment(message.created_at).format(
                                    "hh:mm:ss a"
                                  )}
                                </p>
                              </div>
                              <div
                                className={
                                  message.sender === "user"
                                    ? "bg-blue text-white p-sm rounded-sm h-[50px] flex items-center"
                                    : "bg-gray text-text p-sm rounded-sm flex items-center h-[50px]"
                                }
                              >
                                {message.comment}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </Paper>
                  {/* <Divider /> */}

                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={modalCancelNoteOpen}
        onClose={handleModalCancelNoteClose}
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#D9D9D9",
            color: "#0455A6",
            fontWeight: "800",
            padding: 1,
          }}
        >
          Cancel Notes
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1 }}>
          <textarea
            type="text"
            className="modal-field notes-field"
            name="note"
            placeholder="Write your note here!!!"
            value={adminNote}
            onChange={(e) => setAdminNote(e.target.value)}
            rows={4}
          />
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={"15px"}
            marginLeft={23}
          >
            <Button
              onClick={handleModalCancelNoteClose}
              variant="outlined"
              color="inherit"
              sx={{ backgroundColor: "white" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveCancelNote}
              variant="outlined"
              color="inherit"
              sx={{ backgroundColor: "white" }}
            >
              Cancel Order
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <div style={{ display: "none" }}>
        <div
          style={{ margin: "10px", padding: "16px", maxWidth: "3.75in" }}
          ref={componentRef}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Date: {moment(orderDetail.createdAt).format("MM/DD/YYYY")}
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Time: {moment(orderDetail.createdAt).format("hh:mm:ss A")}
              </p>
            </div>
            {/* <img src={pdfLogo} alt="logo" style={{width: '100px', height: '100px'}} /> */}
            <img src={pdfLogo} style={{ width: "120px" }} alt="logo" />
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "11px",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Order #: {orderDetail.daily_order_id}
              </p>
            </div>
          </div>
          <hr />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h3
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Order ID:{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  {orderDetail.slug}
                </span>
              </h3>
              <h3
                style={{
                  margin: "0",
                  fontSize: "15px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Client:{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  {orderDetail.name}
                </span>
              </h3>
              <h5
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: 300,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Rx Count: {orderDetail.rxList.length}
              </h5>
              <h5
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Delivery Preference:
              </h5>
              <h5
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: 300,
                  padding: "5px 0",
                  textTransform: "uppercase",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                {orderDetail.parcelPlace}
              </h5>
              <h4
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Delivery Date:{" "}
                {moment(orderDetail.deliverAtSelected).format("MM-DD-YYYY")}
              </h4>
              <h4
                style={{
                  margin: "0",
                  fontSize: "14px",
                  fontWeight: 700,
                  padding: "5px 0",
                  fontFamily: "Saira, sans-serif",
                }}
              >
                Time Window: {orderDetail.timeWindow}
              </h4>
              {orderDetail.note !== "" && (
                <p
                  style={{
                    margin: "0",
                    fontSize: "13px",
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Delivery Note:</span>
                </p>
              )}
              {orderDetail.note !== "" && (
                <p
                  style={{
                    margin: "0",
                    fontSize: "13px",
                    padding: "5px 0",
                    width: "2.1in",
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  {orderDetail.note}
                </p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px 0",
                alignItems: "center",
              }}
            >
              {orderDetail.totalCopay !== undefined &&
                orderDetail.totalCopay !== 0 && (
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "16px",
                      border: "2px solid black",
                      fontFamily: "Saira, sans-serif",
                      padding: "0 2px",
                      textAlign: "center",
                      marginBottom: "10px",
                      width: "90px",
                    }}
                  >
                    COPAY: $ {orderDetail.totalCopay.toFixed(2)}
                  </p>
                )}
              <QRCode value={oid.toString()} renderAs="svg" size={80} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              fontFamily: "Saira, sans-serif",
            }}
          >
            {(orderDetail.hippaForm ||
              orderDetail.lineForm ||
              orderDetail.nfForm) && (
              <>
                <h4
                  style={{
                    margin: "0",
                    fontSize: "14px",
                    fontWeight: 700,
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  Get Forms Signed:
                </h4>
                <h5
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    fontWeight: 700,
                    fontFamily: "Saira, sans-serif",
                  }}
                >
                  {orderDetail.hippaForm && <span>Hipaa Form</span>}
                  {orderDetail.lineForm && (
                    <span
                      style={{
                        marginLeft: "8px",
                        fontFamily: "Saira, sans-serif",
                      }}
                    >
                      Lien Form
                    </span>
                  )}
                  {orderDetail.nfForm && (
                    <span
                      style={{
                        marginLeft: "8px",
                        fontFamily: "Saira, sans-serif",
                      }}
                    >
                      NF AOB Form
                    </span>
                  )}
                </h5>
              </>
            )}
            <h4
              style={{
                margin: "0",
                fontSize: "16px",
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
              }}
            >
              {orderDetail.registed && orderDetail.reqPhotoId
                ? "Refrigerated / Photo ID"
                : orderDetail.reqPhotoId
                ? "Photo ID"
                : orderDetail.registed
                ? "Refrigerated"
                : " "}
              {orderDetail.registed && orderDetail.reqPhotoId ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={{ marginRight: "5px" }}
                    src={registered}
                    alt="logo"
                  />{" "}
                  <img
                    src={photoId}
                    style={{ marginLeft: "10px" }}
                    alt="logo"
                  />
                </div>
              ) : orderDetail.reqPhotoId ? (
                <img style={{ margin: "auto" }} src={photoId} alt="logo" />
              ) : orderDetail.registed ? (
                <img style={{ margin: "auto" }} src={registered} alt="logo" />
              ) : (
                " "
              )}
            </h4>
            <h5
              style={{
                margin: "0",
                marginTop: "10px",
                fontFamily: "Saira, sans-serif",
                fontSize: "13px",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              From
            </h5>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 700,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
              }}
            >
              {pharmacyDetail.name}
            </h4>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 300,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
              }}
            >
              {pharmacyDetail.location}
            </h4>
            <h4
              style={{
                margin: "0",
                fontSize: "13px",
                fontWeight: 300,
                textAlign: "center",
                fontFamily: "Saira, sans-serif",
              }}
            >
              Phone {pharmacyDetail.contact_no}
            </h4>
          </div>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            paddingTop: "10px",
          }}
          ref={componentRef2}
        >
          <Printdoc
            orderDetail={orderDetail}
            edit={true}
            upload={false}
            view={false}
            pharmacy={pharmacyDetail}
          />
        </div>
      </div>
      {selectedImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000, // Ensure it's above everything else
          }}
          onClick={closeFullscreen}
        >
          <img
            src={selectedImage}
            alt="Fullscreen"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              width: "auto",
              height: "auto",
            }}
          />
          <span
            style={{
              position: "absolute",
              top: "10px",
              right: "20px",
              fontSize: "30px",
              color: "white",
              cursor: "pointer",
            }}
            onClick={closeFullscreen}
          >
            ×
          </span>
        </div>
      )}
    </div>
  );
}

export default EditOrder;
